import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {LineChart} from 'echarts/charts';
import {DataZoomComponent, GridComponent, LegendComponent, TooltipComponent} from 'echarts/components';
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import mqtt, {MqttClient} from 'mqtt';
import VChart from 'vue-echarts';
import {Component, Vue} from 'vue-property-decorator';
import {DataDisplayType} from '../../../types/types';
import {createPresignedUrl, getActualValue, getSensorId, getSensorName} from '../../../utils/utils';

// Setup ECharts components
use([CanvasRenderer, LineChart, GridComponent, TooltipComponent, LegendComponent, DataZoomComponent]);

dayjs.extend(relativeTime);

interface SensorData {
    sensorName: string;
    value: number;
    time: number;
}

@Component({
    components: {
        VChart,
    },
})
export default class IoTDataClient extends Vue {
    private isLoading = false; // Used in connectToMQTT and disconnectMQTT methods
    private isConnected = false;
    private errorMessage: string | undefined = undefined; // Used in connectToMQTT method
    private data: any[] = [];
    private mqttClient: MqttClient | null = null;
    private dialogOpen = false; // Used in handleRowClick method
    private selectedJson: string | null = null; // Used in handleRowClick method
    private selectedDataDisplayType: DataDisplayType = DataDisplayType.SAMPLE;
    private inputDataScale = 1;
    private inputDataOffset = 0;

    private readonly accessKeyId = process.env.VUE_APP_AWS_ACCESS_KEY_ID ?? '';
    private readonly secretAccessKey = process.env.VUE_APP_AWS_SECRET_ACCESS_KEY ?? '';
    private readonly endpoint = process.env.VUE_APP_ENDPOINT ?? '';
    private readonly topics = process.env.VUE_APP_MQTT_TOPICS ? process.env.VUE_APP_MQTT_TOPICS.split(',') : [];

    private readonly displayTypes = Object.values(DataDisplayType); // Used in template
    private readonly headers = [
        // Used in template
        {title: 'Sensor Id', key: 'sensorId'},
        {title: 'Received At', key: 'notifiedAt'},
        {title: 'Value', key: 'value'},
    ];

    get limitedData() {
        return this.data.slice(-40).reverse();
    }

    get tableData() {
        return this.limitedData.map(row => ({
            sensorId: `${getSensorName(row.data[0].id)} - ${getSensorId(row.data[0].id)}`,
            notifiedAt: row.notifiedAt,
            value: getActualValue(row.data[0])?.value ?? 0,
            rawData: row.data[0],
        }));
    }

    get processedData() {
        const rawData = this.data
            .map(item => {
                const dataValues = getActualValue(item.data[0]);
                const value = dataValues ? parseFloat(dataValues.value) * this.inputDataScale + this.inputDataOffset : 0;
                return {
                    time: dataValues ? dayjs(dataValues.observedAt).valueOf() : 0,
                    value: isNaN(value) ? 0 : value,
                };
            })
            .sort((a, b) => a.time - b.time);

        if (this.selectedDataDisplayType === DataDisplayType.PERCENTILE) {
            return this.generatePercentileData(rawData);
        } else if (this.selectedDataDisplayType === DataDisplayType.QUANTILE) {
            return this.generateQuantileData(rawData, 4);
        }

        return rawData;
    }

    get chartOption() {
        return {
            tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                    const time = dayjs(params[0].data[0]).format('YYYY-MM-DD HH:mm:ss');
                    const values = params.map((param: any) => `${param.seriesName}: ${param.data[1]}`).join('<br/>');
                    return `${time}<br/>${values}`;
                },
            },
            xAxis: {
                type: 'time',
                axisLabel: {
                    formatter: (value: number) => dayjs(value).format('HH:mm:ss'),
                },
            },
            yAxis: {
                type: 'value',
            },
            series: this.generateSeries(this.processedData),
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                },
            ],
        };
    }

    private generateSeries(data: any[]) {
        if (this.selectedDataDisplayType === DataDisplayType.PERCENTILE) {
            return [
                {
                    name: '25th Percentile',
                    type: 'line',
                    data: data.map(item => [item.time, item.p25]),
                    smooth: true,
                },
                {
                    name: '50th Percentile',
                    type: 'line',
                    data: data.map(item => [item.time, item.p50]),
                    smooth: true,
                },
                {
                    name: '75th Percentile',
                    type: 'line',
                    data: data.map(item => [item.time, item.p75]),
                    smooth: true,
                },
            ];
        }

        return [
            {
                name: 'Value',
                type: 'line',
                data: data.map(item => [item.time, item.value]),
                smooth: true,
            },
        ];
    }

    private generatePercentileData(data: any[]) {
        const values = data.map(item => item.value);
        return data.map(item => ({
            time: item.time,
            p25: this.calculatePercentile(values, 25),
            p50: this.calculatePercentile(values, 50),
            p75: this.calculatePercentile(values, 75),
        }));
    }

    private generateQuantileData(data: any[], numQuantiles: number) {
        const samples = [];
        for (let i = 0; i < numQuantiles; i++) {
            const index = Math.floor((i + 1) * (data.length / numQuantiles)) - 1;
            if (index >= 0 && index < data.length) {
                samples.push(data[index]);
            }
        }
        return samples;
    }

    private calculatePercentile(arr: number[], percentile: number) {
        const sorted = [...arr].sort((a, b) => a - b);
        const index = (percentile / 100) * (sorted.length - 1);
        const lower = Math.floor(index);
        const upper = Math.ceil(index);
        const weight = index - lower;

        if (upper >= sorted.length) return sorted[lower];
        return sorted[lower] * (1 - weight) + sorted[upper] * weight;
    }

    async connectToMQTT() {
        if (this.isConnected) return;

        this.isLoading = true;
        this.errorMessage = undefined;

        if (this.mqttClient?.disconnected) {
            this.mqttClient.reconnect();
            return;
        }

        try {
            const credentials = {
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
                sessionToken: '',
            };

            const presignedUrl = await createPresignedUrl(this.endpoint, credentials);
            const client = mqtt.connect(presignedUrl, {
                clientId: `mqtt-client-${Math.floor(Math.random() * 100000 + 1)}`,
                protocol: 'wss',
            });

            this.mqttClient = client;

            client.on('reconnect', () => {
                console.log('Reconnecting to AWS IoT MQTT broker');
            });

            client.on('connect', () => {
                console.log('Connected to AWS IoT MQTT broker');
                this.isLoading = false;
                this.isConnected = true;

                this.topics.forEach(topic => {
                    client.subscribe(topic, err => {
                        if (!err) {
                            console.log(`Subscribed to topic: ${topic}`);
                        } else {
                            console.error('Subscription error:', err);
                        }
                    });
                });
            });

            client.on('message', (topic, message) => {
                console.log(`Received message from topic ${topic}`);
                this.data.push(JSON.parse(message.toString()));
            });

            client.on('error', error => {
                console.error('Connection error:', error);
                this.isLoading = false;
                this.isConnected = false;
                this.errorMessage = error.message;
            });

            client.on('close', () => {
                console.log('Connection closed');
                this.isLoading = false;
                this.isConnected = false;
            });
        } catch (error: any) {
            console.error('Error connecting to MQTT:', error);
            this.errorMessage = error.message;
            this.isLoading = false;
            this.isConnected = false;
        }
    }

    disconnectMQTT() {
        if (!this.isConnected) return;

        this.isLoading = true;
        this.errorMessage = undefined;

        this.mqttClient?.end(false, () => {
            console.log('Disconnected from MQTT broker');
            this.isConnected = false;
            this.isLoading = false;
        });
    }

    handleRowClick(row: any) {
        this.selectedJson = JSON.stringify(row.rawData, null, 2);
        this.dialogOpen = true;
    }

    formatTime(time: string) {
        return dayjs(time).fromNow();
    }

    beforeDestroy() {
        if (this.mqttClient) {
            this.mqttClient.end();
        }
    }
}
