var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "view-wrapper-component",
    {
      attrs: { "max-width": 700, name: _vm.name, title: _vm.dialogTitle },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  attrs: { color: "admin-primary", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v("Close")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "admin-primary ", loading: _vm.isLoading },
                  on: { click: _vm.updateSensor },
                },
                [_vm._v("Save Changes")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "400px" } },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("v-text-field", {
                attrs: { dense: "", outlined: "", label: "Sensor ID" },
                model: {
                  value: _vm.sensorId,
                  callback: function ($$v) {
                    _vm.sensorId = $$v
                  },
                  expression: "sensorId",
                },
              }),
              _c("v-text-field", {
                attrs: { dense: "", outlined: "", label: "Sensor Type" },
                model: {
                  value: _vm.sensorType,
                  callback: function ($$v) {
                    _vm.sensorType = $$v
                  },
                  expression: "sensorType",
                },
              }),
              _c("v-text-field", {
                attrs: { dense: "", outlined: "", label: "Sensor IP" },
                model: {
                  value: _vm.ip,
                  callback: function ($$v) {
                    _vm.ip = $$v
                  },
                  expression: "ip",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }