import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {SensorModel} from '@/sdk';
import {IotApi} from '@/sdk/api-services';
import CreateSensorDialogComponent from '@/views/shared/iot-dialog/create-sensor-dialog.component';
import EditSensorDialogComponent from '@/views/shared/iot-dialog/edit-sensor.dialog.component.vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({
    components: {
        CreateSensorDialogComponent,
        EditSensorDialogComponent,
    },
})
export default class SensorManagementComponent extends VueWrapper {
    public $refs!: {
        createSensorDialog: CreateSensorDialogComponent;
        editSensorDialog: EditSensorDialogComponent;
    };

    @Prop({
        type: String,
        required: true,
    })
    public siteLocationId!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readOnlyFlag!: boolean;

    public showSensorManagement = true;

    public selectedSensor: SensorModel | null = null;

    public sensors: SensorModel[] = [];
    public sensorId: string = '';
    public ip: string = '';
    public sensorType: string = '';

    public iotApi: IotApi = new IotApi();

    public showCreateSensorDialog = false;
    public showEditSensorDialog = false;

    public mounted(): void {
        this.fetchSensors();
    }

    public openCreateSensorDialog(): void {
        this.showCreateSensorDialog = true;
    }

    public openEditSensorDialog(sensor: SensorModel): void {
        this.showEditSensorDialog = true;
        this.selectedSensor = sensor;
    }

    public openViewSensorChart(sensor: SensorModel): void {
        this.showSensorManagement = false;
    }

    public goBackToSensorManagement(): void {
        this.showSensorManagement = true;
    }

    public fetchSensors(): void {
        this.LoaderSrv.showFullScreenLoader();
        this.iotApi
            .getSensorsByLocation(this.siteLocationId)
            .then(res => {
                this.sensors = res;
            })
            .catch(err => {
                this.AlertSrv.show('error', `${err.error.message}`);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public deleteSensor(sensor: SensorModel): void {
        this.ConfirmSrv.open('$t.Common.SensorManagement.DeleteSensor.ConfirmationTitle', '$t.Common.SensorManagement.DeleteSensor.ConfirmationMessage').then(
            async yes => {
                if (yes) {
                    this.iotApi
                        .deleteSensor(sensor.id)
                        .then(() => {
                            this.AlertSrv.show('success', '$t.Common.SensorManagement.DeleteSensor.Success');
                            this.fetchSensors();
                        })
                        .catch(err => {
                            this.AlertSrv.show('error', `${err.error.message}`);
                        });
                }
            }
        );
    }
}
