import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js';
import 'chartjs-adapter-date-fns'; // Add this import for time scale support
import {Line} from 'vue-chartjs';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, Tooltip);

interface DataItem {
    time: string | number;
    value: number;
}

interface Statistics {
    totalCount: number;
    average: number;
    max: number;
    min: number;
    stdDev: number;
    rms: number;
}

@Component({
    components: {
        LineChart: Line,
    },
})
export default class SensorGraph extends Vue {
    @Prop({required: true}) data!: DataItem[];

    private selectedChartType = 'line';
    private showStatistics = false;
    private isStatsExpanded = false;
    private isRunning = false;
    private scale = 1;
    private offset = 0;
    private samplingFrequency = 1;
    private simulationInterval: number | null = null;

    get chartData() {
        if (!this.data || this.data.length === 0) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const backgroundColor = this.selectedChartType === 'area' ? 'rgba(130, 202, 157, 0.2)' : '#82ca9d';

        // Format dates for labels if necessary
        const labels = this.data.map(item => {
            if (typeof item.time === 'string') {
                return new Date(item.time).toISOString();
            }
            return new Date(item.time).toISOString();
        });

        return {
            labels,
            datasets: [
                {
                    label: 'Value',
                    backgroundColor,
                    borderColor: '#82ca9d',
                    data: this.data.map(item => ({
                        x: typeof item.time === 'string' ? new Date(item.time) : new Date(item.time),
                        y: item.value * this.scale + this.offset,
                    })),
                    fill: this.selectedChartType === 'area',
                },
            ],
        };
    }

    get computedChartOptions() {
        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: 'category', // Changed from 'time' to 'category'
                    display: true,
                    title: {
                        display: true,
                        text: 'Time',
                    },
                    ticks: {
                        maxRotation: 45,
                        minRotation: 45,
                    },
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Value',
                    },
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            return `Value: ${context.parsed.y.toFixed(2)}`;
                        },
                    },
                },
            },
        };
    }

    get statistics(): Statistics {
        if (!this.data || this.data.length === 0) {
            return {
                totalCount: 0,
                average: 0,
                max: 0,
                min: 0,
                stdDev: 0,
                rms: 0,
            };
        }

        const values = this.data.map(item => item.value);
        const totalCount = values.length;
        const sum = values.reduce((a, b) => a + b, 0);
        const average = sum / totalCount;
        const max = Math.max(...values);
        const min = Math.min(...values);
        const squaredDiffs = values.map(value => Math.pow(value - average, 2));
        const stdDev = Math.sqrt(squaredDiffs.reduce((a, b) => a + b, 0) / totalCount);
        const rms = Math.sqrt(values.reduce((acc, val) => acc + Math.pow(val, 2), 0) / totalCount);

        return {totalCount, average, max, min, stdDev, rms};
    }

    toggleStatistics() {
        this.isStatsExpanded = !this.isStatsExpanded;
    }

    toggleSimulation() {
        if (this.isRunning) {
            this.stopSimulation();
        } else {
            this.startSimulation();
        }
    }

    startSimulation() {
        this.isRunning = true;
        this.simulationInterval = window.setInterval(() => {
            this.$emit('simulation-tick');
        }, 1000 / this.samplingFrequency);
    }

    stopSimulation() {
        this.isRunning = false;
        if (this.simulationInterval) {
            clearInterval(this.simulationInterval);
            this.simulationInterval = null;
        }
    }

    @Watch('samplingFrequency')
    onSamplingFrequencyChange(newVal: number) {
        if (this.isRunning) {
            this.stopSimulation();
            this.startSimulation();
        }
    }

    beforeDestroy() {
        this.stopSimulation();
    }
}
