import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AnyObject} from '@/globals';
import {DashboardApi} from '@/sdk/api-services';
import * as echarts from 'echarts';
import {Component, Prop} from 'vue-property-decorator';
import LocationRulesWarningsDialogComponent from '../shared/location-rules-warnings-dialog/location-rules-warnings-dialog.component';

@Component({
    components: {
        LocationRulesWarningsDialogComponent,
    },
})
export default class SiteDasboardComponent extends VueWrapper {
    public $refs!: {
        equipmentChartElem: HTMLDivElement;
        warningsDialogRef: LocationRulesWarningsDialogComponent;
    };

    public dasboardApi: DashboardApi = new DashboardApi();

    areasCount: number = 0;
    subAreasCount: number = 0;
    equipmentCount: number = 0;
    warningsCount: number = 0;

    completedCorrectiveActionsCount: number = 0;
    onGoingCorrectiveActionsCount: number = 0;

    completedInspectionsCount: number = 0;
    onGoingInspectionsCount: number = 0;

    delayedInspectionsCount: number = 0;
    asPlannedInspectionsCount: number = 0;

    siteId: string | null = null;

    equipmentChart: echarts.ECharts | null = null;

    areasCountByType: AnyObject = {};
    equipmentCountByType: AnyObject = {};

    @Prop({type: String, default: ''})
    siteIdProp!: string;

    created() {
        this.siteId = this.$route.params.siteId ?? this.siteIdProp;

        // this.getAreasCount();
        // this.getSubAreasCount();
        // this.getEquipmentCount();
        // this.getWarningsCount();

        // this.getCompletedCorrectiveActionsCount();
        // this.getOnGoingCorrectiveActionsCount();

        // this.getCompletedInspectionsCount();
        // this.getOnGoingInspectionsCount();

        // this.getDelayedInspectionsCount();

        // this.getEquipmentCountByType();
    }

    public mounted(): void {
        // this.initEquipmentChart();
    }

    public openWarningsDialog() {
        this.$refs.warningsDialogRef.open();
    }

    initEquipmentChart() {
        this.equipmentChart = echarts.init(this.$refs.equipmentChartElem);

        // equipments bar chart by months
        const option: echarts.EChartsOption = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                bottom: 0,
                data: ['Areas', 'Equipments'],
            },

            xAxis: {
                type: 'value',
            },
            yAxis: {
                type: 'category',
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            series: [
                {
                    name: 'Areas',
                    type: 'bar',
                    data: Array(12).fill(0),
                },
                {
                    name: 'Equipments',
                    type: 'bar',
                    data: Array(12).fill(0),
                },
            ],
        };

        this.equipmentChart.setOption(option);

        this.processEquipmentCountData();
    }

    processEquipmentCountData() {
        const months = Object.keys(this.equipmentCountByType);
        // sort months by month number
        months.sort((a, b) => {
            return new Date(a).getMonth() - new Date(b).getMonth();
        });

        const areasCountByMonths = months.map(month => this.areasCountByType[month] ?? 0);
        const equipmentCountByMonths = months.map(month => this.equipmentCountByType[month] ?? 0);

        this.equipmentChart?.setOption({
            series: [
                {
                    name: 'Areas',
                    data: areasCountByMonths,
                },
                {
                    name: 'Equipments',
                    data: equipmentCountByMonths,
                },
            ],
        });
    }

    getEquipmentCountByType() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.equipmentChart?.showLoading();
        this.dasboardApi
            .getLocationCountOfEachTypeByTimeType(this.siteId, 'month')
            .then(res => {
                this.areasCountByType = res.areasCount;
                this.equipmentCountByType = res.equipmentsCount;

                this.processEquipmentCountData();
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getAreasCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getAreasCountBySiteId(this.siteId)
            .then(res => {
                this.areasCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getSubAreasCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getSubAreasCountBySiteId(this.siteId)
            .then(res => {
                this.subAreasCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getEquipmentCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getEquipmentCountBySiteId(this.siteId)
            .then(res => {
                this.equipmentCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getWarningsCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getWarningsCountBySiteId(this.siteId)
            .then(res => {
                this.warningsCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getCompletedCorrectiveActionsCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getCompletedCorrectiveActionsCount(this.siteId)
            .then(res => {
                this.completedCorrectiveActionsCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getOnGoingCorrectiveActionsCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getOnGoingCorrectiveActionsCount(this.siteId)
            .then(res => {
                this.onGoingCorrectiveActionsCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getCompletedInspectionsCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getCompletedInspectionsCount(this.siteId)
            .then(res => {
                this.completedInspectionsCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getOnGoingInspectionsCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getOnGoingInspectionsCount(this.siteId)
            .then(res => {
                this.onGoingInspectionsCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getDelayedInspectionsCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getDelayedInspectionsCount(this.siteId)
            .then(res => {
                this.delayedInspectionsCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    getAsPlannedInspectionsCount() {
        if (!this.siteId) return;

        this.LoaderSrv.showFullScreenLoader();
        this.dasboardApi
            .getAsPlannedInspectionsCount(this.siteId)
            .then(res => {
                this.asPlannedInspectionsCount = res.count;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }
}
