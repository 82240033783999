var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-container",
        [
          _vm.errorMessage
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: "error", variant: "outlined" } },
                        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.displayTypes,
                                      label: "Select Graph Type",
                                    },
                                    model: {
                                      value: _vm.selectedDataDisplayType,
                                      callback: function ($$v) {
                                        _vm.selectedDataDisplayType = $$v
                                      },
                                      expression: "selectedDataDisplayType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Scale", type: "number" },
                                    model: {
                                      value: _vm.inputDataScale,
                                      callback: function ($$v) {
                                        _vm.inputDataScale = _vm._n($$v)
                                      },
                                      expression: "inputDataScale",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Offset", type: "number" },
                                    model: {
                                      value: _vm.inputDataOffset,
                                      callback: function ($$v) {
                                        _vm.inputDataOffset = _vm._n($$v)
                                      },
                                      expression: "inputDataOffset",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mb-6" },
                            [
                              !_vm.isConnected
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "success",
                                        loading: _vm.isLoading,
                                      },
                                      on: { click: _vm.connectToMQTT },
                                    },
                                    [_vm._v(" Connect ")]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "error",
                                        loading: _vm.isLoading,
                                      },
                                      on: { click: _vm.disconnectMQTT },
                                    },
                                    [_vm._v(" Disconnect ")]
                                  ),
                            ],
                            1
                          ),
                          _vm.processedData.length
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "chart-container" },
                                      [
                                        _c("v-chart", {
                                          staticClass: "chart",
                                          attrs: {
                                            option: _vm.chartOption,
                                            autoresize: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tableData,
                            },
                            on: { "click:row": _vm.handleRowClick },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatTime(item.notifiedAt)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.dialogOpen,
            callback: function ($$v) {
              _vm.dialogOpen = $$v
            },
            expression: "dialogOpen",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Received Data")]),
              _c("v-card-text", [
                _c("pre", [_vm._v(_vm._s(_vm.selectedJson))]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogOpen = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }