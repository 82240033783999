var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      key: _vm.hash,
      ref: "tableContainer",
      staticClass: "width-100 relative",
      attrs: { flat: "" },
    },
    [
      _c("v-card-text", [
        _c("div", { staticClass: "d-flex justify-center" }, [
          _c("div", { staticClass: "in-section d-flex flex-column width-50" }, [
            _c(
              "div",
              {
                staticClass: "d-flex mb-2 justify-center",
                staticStyle: { border: "1px solid #d2d2d2" },
              },
              [_c("h2", { staticClass: "text-center" }, [_vm._v("Wire In")])]
            ),
            _c("div", { staticClass: "d-flex justify-end" }, [
              _c("div", { staticClass: "in-caps-wrap display-flex mr-10" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("base-text-field", {
                      staticClass: "mr-2 width-100px",
                      attrs: {
                        type: "number",
                        label: "IN Caps",
                        value: _vm.capsInToAddCount,
                        readonly: _vm.readonly,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.capsInToAddCount = $event
                        },
                      },
                    }),
                    _c(
                      "base-btn",
                      {
                        staticClass: "mt-1",
                        attrs: {
                          small: "",
                          outlined: "",
                          disabled: _vm.readonly,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addCaps("IN")
                          },
                        },
                      },
                      [_vm._v("Add")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "width-170px mr-auto caps-in-list-wrap" },
                    _vm._l(_vm.inCapsList, function (c, index) {
                      return _c("div", { key: `cap-outer-box-${c.id}` }, [
                        index === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "drop-area",
                                on: {
                                  dragover: function ($event) {
                                    $event.preventDefault()
                                  },
                                  drop: function ($event) {
                                    return _vm.onDropCap("before", c)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "add-icon",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addSingleCap(c, "before")
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-plus")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            key: c.id,
                            staticClass: "cap-in-box",
                            attrs: {
                              id: `cap-${c.id}`,
                              draggable: !_vm.readonly,
                            },
                            on: {
                              mouseup: function ($event) {
                                return _vm.onCapInMouseUp($event, c)
                              },
                              dragstart: function ($event) {
                                return _vm.onCapDragStart($event, c)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mr-1" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      "x-small": "",
                                      disabled: _vm.readonly,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onDeleteCapClick(c)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-delete"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(_vm._s(c.title)),
                            ]),
                            _c("span", [_c("v-icon", [_vm._v("mdi-lan")])], 1),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "drop-area",
                            on: {
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                              drop: function ($event) {
                                return _vm.onDropCap("after", c)
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "add-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.addSingleCap(c, "after")
                                  },
                                },
                              },
                              [_vm._v("mdi-plus")]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "in-pins-wrap d-flex flex-column ml-10" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c("base-text-field", {
                        staticClass: "mr-2 width-100px",
                        attrs: {
                          type: "number",
                          label: "IN Pins",
                          value: _vm.pinsInToAddCount,
                          readonly: _vm.readonly,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.pinsInToAddCount = $event
                          },
                        },
                      }),
                      _c(
                        "base-btn",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            small: "",
                            outlined: "",
                            disabled: _vm.readonly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addPins("IN")
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "width-170px ml-auto pins-in-list-wrap" },
                      _vm._l(_vm.inPinsList, function (p, index) {
                        return _c("div", { key: `pin-oouter-box-${p.id}` }, [
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "drop-area",
                                  on: {
                                    dragover: function ($event) {
                                      $event.preventDefault()
                                    },
                                    drop: function ($event) {
                                      return _vm.onDropPin("before", p)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "add-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addSinglePin(p, "before")
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-plus")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              key: p.id,
                              staticClass: "pin-in-box",
                              attrs: {
                                id: `pin-${p.id}`,
                                draggable: !_vm.readonly,
                              },
                              on: {
                                mousedown: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onPinInMouseDownn(
                                    $event,
                                    "pin-in",
                                    p
                                  )
                                },
                                mouseup: function ($event) {
                                  return _vm.onPinMouseUp($event, p, "pin-in")
                                },
                                dragstart: function ($event) {
                                  return _vm.onPinDragStart($event, p)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mr-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        "x-small": "",
                                        disabled: _vm.readonly,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onDeletePinClick(p)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-delete")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(p.title)),
                              ]),
                              _c(
                                "span",
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "ma-0 pa-0",
                                      attrs: {
                                        value: p.isActive,
                                        dense: "",
                                        "hide-details": "",
                                        disabled: _vm.readonly,
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          small: "",
                                          value: true,
                                          readonly: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onPinRadioClick(p)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "drop-area",
                              on: {
                                dragover: function ($event) {
                                  $event.preventDefault()
                                },
                                drop: function ($event) {
                                  return _vm.onDropPin("after", p)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "add-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSinglePin(p, "after")
                                    },
                                  },
                                },
                                [_vm._v("mdi-plus")]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "width-80px d-flex justify-center" },
            [_c("v-divider", { attrs: { vertical: "" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "out-section width-50 d-flex flex-column" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex mb-2 justify-center",
                  staticStyle: { border: "1px solid #d2d2d2" },
                },
                [_c("h2", { staticClass: "text-center" }, [_vm._v("Wire Out")])]
              ),
              _c("div", { staticClass: "d-flex justify-start" }, [
                _c(
                  "div",
                  {
                    staticClass: "out-pins-wrap mr-5 d-flex flex-column mr-10",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("base-text-field", {
                          staticClass: "mr-2 width-100px",
                          attrs: {
                            type: "number",
                            label: "OUT Pins",
                            value: _vm.pinsOutToAddCount,
                            readonly: _vm.readonly,
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.pinsOutToAddCount = $event
                            },
                          },
                        }),
                        _c(
                          "base-btn",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              small: "",
                              outlined: "",
                              disabled: _vm.readonly,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addPins("OUT")
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "width-170px mr-2 pins-out-list-wrap" },
                        _vm._l(_vm.outPinsList, function (p, index) {
                          return _c("div", { key: `pin-oouter-box-${p.id}` }, [
                            index === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "drop-area",
                                    on: {
                                      dragover: function ($event) {
                                        $event.preventDefault()
                                      },
                                      drop: function ($event) {
                                        return _vm.onDropPin("before", p)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "add-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addSinglePin(p, "before")
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-plus")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                key: p.id,
                                staticClass: "pin-out-box",
                                attrs: {
                                  id: `pin-${p.id}`,
                                  draggable: !_vm.readonly,
                                },
                                on: {
                                  mousedown: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onPinOutMouseDown(
                                      $event,
                                      "pin-out",
                                      p
                                    )
                                  },
                                  mouseup: function ($event) {
                                    return _vm.onPinMouseUp(
                                      $event,
                                      p,
                                      "pin-out"
                                    )
                                  },
                                  dragstart: function ($event) {
                                    return _vm.onPinDragStart($event, p)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ma-0 pa-0",
                                        attrs: {
                                          value: p.isActive,
                                          dense: "",
                                          "hide-details": "",
                                          disabled: _vm.readonly,
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            small: "",
                                            value: true,
                                            readonly: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onPinRadioClick(p)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(p.title)),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "ml-1" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          "x-small": "",
                                          disabled: _vm.readonly,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onDeletePinClick(p)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "red" } },
                                          [_vm._v("mdi-delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "drop-area",
                                on: {
                                  dragover: function ($event) {
                                    $event.preventDefault()
                                  },
                                  drop: function ($event) {
                                    return _vm.onDropPin("after", p)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "add-icon",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addSinglePin(p, "after")
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-plus")]
                                ),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "cap-caps-wrap ml-10" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("base-text-field", {
                        staticClass: "mr-2 width-100px",
                        attrs: {
                          type: "number",
                          label: "Total OUT Caps",
                          value: _vm.capsOutToAddCount,
                          readonly: _vm.readonly,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.capsOutToAddCount = $event
                          },
                        },
                      }),
                      _c(
                        "base-btn",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            small: "",
                            outlined: "",
                            disabled: _vm.readonly,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addCaps("OUT")
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "width-170px ml-auto caps-out-list-wrap" },
                      _vm._l(_vm.outCapsList, function (c, index) {
                        return _c("div", { key: `cap-outer-box-${c.id}` }, [
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "drop-area",
                                  on: {
                                    dragover: function ($event) {
                                      $event.preventDefault()
                                    },
                                    drop: function ($event) {
                                      return _vm.onDropCap("before", c)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "add-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addSingleCap(c, "before")
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-plus")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              key: c.id,
                              staticClass: "cap-out-box",
                              attrs: {
                                id: `cap-${c.id}`,
                                draggable: !_vm.readonly,
                              },
                              on: {
                                mouseup: function ($event) {
                                  return _vm.onCapOutMouseUp($event, c)
                                },
                                dragstart: function ($event) {
                                  return _vm.onCapDragStart($event, c)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [_c("v-icon", [_vm._v("mdi-lan")])],
                                1
                              ),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v(_vm._s(c.title)),
                              ]),
                              _c(
                                "span",
                                { staticClass: "ml-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        "x-small": "",
                                        disabled: _vm.readonly,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onDeleteCapClick(c)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-delete")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "drop-area",
                              on: {
                                dragover: function ($event) {
                                  $event.preventDefault()
                                },
                                drop: function ($event) {
                                  return _vm.onDropCap("after", c)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "add-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSingleCap(c, "after")
                                    },
                                  },
                                },
                                [_vm._v("mdi-plus")]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }