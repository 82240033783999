import {ServiceClass} from '@/decorators';
import {CreateUpdateSensorRequest, SensorModel, SensorWithRelations} from '../models';
import {BaseApi} from './base.api';

@ServiceClass()
export class IotApi extends BaseApi {
    public createSensor(data: CreateUpdateSensorRequest) {
        return this.POST_RequestAsync<SensorModel>(`${this.ApiUrl}/sensors`, data);
    }

    public getSensors() {
        return this.GET_RequestAsync<SensorWithRelations[]>(`${this.ApiUrl}/sensors`);
    }

    public getSensorsByLocation(locationId: string) {
        return this.GET_RequestAsync<SensorWithRelations[]>(`${this.ApiUrl}/sensors/location/${locationId}`);
    }

    public getSensorByIp(ip: string) {
        return this.GET_RequestAsync<SensorModel>(`${this.ApiUrl}/sensors/ip/${ip}`);
    }

    public updateSensor(id: string, data: CreateUpdateSensorRequest) {
        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/sensors/${id}`, data);
    }

    public deleteSensor(id: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/sensors/${id}`);
    }
}
