import VueWrapper from '@/components/core/Vue/vue.wrapper';
import ViewWrapperComponent from '@/components/shared/view-wrapper/view-wrapper.component';
import {CreateUpdateSensorRequest, SensorModel} from '@/sdk';
import {IotApi} from '@/sdk/api-services';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component({
    components: {
        ViewWrapperComponent,
    },
})
export default class EditSensorDialogComponent extends VueWrapper {
    public $refs!: {
        form: HTMLFormElement;
    };

    @Prop({
        type: String,
        default: 'edit-sensor-dialog',
    })
    public readonly name!: string;

    @Prop({
        type: String,
        required: true,
    })
    public siteLocationId!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readonly show!: boolean;

    @Prop({
        type: Object,
        required: true,
    })
    public selectedSensor!: SensorModel;

    @Prop({
        type: Function,
        required: true,
    })
    public fetchSensors!: () => void;

    public sensorId: string = '';
    public sensorType: string = '';
    public ip: string = '';
    public isLoading: boolean = false;
    public valid: boolean = false;

    public iotApi: IotApi = new IotApi();

    @Watch('show')
    public onShowChange(newValue: boolean) {
        if (newValue) {
            this.open();
        } else {
            this.close();
        }
    }

    public mounted() {
        if (this.show) {
            this.open();
            this.sensorId = this.selectedSensor.sensorId;
            this.sensorType = this.selectedSensor.sensorType;
            this.ip = this.selectedSensor.ip;
        }
    }

    public get dialogTitle() {
        return 'Edit Sensor';
    }

    public open() {
        if (this.CoreSrv.isModalOpened(this.name)) {
            this.close();
        }
        this.CoreSrv.OpenModal(this.name);
        this.$emit('opened');
    }

    public close() {
        this.CoreSrv.CloseModal(this.name);
        this.$emit('closed');
        this.$emit('update:show', false);
    }

    public updateSensor() {
        this.ConfirmSrv.open('$t.Common.SensorManagement.UpdateSensor.ConfirmationTitle', '$t.Common.SensorManagement.UpdateSensor.ConfirmationMessage').then(
            yes => {
                if (yes) {
                    this.isLoading = true;
                    const updatedSensor: CreateUpdateSensorRequest = {
                        sensorId: this.sensorId,
                        sensorType: this.sensorType,
                        ip: this.ip,
                        locationId: this.siteLocationId,
                    };

                    this.iotApi
                        .updateSensor(this.selectedSensor.id, updatedSensor)
                        .then(() => {
                            this.fetchSensors();
                            this.isLoading = false;
                            this.close();
                            this.$emit('sensor-updated');
                        })
                        .catch(error => {
                            this.isLoading = false;
                            console.log(error);
                        });
                }
            }
        );
    }
}
