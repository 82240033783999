import {Component, Prop, Vue} from 'vue-property-decorator';

interface Parameters {
    A: number;
    F: number;
    P: number;
    B: number;
    samplingRate: number;
    duration: number;
    noiseAmplitude: number;
    xOffset: number;
    decayRate: number;
}

@Component
export default class SinusoidalFunction extends Vue {
    @Prop({required: true}) parameters!: Parameters;

    updateParameter(param: string, event: Event) {
        const value = parseFloat((event.target as HTMLInputElement).value) || 0;
        this.$emit('update:parameters', {...this.parameters, [param]: value});
    }
}
