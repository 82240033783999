import {MonetTemperatureCSVData, SensorType, TemperatureCSVData, VibratorSamplingCSVData} from '../types/types';

// client utils imports
import {Sha256} from '@aws-crypto/sha256-js';
import {SignatureV4} from '@aws-sdk/signature-v4';
import {AwsCredentialIdentity} from '@aws-sdk/types';
import CryptoJS from 'crypto-js';
import {
    AbbSkinTemperatureSensorData,
    MonetTemperatureSensorData,
    MotorSurfaceTemperatureSensorData,
    SensorData,
    VibratorSamplingSensorData,
} from '../types/types';

const abbSkinUrn = process.env.REACT_APP_ABB_SKIN_TEMPERATURE_ID || 'urn:ngsi-ld:default:abbSkin';
const monetTemperatureUrn = process.env.REACT_APP_MONET_TEMPERATURE_ID || 'urn:ngsi-ld:default:monetTemperature';
const motorSurfaceTemperatureUrn = process.env.REACT_APP_MOTOR_SURFACE_TEMPERATURE_ID || 'urn:ngsi-ld:default:motorSurfaceTemperature';
const vibratorSamplingUrn = process.env.REACT_APP_VIBRATOR_SAMPLING_ID || 'urn:ngsi-ld:default:vibratorSampling';

function sensorName(urn: string): string {
    const regex = /urn:ngsi-ld:[^:]+:([^-]+)-(.+)/;
    const match = urn.match(regex);
    return match ? match[1] : 'Unknown Sensor';
}

function sensorId(urn: string): string {
    const regex = /urn:ngsi-ld:[^:]+:([^-]+)-(.+)/;
    const match = urn.match(regex);
    return match ? match[2] : 'Unknown ID';
}

const abbSkinSensorId = sensorId(abbSkinUrn);
const abbSkinSensorName = sensorName(abbSkinUrn);

const monetTemperatureSensorId = sensorId(monetTemperatureUrn);
const monetTemperatureSensorName = sensorName(monetTemperatureUrn);

const motorSurfaceTemperatureSensorId = sensorId(motorSurfaceTemperatureUrn);
const motorSurfaceTemperatureSensorName = sensorName(motorSurfaceTemperatureUrn);

const vibratorSamplingSensorId = sensorId(vibratorSamplingUrn);
const vibratorSamplingSensorName = sensorName(vibratorSamplingUrn);

export const detectDelimiter = (data: string): string => {
    const delimiters = [',', ';', '\t', '|', ':'];
    const lines = data.split('\n').slice(0, 10);

    const scores = delimiters.map(delimiter => ({
        delimiter,
        score: lines.reduce((score, line) => {
            const count = line.split(delimiter).length - 1;
            return score + (count > 0 ? count : 0);
        }, 0),
    }));

    scores.sort((a, b) => b.score - a.score);

    if (scores[0].score > scores[1].score * 2) {
        return scores[0].delimiter;
    }

    return ';'; // Default to `;` if scores are inconclusive
};

export const detectSensorType = (data: string[][]): SensorType => {
    if (data[0][0].toLowerCase() === 'model' && data[1][0].toLowerCase() === 'firmware version') {
        return SensorType.VIBRATOR_SAMPLING;
    }

    const headers = data[0].map(header => header.toLowerCase());

    if (headers.includes('measurement_taken_on(utc)') && headers.includes('measurement_value')) {
        return SensorType.ABB_SKIN_TEMPERATURE;
    }

    if (headers.includes('datamessageguid') && headers.includes('sensorid')) {
        return SensorType.MONET_TEMPERATURE;
    }

    throw new Error('Unknown sensor type');
};

export const parseVibratorSamplingData = (data: string[][]): VibratorSamplingCSVData => {
    const headerData = Object.fromEntries(data.slice(0, 14).map(row => [row[0], row[1]]));
    return {
        sensorId: vibratorSamplingSensorId,
        sensorName: vibratorSamplingSensorName,
        type: SensorType.VIBRATOR_SAMPLING,
        model: headerData['Model'],
        firmwareVersion: headerData['Firmware Version'],
        pointFormat: headerData['Point Format'],
        horizontalUnits: headerData['Horizontal Units'],
        horizontalScale: parseFloat(headerData['Horizontal Scale']),
        sampleInterval: parseFloat(headerData['Sample Interval']),
        filterFrequency: parseFloat(headerData['Filter Frequency']),
        recordLength: parseInt(headerData['Record Length']),
        gating: headerData['Gating'],
        probeAttenuation: parseInt(headerData['Probe Attenuation']),
        verticalUnits: headerData['Vertical Units'],
        verticalOffset: parseFloat(headerData['Vertical Offset']),
        verticalScale: parseFloat(headerData['Vertical Scale']),
        label: headerData['Label'],
        data: data.slice(16).map(row => ({
            TIME: row[0],
            CH1: parseFloat(row[1]),
        })),
    };
};

export const parseTemperatureData = (data: string[][], type: SensorType.ABB_SKIN_TEMPERATURE | SensorType.MOTOR_SURFACE_TEMPERATURE): TemperatureCSVData => {
    const headers = data[0].map(header => header.trim().toLowerCase());
    const timeIndex = headers.indexOf('measurement_taken_on(utc)');
    const valueIndex = headers.indexOf('measurement_value');
    const qualityFlagIndex = headers.indexOf('measurement_quality_flag');
    const qualityIndexIndex = headers.indexOf('measurement_quality_index');
    const qualityReasonIndex = headers.indexOf('measurement_quality_reason');

    if (timeIndex === -1 || valueIndex === -1) {
        throw new Error('Missing required columns in the CSV data.');
    }

    return {
        sensorId: type === SensorType.ABB_SKIN_TEMPERATURE ? abbSkinSensorId : motorSurfaceTemperatureSensorId,
        sensorName: type === SensorType.ABB_SKIN_TEMPERATURE ? abbSkinSensorName : motorSurfaceTemperatureSensorName,
        type,
        data: data.slice(1).map(row => ({
            MEASUREMENT_TAKEN_ON: row[timeIndex],
            MEASUREMENT_VALUE: parseFloat(row[valueIndex]),
            MEASUREMENT_QUALITY_FLAG: qualityFlagIndex !== -1 ? row[qualityFlagIndex] : 'Unknown',
            MEASUREMENT_QUALITY_INDEX: qualityIndexIndex !== -1 ? row[qualityIndexIndex] : 'Unknown',
            MEASUREMENT_QUALITY_REASON: qualityReasonIndex !== -1 ? row[qualityReasonIndex] : 'Unknown',
        })),
    };
};

export const parseMonetTemperatureData = (data: string[][]): MonetTemperatureCSVData => {
    return {
        sensorId: monetTemperatureSensorId,
        sensorName: monetTemperatureSensorName,
        type: SensorType.MONET_TEMPERATURE,
        data: data.slice(1).map(row => {
            const cleanedRow = row.slice(0, 13);
            return {
                DataMessageGUID: cleanedRow[0],
                SensorID: parseInt(cleanedRow[1]),
                'Sensor Name': cleanedRow[2],
                Date: cleanedRow[3],
                Value: parseFloat(cleanedRow[4]),
                'Formatted Value': cleanedRow[5],
                Battery: parseInt(cleanedRow[6]),
                'Raw Data': parseFloat(cleanedRow[7]),
                'Sensor State': parseInt(cleanedRow[8]),
                GatewayID: parseInt(cleanedRow[9]),
                'Alert Sent': cleanedRow[10] === 'true',
                'Signal Strength': parseInt(cleanedRow[11]),
                Voltage: parseFloat(cleanedRow[12]),
            };
        }),
    };
};

// client utils

export function getSensorName(urn: string): string | null {
    const regex = /urn:ngsi-ld:[^:]+:([^-]+)-(.+)/;
    const match = urn.match(regex);
    return match ? match[1] : null;
}

export function getSensorId(urn: string): string | null {
    const regex = /urn:ngsi-ld:[^:]+:([^-]+)-(.+)/;
    const match = urn.match(regex);
    return match ? match[2] : null;
}

const abbSkinTemperatureId = process.env.REACT_APP_ABB_SKIN_TEMPERATURE_ID;
const monetTemperatureId = process.env.REACT_APP_MONET_TEMPERATURE_ID;
const motorSurfaceTemperatureId = process.env.REACT_APP_MOTOR_SURFACE_TEMPERATURE_ID;
const vibratorSamplingId = process.env.REACT_APP_VIBRATOR_SAMPLING_ID;

const region = process.env.VUE_APP_AWS_REGION ?? '';

export const getActualValue = (data: SensorData) => {
    if (data.id === monetTemperatureId) {
        const value = (data as MonetTemperatureSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature'].value;
        const observedAt = (data as MonetTemperatureSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature']
            .observedAt;
        return {value, observedAt};
    } else if (data.id === abbSkinTemperatureId) {
        const value = (data as AbbSkinTemperatureSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature'].value;
        const observedAt = (data as AbbSkinTemperatureSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature']
            .observedAt;
        return {value, observedAt};
    } else if (data.id === motorSurfaceTemperatureId) {
        const value = (data as MotorSurfaceTemperatureSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature']
            .value;
        const observedAt = (data as MotorSurfaceTemperatureSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature']
            .observedAt;
        return {value, observedAt};
    } else if (data.id === vibratorSamplingId) {
        const value = (data as VibratorSamplingSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/voltage'].value;
        const observedAt = (data as VibratorSamplingSensorData)['https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/voltage'].observedAt;
        return {value, observedAt};
    }
};

export const sha256 = (data: string) => {
    return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
};

export async function createPresignedUrl(endpoint: string, credentials: AwsCredentialIdentity) {
    const service = 'iotdevicegateway';
    const algorithm = 'AWS4-HMAC-SHA256';
    const expiresIn = 900;

    const sigV4 = new SignatureV4({
        sha256: Sha256,
        service,
        region,
        credentials,
    });

    const date = new Date().toISOString().replaceAll(/[:-]|\.\d{3}/gu, '');
    const credentialScope = `${date.slice(0, 8)}/${region}/${service}/aws4_request`;

    const parameters: {[key: string]: string} = {
        'X-Amz-Algorithm': algorithm,
        'X-Amz-Credential': `${encodeURIComponent(`${credentials.accessKeyId}/${credentialScope}`)}`,
        'X-Amz-Date': date,
        'X-Amz-Expires': expiresIn.toString(),
        'X-Amz-SignedHeaders': 'host',
    };

    const path = '/mqtt';
    const headers = `host:${endpoint}\n`;
    const canonicalRequest = `GET\n${path}\n${toQueryString(parameters)}\n${headers}\nhost\n${sha256('')}`;
    const stringToSign = `${algorithm}\n${date}\n${credentialScope}\n${sha256(canonicalRequest)}`;

    parameters['X-Amz-Signature'] = await sigV4.sign(stringToSign);
    if (credentials.sessionToken) {
        parameters['X-Amz-Security-Token'] = encodeURIComponent(credentials.sessionToken);
    }

    return `wss://${endpoint}${path}?${toQueryString(parameters)}`;
}

export const toQueryString = (queryStrings: object) =>
    Object.entries(queryStrings)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

export const generateData = () => {
    const data = [];
    const startDate = new Date('2023-10-01').getTime();
    const endDate = new Date('2023-10-10').getTime();

    for (let i = startDate; i <= endDate; i += 1000 * 60 * 60) {
        data.push({
            time: i,
            value: Math.floor(Math.random() * 100),
        });
    }
    return data.sort((a, b) => a.time - b.time);
};

export const calculatePercentile = (data: number[], percentile: number): number => {
    if (data.length === 0) return NaN;

    const sortedData = [...data].sort((a, b) => a - b);
    const index = (percentile / 100) * sortedData.length;
    const lowerIndex = Math.floor(index);
    const upperIndex = Math.ceil(index);

    if (lowerIndex >= sortedData.length || upperIndex >= sortedData.length) {
        return sortedData[sortedData.length - 1]; // Return the maximum value
    }

    if (lowerIndex === upperIndex) {
        return sortedData[lowerIndex];
    }

    return sortedData[lowerIndex] + (index - lowerIndex) * (sortedData[upperIndex] - sortedData[lowerIndex]);
};
