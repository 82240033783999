var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "sinusoidal-function" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h5", [_vm._v("y = A · sin((2π · F · x + P)) + B")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        _vm._l(["A", "F", "P", "B"], function (param) {
          return _c(
            "v-col",
            { key: param, attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: param,
                  value: _vm.parameters[param],
                  type: "number",
                  outlined: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.updateParameter(param, $event)
                  },
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-row",
        _vm._l(
          [
            "samplingRate",
            "duration",
            "noiseAmplitude",
            "xOffset",
            "decayRate",
          ],
          function (param) {
            return _c(
              "v-col",
              { key: param, attrs: { cols: "3" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: param,
                    value: _vm.parameters[param],
                    type: "number",
                    outlined: "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.updateParameter(param, $event)
                    },
                  },
                }),
              ],
              1
            )
          }
        ),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }