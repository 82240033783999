var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "csv-parser" },
    [
      _c(
        "v-btn",
        {
          staticClass: "file-upload-btn",
          attrs: { loading: _vm.isLoading, outlined: "" },
          on: { click: _vm.triggerFileUpload },
        },
        [
          _vm._v(
            " Select " + _vm._s(_vm.detectedSensor ? "New" : "") + " CSV "
          ),
          _c("input", {
            ref: "fileInput",
            attrs: { type: "file", hidden: "" },
            on: { change: _vm.handleFileUpload },
          }),
        ]
      ),
      _vm.detectedSensor
        ? _c("div", { staticClass: "sensor-info" }, [
            _c("p", [_vm._v("Detected Sensor: " + _vm._s(_vm.detectedSensor))]),
          ])
        : _vm._e(),
      _vm.error
        ? _c("p", { staticClass: "error-message" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }