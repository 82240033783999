import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {CreateEditEquipmentCapViewModel, EquipmentCapModel, UpdateCapSortOrderEquipmentCapModel} from '../models';

@ServiceClass()
export class EquipmentCapsApi extends BaseApi {
    public getLocationCaps(locationId: string, filter: any = {}) {
        return this.GET_RequestAsync<EquipmentCapModel[]>(
            `${this.ApiUrl}/locations/${locationId}/equipment-caps?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
    }

    public getCapById(id: string) {
        return this.GET_RequestAsync<EquipmentCapModel>(`${this.ApiUrl}/equipment-caps/${id}`);
    }

    public createCapsMultiple(locationId: string, afterOrder: number, data: CreateEditEquipmentCapViewModel[]) {
        return this.POST_RequestAsync<EquipmentCapModel[]>(`${this.ApiUrl}/locations/${locationId}/equipment-caps/multiple?afterOrder=${afterOrder}`, data);
    }

    public updateCap(locationId: string, capId: string, data: CreateEditEquipmentCapViewModel) {
        delete data.id;
        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/locations/${locationId}/equipment-caps/${capId}`, data);
    }

    public deleteCap(id: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/equipment-caps/${id}`);
    }

    public updateSortOrder(locationId: string, data: UpdateCapSortOrderEquipmentCapModel) {
        return this.POST_RequestAsync<void>(`${this.ApiUrl}/locations/${locationId}/equipment-caps/sort-order`, data);
    }
}
