var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "canvas-container" }, [
    _c("canvas", { ref: "bjsCanvas", staticClass: "full-screen-canvas" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }