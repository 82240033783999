var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    attrs: { items: _vm.data, dense: "" },
    scopedSlots: _vm._u(
      [
        {
          key: `item.sensorName`,
          fn: function ({ item }) {
            return [_vm._v(" " + _vm._s(item.sensorName) + " ")]
          },
        },
        {
          key: `item.value`,
          fn: function ({ item }) {
            return [_vm._v(" " + _vm._s(item.value) + " ")]
          },
        },
        {
          key: `item.time`,
          fn: function ({ item }) {
            return [
              _vm._v(" " + _vm._s(new Date(item.time).toLocaleString()) + " "),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }