var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _vm.siteLocationsSrv.selectedLcoation.id
            ? _c("sensor-management-component", {
                key: _vm.siteLocationsSrv.selectedLcoation.id,
                attrs: {
                  siteLocationId: _vm.siteLocationsSrv.selectedLcoation.id,
                  readOnlyFlag: _vm.readOnlyFlag,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }