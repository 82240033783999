import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {FieldOptions} from '@/sdk';
import {CreatePointApi} from '@/sdk/api-services';
import TagManagementComponent from '@/views/client/site-setup/site-setup-location-form/tag-management/tag-management.component';
import AreaRulesSummaryDialogComponent from '@/views/shared/area-rules-summary-dialog/area-rules-summary-dialog.component';
import {AdminDataFieldService, DustDbService, GasDbService} from '@/views/shared/services';
import {SiteLocationService} from '@/views/shared/services/site-location.service';
import {Component, Prop} from 'vue-property-decorator';
import DigitalTwinCanvasComponent from '../../digital-twin/digital-twin-canvas/digital-twin-canvas.component';
import IoTDataSimulator from '../../iot-simulator/IoTDataSimulator.component.vue';
import AreaDocumentationComponent from './area-documentation/area-documentation.component';

@Component({
    name: 'SiteSetupLocationFormComponent',
    components: {
        AreaDocumentationComponent,
        AreaRulesSummaryDialogComponent,
        TagManagementComponent,
        DigitalTwinCanvasComponent,
        IoTDataSimulator,
    },
})
export default class SiteSetupLocationFormComponent extends VueWrapper {
    public $refs!: {
        areaRulesSummaryDialogRef: AreaRulesSummaryDialogComponent;
    };

    @Prop({
        required: true,
    })
    locationPath!: Array<any>;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readOnlyFlag!: boolean;

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public gasDbSrv: GasDbService = new GasDbService();
    public dustDbSrv: DustDbService = new DustDbService();

    // OPTIONS
    // area: Array<String> = ['Area 1', 'Area 2'];
    // type: Array<String> = ['Type1', 'Type2', 'Type3'];
    // subArea: Array<String> = ['Sub-Area 1', 'Sub-Area 2'];
    // flammable: Array<String> = ['Gasoline', 'Propane', 'Hydrogen', 'Wood', 'Diesel', 'Potasium'];
    // explosionConsequence: Array<String> = ['1 - Slight', '2 - Minor', '3 - Major', '4 - Critical', '5 - Catastrophic'];
    // ipx: Array<String> = ['1', '2', '3', '4', '5', '6'];
    // ipy: Array<String> = ['1', '2', '3', '4', '5', '6', '7', '8', '9k'];
    // inspFreq: Array<String> = ['3 years', '2 years', '1 year', '6 months', '3 months'];
    // approvalType: Array<String> = ['IECEx', 'KCs', 'ATEX', 'North America'];
    // gasZone: Array<String> = ['Zone 0', 'Zone 1', 'Zone 2', 'Safe Area'];
    // gasGroup: Array<String> = ['I', 'IIA', 'IIB', 'IIB + H2', 'IIC'];
    // tempClass: Array<String> = ['T1', 'T2', 'T3', 'T4', 'T5', 'T6'];
    // dustZone: Array<String> = ['Zone 20', 'Zone 21', 'Zone 22', 'Safe Area'];
    // dustGroup: Array<String> = ['IIIA', 'IIIB', 'IIIC'];
    // dustIgnition: Array<String> = ['Dust Cloud', 'Dust Layer/Depth/Temp'];
    // envCond: Array<String> = ['Indoor', 'Outdoor', 'Hot', 'Cold', 'Dusty', 'Coastal'];
    // gradeofInspection: Array<String> = ['D', 'C', 'V'];

    //INPUTS
    // titleInput: String | null = null;
    // typeInput: String | null = null;
    // areaInput: String | null = null;
    // subAreaInput: String | null = null;
    // flammableInput: String | null = null;
    // explosionInput: String | null = null;
    // ipxInput: String | null = null;
    // ipyInput: String | null = null;
    // ambTempInput: String | null = null;
    // inspFrequencyInput: String | null = null;
    // approvalTypeInput: String | null = null;
    // gasZoneInput: String | null = null;
    // gasGroupInput: String | null = null;
    // tempClassInput: String | null = null;
    // dustZoneInput: String | null = null;
    // dustGroupInput: String | null = null;
    // dustIgnitionSelectionInput: String | null = null;
    // dustIgnitionInput: String | null = null;
    // envCondInput: String | null = null;

    public createPointApi: CreatePointApi = new CreatePointApi();
    // public submitPoint() {
    //     this.LoaderSrv.showFullScreenLoader();
    //     this.createPointApi
    //         .CreatePoint({
    //             title: this.titleInput,
    //             type: this.typeInput,
    //             area: this.areaInput,
    //             subarea: this.subAreaInput,
    //             flammable: this.flammableInput,
    //             explosion: this.explosionInput,
    //             ipx: this.ipxInput,
    //             ipy: this.ipyInput,
    //             ambtemp: this.ambTempInput,
    //             inspfreq: this.inspFrequencyInput,
    //             approvaltype: this.approvalTypeInput,
    //             gaszone: this.gasZoneInput,
    //             gasgroup: this.gasGroupInput,
    //             tempclass: this.tempClassInput,
    //             dustzone: this.dustZoneInput,
    //             dustgroup: this.dustGroupInput,
    //             dustign: this.dustIgnitionSelectionInput,
    //             dustignin: this.dustIgnitionInput,
    //             envcond: this.envCondInput,
    //         })
    //         .subscribe(
    //             res => {
    //                 this.$router.go(0);

    //                 console.log(res);
    //             },
    //             err => {
    //                 console.log('err', err);
    //             }
    //         )
    //         .add(() => {
    //             this.LoaderSrv.hideFullScreenLoader();
    //         });
    // }

    public mounted() {
        this.adminDataFieldsSrv.loadAreaFields();

        this.adminDataFieldsSrv.loadAreaFieldOptions();

        this.gasDbSrv.loadGasDb();
        this.dustDbSrv.loadDustDb();
    }

    public flammableSubstanceFilter(item: FieldOptions, queryText: string, itemText: string) {
        return (
            itemText.toLowerCase().includes(queryText.toLowerCase()) ||
            item.extras?.caseNo?.toLowerCase().includes(queryText.toLowerCase()) ||
            item.extras?.otherName?.toLowerCase().includes(queryText.toLowerCase()) ||
            item.extras?.formula?.toLowerCase().includes(queryText.toLowerCase())
        );
    }

    public get flameableGasInfo() {
        const tmp = this.siteLocationsSrv.selectedLcoation?.locationDetail?.flammableSubstances ?? [];

        const gasDbObjs = this.gasDbSrv.gasDbData.filter(x => tmp.includes(x.id!));

        return gasDbObjs ?? [];
    }

    public get flameableDustInfo() {
        const tmp = this.siteLocationsSrv.selectedLcoation?.locationDetail?.flammableDust ?? [];

        const dustList = this.dustDbSrv.dustDbData.filter(x => tmp.includes(x.id!));

        return dustList ?? [];
    }

    public onSelected(event: any, name: string) {
        if (name === 'areaFlammableSubstance' && this.siteLocationsSrv.selectedLcoation?.locationDetail?.flammableSubstances) {
            const tmp = this.siteLocationsSrv.selectedLcoation.locationDetail.flammableSubstances;

            const gasDbObjs = this.gasDbSrv.gasDbData.filter(x => tmp.includes(x.id!));
            const gassesTClasses = gasDbObjs.map(x => x.tClass?.trim()) ?? [];
            const gassesGroups = gasDbObjs.map(x => x.equipmentGroup?.trim()) ?? [];

            const tempOpts = this.adminDataFieldsSrv.areaFieldOptions[this.areaFieldsList.areaGasTemperatureClass] as Array<FieldOptions>;
            const gasGroupOpts = this.adminDataFieldsSrv.areaFieldOptions[this.areaFieldsList.areaGasGroup] as Array<FieldOptions>;

            const opts = tempOpts.filter(x => gassesTClasses.includes(x.value!));
            const groupOpts = gasGroupOpts.filter(x => gassesGroups.includes(x.value!));

            const max = opts.length ? opts.reduce((max, cur) => ((max.regressPriority || 0) > (cur.regressPriority || 0) ? max : cur)) : null;
            const maxGroup = groupOpts.length ? groupOpts.reduce((max, cur) => ((max.regressPriority || 0) > (cur.regressPriority || 0) ? max : cur)) : null;

            this.siteLocationsSrv.selectedLcoation.locationDetail.tempClass = max?.id || null;
            this.siteLocationsSrv.selectedLcoation.locationDetail.gasGroup = maxGroup?.id || null;
        } else if (name === 'areaFlammableDust' && this.siteLocationsSrv.selectedLcoation?.locationDetail?.flammableDust) {
            //
        }
    }

    public summaryClick() {
        this.$refs.areaRulesSummaryDialogRef?.open();
    }
}
