import {PIN_DIRECTION} from '@/globals';

export interface EquipmentPinModel {
    id: string;
    title: string;
    locationId: string;
    pinDirection: PIN_DIRECTION;
    created: string;
    equipmentCapId?: string;
    connectedPinId?: string;
    isActive: boolean;
    sortOrder: number;

    // constructor(data: Partial<EquipmentPinModel>) {
    //     Object.assign(this, data);
    // }
}

export class CreateEditEquipmentPinViewModel implements Omit<EquipmentPinModel, 'id' | 'locationId' | 'isActive' | 'created'> {
    id?: string;
    title: string;
    pinDirection: PIN_DIRECTION;
    equipmentCapId?: string;
    connectedPinId?: string;
    sortOrder: number;

    constructor(data: CreateEditEquipmentPinViewModel) {
        Object.assign(this, data);
    }
}

export interface UpdatePinSortOrderEquipmentCapModel {
    dragedPinId: string;
    targetPinId: string;
    where: 'before' | 'after';
}
