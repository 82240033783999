export interface SensorModel {
    id: string;
    sensorId: string;
    sensorType: string;
    ip: string;
    locationId: string;
    active?: boolean;
    created?: string;
    updated?: string;
}

export class CreateUpdateSensorRequest implements Omit<SensorModel, 'id' | 'active' | 'created' | 'updated'> {
    sensorId: string;
    sensorType: string;
    ip: string;
    locationId: string;

    constructor(data: CreateUpdateSensorRequest) {
        this.sensorId = data.sensorId;
        this.sensorType = data.sensorType;
        this.ip = data.ip;
        this.locationId = data.locationId;
    }
}

export interface SensorWithRelations extends SensorModel {
    location: Location;
}
