var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mini-topbar d-flex" },
        [
          _c(
            "v-avatar",
            {
              staticClass: "pa-2",
              staticStyle: { left: "20px" },
              attrs: {
                tile: "",
                "min-width": "55",
                width: this.$vuetify.breakpoint.width >= 996 ? 100 : 80,
                "max-width": "100",
                height: "48",
              },
            },
            [
              _c("v-img", {
                attrs: {
                  contain: "",
                  src: require("@/assets/logos/full_logo_horizontal_dark.svg"),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "ml-auto mr-2 height-100x justify-center align-center d-flex",
              attrs: { id: "appbarLangMenu" },
            },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    center: "",
                    left: "",
                    origin: "center center",
                    transition: "slide-y-reverse-transition",
                    attach: "#appbarLangMenu",
                    "offset-overflow": "",
                    "nudge-left": "40",
                    "min-width": "140",
                    "close-on-content-click": false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass:
                                    "cursor-pointer d-flex align-center",
                                },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "base-btn",
                                { attrs: { color: "white", outlined: "" } },
                                [_vm._v(_vm._s(_vm.getLocale))]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.langMenu,
                    callback: function ($$v) {
                      _vm.langMenu = $$v
                    },
                    expression: "langMenu",
                  },
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "", nav: "", width: "150" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.langClick("en")
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "cursor-pointer" },
                            [_vm._v(" EN ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.langClick("ko")
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "cursor-pointer" },
                            [_vm._v(" KO ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !!_vm.Session.userId
            ? _c(
                "div",
                {
                  staticClass: "d-flex",
                  staticStyle: { "margin-right": "28px" },
                  attrs: { id: "appbarMenuWrap" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        center: "",
                        left: "",
                        origin: "center center",
                        transition: "slide-y-reverse-transition",
                        attach: "#appbarMenuWrap",
                        "offset-overflow": "",
                        "nudge-left": "40",
                        "min-width": "140",
                        "close-on-content-click": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "cursor-pointer d-flex align-center",
                                      },
                                      "div",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "white accent-1 mr-1",
                                        attrs: { size: "28" },
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: _vm.User.ImageUrl
                                              ? _vm.User.ImageUrl
                                              : _vm.CoreSrv.UserImage,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2244452848
                      ),
                      model: {
                        value: _vm.UserMenu,
                        callback: function ($$v) {
                          _vm.UserMenu = $$v
                        },
                        expression: "UserMenu",
                      },
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "", nav: "", width: "300" } },
                        [
                          _c(
                            "v-list-item",
                            { on: { click: _vm.MyProfileClick } },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-3" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("mdi-account")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-title",
                                { staticClass: "cursor-pointer" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("ClientAppbar.MyProfile")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { on: { click: _vm.onAccountInfoClick } },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-3" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("mdi-account")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-title",
                                { staticClass: "cursor-pointer" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("ClientAppbar.AccountInfo")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.UserLogout()
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-3" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("mdi-logout")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-title",
                                { staticClass: "cursor-pointer" },
                                [_vm._v(_vm._s(_vm.$t("ClientAppbar.Logout")))]
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "mb-1" }),
                          _c(
                            "v-list-group",
                            {
                              attrs: { "no-action": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              { staticClass: "mr-3" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "secondary",
                                                    },
                                                  },
                                                  [_vm._v("mdi-account-cog")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "ClientAppbar.OwnerSetup"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2117880236
                              ),
                            },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "mr-3" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v("mdi-credit-card")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "cursor-pointer" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("ClientAppbar.Payment")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { attrs: { to: { name: "User Setup" } } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "mr-3" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v("mdi-account-group")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "cursor-pointer" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("ClientAppbar.Users")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  attrs: { to: { name: "Access Management" } },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "mr-3" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v("mdi-security")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "cursor-pointer" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("ClientAppbar.Access")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: { name: "admin-panel" },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "mr-3" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v("mdi-shield-crown")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "cursor-pointer" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("ClientAppbar.AdminPortal")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-app-bar",
        {
          staticClass: "white",
          staticStyle: { top: "48px" },
          attrs: {
            height: _vm.navHeight,
            absolute: "",
            app: "",
            id: "appbar",
            elevation: 0,
          },
        },
        [
          _c(
            "v-container",
            {
              staticClass: "d-flex flex-nowrap align-center",
              class: [{ "px-0": this.$vuetify.breakpoint.width <= 1019 }],
              staticStyle: { "justify-content": "space-between" },
              attrs: { fluid: "" },
            },
            [
              _c("v-app-bar-nav-icon", {
                staticClass: "d-md-none",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.CoreSrv.drawer = !_vm.CoreSrv.drawer
                  },
                },
              }),
              _c(
                "base-btn",
                {
                  staticClass: "top-menu-links border-left",
                  attrs: {
                    disabled: !_vm.selectedService.selectedSite,
                    color: "d-flex",
                    height: "64",
                    to: {
                      name: "site-dashboard",
                      params: { siteId: _vm.selectedService.selectedSite },
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        "flex-direction": "column",
                        gap: "0.4rem",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [
                        _vm._v(" mdi-view-grid "),
                      ]),
                      _c("span", [_vm._v(" Dashboard ")]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "base-btn",
                {
                  staticClass: "top-menu-links border-left",
                  attrs: {
                    disabled: !_vm.selectedService.selectedSite,
                    color: "d-flex",
                    height: "64",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        "flex-direction": "column",
                        gap: "0.4rem",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [
                        _vm._v(" mdi-poll "),
                      ]),
                      _c("span", [_vm._v(" Analytics ")]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "base-btn",
                {
                  staticClass: "top-menu-links border-left border-right",
                  attrs: {
                    disabled: !_vm.selectedService.selectedSite,
                    color: "d-flex",
                    height: "64",
                    to: {
                      name: "site-jobs-report",
                      params: { siteId: _vm.selectedService.selectedSite },
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        "flex-direction": "column",
                        gap: "0.4rem",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [
                        _vm._v(" mdi-file-multiple "),
                      ]),
                      _c("span", [_vm._v(" Reports ")]),
                    ],
                    1
                  ),
                ]
              ),
              _vm.userSession.role === _vm.$ROLES.SYS_ADMIN ||
              _vm.userSession.role === _vm.$ROLES.ACCOUNT_ADMIN
                ? _c(
                    "div",
                    [
                      _c(
                        "base-btn",
                        {
                          staticClass: "top-menu-links",
                          attrs: { color: "d-flex", height: "64", width: "90" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({ name: "UserSetupMain" })
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex",
                              staticStyle: { "flex-direction": "column" },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  height: "30",
                                  contain: "",
                                  src: require("@/assets/icons/user-admin.png"),
                                },
                              }),
                              _c("span", [_vm._v(" Admin Config ")]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "text-center top-menu-slider-items" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1 top-menu-slider-parent first",
                      attrs: {
                        exact: "",
                        "exact-path": "",
                        "active-class":
                          "white--text active-number active-number-orange",
                        elevation: "0",
                        to: { name: "sites-list" },
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex top-menu-slider" }, [
                        _c(
                          "span",
                          { staticClass: "top-menu-square-number font" },
                          [_vm._v("1")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "slider-name",
                            staticStyle: { color: "black" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("ClientAppbar.Steps.SiteList"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1 top-menu-slider-parent",
                      attrs: {
                        disabled: !_vm.selectedService.selectedSite,
                        exact: "",
                        "exact-path": "",
                        "active-class": "white--text active-number",
                        elevation: "0",
                        to: {
                          name: "site-setup",
                          params: { siteId: _vm.selectedService.selectedSite },
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex top-menu-slider" }, [
                        _c(
                          "span",
                          { staticClass: "top-menu-circle-number font" },
                          [_vm._v("2")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "slider-name",
                            staticStyle: { color: "black" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("ClientAppbar.Steps.SiteSetup"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1 top-menu-slider-parent",
                      attrs: {
                        disabled: !_vm.selectedService.selectedSite,
                        exact: "",
                        "exact-path": "",
                        "active-class": "white--text active-number",
                        elevation: "0",
                        to: {
                          name: "digital-twin",
                          params: { siteId: _vm.selectedService.selectedSite },
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex top-menu-slider" }, [
                        _c(
                          "span",
                          { staticClass: "top-menu-circle-number font" },
                          [_vm._v("3")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "slider-name",
                            staticStyle: { color: "black" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("ClientAppbar.Steps.DigitalTwin"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1 top-menu-slider-parent",
                      attrs: {
                        disabled: !_vm.selectedService.selectedSite,
                        exact: "",
                        "exact-path": "",
                        "active-class": "white--text active-number",
                        elevation: "0",
                        to: {
                          name: "diagram-view",
                          params: { siteId: _vm.selectedService.selectedSite },
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex top-menu-slider" }, [
                        _c(
                          "span",
                          { staticClass: "top-menu-circle-number font" },
                          [_vm._v("4")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "slider-name",
                            staticStyle: { color: "black" },
                          },
                          [_vm._v(_vm._s(_vm.$t("ClientAppbar.Steps.Circuit")))]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1 top-menu-slider-parent",
                      attrs: {
                        disabled: !_vm.selectedService.selectedSite,
                        exact: "",
                        "exact-path": "",
                        "active-class": "white--text active-number",
                        elevation: "0",
                        to: {
                          name: "Team Main",
                          params: { siteId: _vm.selectedService.selectedSite },
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex top-menu-slider" }, [
                        _c(
                          "span",
                          { staticClass: "top-menu-circle-number font" },
                          [_vm._v("5")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "slider-name",
                            staticStyle: { color: "black" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("ClientAppbar.Steps.TeamSetup"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-1 top-menu-slider-parent last",
                      attrs: {
                        disabled: !_vm.selectedService.selectedSite,
                        exact: "",
                        "exact-path": "",
                        "active-class": "white--text active-number",
                        elevation: "0",
                        to: {
                          name: "Job Main",
                          params: { siteId: _vm.selectedService.selectedSite },
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex top-menu-slider" }, [
                        _c(
                          "span",
                          { staticClass: "top-menu-circle-number font" },
                          [_vm._v("6")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "slider-name",
                            staticStyle: { color: "black" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("ClientAppbar.Steps.JobSetup"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              !_vm.Session.loginId
                ? _c(
                    "div",
                    { staticClass: "d-flex d-md-none round-15 cursor-pointer" },
                    [
                      _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple",
                              value: true,
                              expression: "true",
                            },
                          ],
                          staticClass: "secondary--text",
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({ name: "signin" })
                            },
                          },
                        },
                        [_vm._v(" mdi-login ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("MyProfileDialogComponent", {
            ref: "myProfileDialogRef",
            attrs: {
              name: "app-bar-add-user-dialog",
              accountId: _vm.userSession.Session.accountId,
            },
          }),
          _c("AccountInfoDialogComponent", { ref: "accountInfoDialog" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }