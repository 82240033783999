import {ServiceClass} from '@/decorators';
import {BaseApi} from './base.api';
import {CreateEditEquipmentPinViewModel, EquipmentPinModel, UpdatePinSortOrderEquipmentCapModel} from '../models';

@ServiceClass()
export class EquipmentPinsApi extends BaseApi {
    public getLocationPins(locationId: string, filter: any = {}) {
        return this.GET_RequestAsync<EquipmentPinModel[]>(
            `${this.ApiUrl}/locations/${locationId}/equipment-pins?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
    }

    public getPinById(id: string) {
        return this.GET_RequestAsync<EquipmentPinModel>(`${this.ApiUrl}/equipment-pins/${id}`);
    }

    public createPin(locationId: string, data: CreateEditEquipmentPinViewModel) {
        return this.POST_RequestAsync<EquipmentPinModel>(`${this.ApiUrl}/locations/${locationId}/equipment-pins`, data);
    }

    public createPinsMultiple(locationId: string, afterOrder: number, data: CreateEditEquipmentPinViewModel[]) {
        return this.POST_RequestAsync<EquipmentPinModel[]>(`${this.ApiUrl}/locations/${locationId}/equipment-pins/multiple?afterOrder=${afterOrder}`, data);
    }

    public updatePin(locationId: string, pinId: string, data: CreateEditEquipmentPinViewModel) {
        delete data.id;
        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/locations/${locationId}/equipment-pins/${pinId}`, data);
    }

    public markPinActive(pinId: string) {
        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/equipment-pins/${pinId}/mark-active`, {});
    }

    public markPinInactive(pinId: string) {
        return this.PATCH_RequestAsync<void>(`${this.ApiUrl}/equipment-pins/${pinId}/mark-inactive`, {});
    }

    public deletePin(id: string) {
        return this.DELETE_RequestAsync<void>(`${this.ApiUrl}/equipment-pins/${id}`);
    }

    public updateSortOrder(locationId: string, data: UpdatePinSortOrderEquipmentCapModel) {
        return this.POST_RequestAsync<void>(`${this.ApiUrl}/locations/${locationId}/equipment-pins/sort-order`, data);
    }
}
