import VueWrapper from '@/components/core/Vue/vue.wrapper';
import ViewWrapperComponent from '@/components/shared/view-wrapper/view-wrapper.component';
import {CreateUpdateSensorRequest} from '@/sdk';
import {IotApi} from '@/sdk/api-services';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component({
    components: {
        ViewWrapperComponent,
    },
})
export default class CreateSensorDialogComponent extends VueWrapper {
    public $refs!: {
        form: HTMLFormElement;
    };

    @Prop({
        type: String,
        default: 'create-sensor-dialog',
    })
    public readonly name!: string;

    @Prop({
        type: String,
        required: true,
    })
    public siteLocationId!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readonly show!: boolean;

    @Prop({
        type: Function,
        required: true,
    })
    public fetchSensors!: () => void;

    public sensorId: string = '';
    public sensorType: string = '';
    public ip: string = '';
    public isLoading: boolean = false;
    public valid: boolean = false;

    public iotApi: IotApi = new IotApi();

    @Watch('show')
    public onShowChange(newValue: boolean) {
        if (newValue) {
            this.open();
        } else {
            this.close();
        }
    }

    public mounted() {
        if (this.show) {
            this.open();
        }
    }

    public get dialogTitle() {
        return 'Create Sensor';
    }

    public open() {
        if (this.CoreSrv.isModalOpened(this.name)) {
            this.close();
        }
        this.CoreSrv.OpenModal(this.name);
        this.$emit('opened');
    }

    public close() {
        this.CoreSrv.CloseModal(this.name);
        this.$emit('closed');
        this.$emit('update:show', false);
    }

    public createSensor() {
        this.ConfirmSrv.open('$t.Common.SensorManagement.CreateSensor.ConfirmationTitle', '$t.Common.SensorManagement.CreateSensor.ConfirmationMessage').then(
            yes => {
                if (yes) {
                    this.isLoading = true;
                    const sensor: CreateUpdateSensorRequest = {
                        sensorId: this.sensorId,
                        sensorType: this.sensorType,
                        ip: this.ip,
                        locationId: this.siteLocationId,
                    };

                    this.iotApi
                        .createSensor(sensor)
                        .then(() => {
                            this.fetchSensors();
                            this.isLoading = false;
                            this.close();
                            this.AlertSrv.show('success', '$t.Common.SensorManagement.CreateSensor.Success');
                        })
                        .catch(error => {
                            this.isLoading = false;
                            this.AlertSrv.show('error', error.message);
                        });
                }
            }
        );
    }
}
