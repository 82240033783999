import {EquipmentData, LocationData} from './foex';

export interface BaseData {
    type: SensorType;
    sensorId: string;
    sensorName: string;
}

export type CSVData = VibratorSamplingCSVData | TemperatureCSVData | MonetTemperatureCSVData;

export interface VibratorSamplingCSVData extends BaseData {
    type: SensorType.VIBRATOR_SAMPLING;
    model: string;
    firmwareVersion: string;
    pointFormat: string;
    horizontalUnits: string;
    horizontalScale: number;
    sampleInterval: number;
    filterFrequency: number;
    recordLength: number;
    gating: string;
    probeAttenuation: number;
    verticalUnits: string;
    verticalOffset: number;
    verticalScale: number;
    label: string;
    data: Array<{TIME: string; CH1: number}>;
}

export interface TemperatureCSVData extends BaseData {
    type: SensorType.ABB_SKIN_TEMPERATURE | SensorType.MOTOR_SURFACE_TEMPERATURE;
    data: Array<{
        MEASUREMENT_TAKEN_ON: string;
        MEASUREMENT_VALUE: number;
        MEASUREMENT_QUALITY_FLAG: string;
        MEASUREMENT_QUALITY_INDEX: string;
        MEASUREMENT_QUALITY_REASON: string;
    }>;
}

export interface MonetTemperatureCSVData extends BaseData {
    type: SensorType.MONET_TEMPERATURE;
    data: Array<{
        DataMessageGUID: string;
        SensorID: number;
        'Sensor Name': string;
        Date: string;
        Value: number;
        'Formatted Value': string;
        Battery: number;
        'Raw Data': number;
        'Sensor State': number;
        GatewayID: number;
        'Alert Sent': boolean;
        'Signal Strength': number;
        Voltage: number;
    }>;
}

export enum SensorType {
    ABB_SKIN_TEMPERATURE = 'ABB_SKIN_TEMPERATURE',
    MONET_TEMPERATURE = 'MONET_TEMPERATURE',
    MOTOR_SURFACE_TEMPERATURE = 'MOTOR_SURFACE_TEMPERATURE',
    VIBRATOR_SAMPLING = 'VIBRATOR_SAMPLING',
    GENERATED = 'GENERATED_SINUSOIDAL',
}

export interface Notification {
    id: string;
    type: string;
    subscriptionId: string;
    notifiedAt: string;
    data: SensorData[];
}

export interface SensorData {
    id: string;
    type: string;
    foexLocationMetadata: {
        value: LocationData;
    };
    foexSensorMetadata: {
        value: EquipmentData;
    };
    location: object;
}

export interface MonetTemperatureSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/dateObserved': {
        value: string;
    };
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature': {
        value: string;
        observedAt: string;
    };
    guid: {
        value: string;
    };
}

export interface AbbSkinTemperatureSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/dateObserved': {
        value: string;
    };
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature': {
        value: string;
        observedAt: string;
    };
    qualityFlag: {
        value: string;
    };
    qualityIndex: {
        value: string;
    };
}

export interface VibratorSamplingSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/dateObserved': {
        value: string;
    };
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/voltage': {
        value: string;
        observedAt: string;
    };
}

export interface MotorSurfaceTemperatureSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature': {
        value: string;
        observedAt: string;
    };
    qualityFlag: {
        value: string;
    };
    qualityIndex: {
        value: string;
    };
}

export enum DataDisplayType {
    SAMPLE = 'SAMPLE',
    PERCENTILE = 'PERCENTILE',
    QUANTILE = 'QUANTILE',
}

// CLIENT types
export interface Notification {
    id: string;
    type: string;
    subscriptionId: string;
    notifiedAt: string;
    data: SensorData[];
}

export interface SensorData {
    id: string;
    type: string;
    foexLocationMetadata: {
        value: LocationData;
    };
    foexSensorMetadata: {
        value: EquipmentData;
    };
    location: object;
}

export interface MonetTemperatureSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/dateObserved': {
        value: string;
    };
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature': {
        value: string;
        observedAt: string;
    };
    guid: {
        value: string;
    };
}

export interface AbbSkinTemperatureSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/dateObserved': {
        value: string;
    };
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature': {
        value: string;
        observedAt: string;
    };
    qualityFlag: {
        value: string;
    };
    qualityIndex: {
        value: string;
    };
}

export interface VibratorSamplingSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/dateObserved': {
        value: string;
    };
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/voltage': {
        value: string;
        observedAt: string;
    };
}

export interface MotorSurfaceTemperatureSensorData extends SensorData {
    'https://smart-data-models.github.io/data-models/terms.jsonld#/definitions/temperature': {
        value: string;
        observedAt: string;
    };
    qualityFlag: {
        value: string;
    };
    qualityIndex: {
        value: string;
    };
}
