import MainLayoutComponent from '@/components/layouts/main-layout/main-layout.component';
import {RouteConfig} from 'vue-router';
import DiagramViewMainComponent from './diagram-view/diagram-view-main/diagram-view-main.component';
import DigitalTwinMainComponent from './digital-twin/digital-twin-main/digital-twin-main.component';
import InformationDetailsComponent from './informationDetails/informationDetails.component';
import InformationDetailsAreaComponent from './informationDetails/informationDetailsArea.component';
import InformationDetailsEquipmentComponent from './informationDetails/informationDetailsEquipment.component';
import InformationDetailsSubComponent from './informationDetails/informationDetailsSub.component';
import IoTDataSimulator from './iot-simulator/IoTDataSimulator.component';
import searchSiteComponent from './searchSite/searchSite.component'; //change upper case
import SitesListComponent from './site-list/site-list.component';
import SiteSetupMainComponent from './site-setup/site-setup-main/site-setup-main.component';
import ButtonBarComponent from './site/buttonBar.component';
import HeaderComponent from './site/header.component';

export const ClientRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Main Component',
        component: MainLayoutComponent,
        redirect: {name: 'sites-list'},
        children: [
            {
                path: 'sites/list',
                name: 'sites-list',
                component: SitesListComponent,
                meta: {
                    title: 'Site List',
                    description: 'Site List page',
                },
            },
            {
                path: 'sites/:siteId/site-dashboard',
                name: 'site-dashboard',
                component: SiteSetupMainComponent,
            },
            {
                path: 'sites/:siteId/setup',
                name: 'site-setup',
                component: SiteSetupMainComponent,
                meta: {
                    title: 'Site Setup',
                    description: 'Site Setup page',
                },
            },
            {
                path: 'sites/:siteId/digital-twin',
                name: 'digital-twin',
                component: DigitalTwinMainComponent,
                meta: {
                    title: 'Digital Twin',
                    description: 'Digital Twin page',
                },
            },
            {
                path: '/iot-simulator',
                name: 'iot-simulator',
                component: IoTDataSimulator,
                meta: {
                    title: 'IoT Data Simulator',
                    description: 'Simulate and visualize IoT sensor data',
                },
            },
            {
                path: 'sites/:siteId/diagram-view',
                name: 'diagram-view',
                component: DiagramViewMainComponent,
                meta: {
                    title: 'Diagram View',
                    description: 'Diagram View page',
                },
            },
        ],
    },
    {
        path: '/home',
        name: 'h',
        component: MainLayoutComponent,
        redirect: {name: 'sites-list'},
    },
    {
        path: '/informationDetails',
        name: 'informationDetails',
        component: InformationDetailsComponent,
    },
    {
        path: '/informationDetailsArea',
        name: 'informationDetailsArea',
        component: InformationDetailsAreaComponent,
    },
    {
        path: '/informationDetailsSub',
        name: 'informationDetailsSub',
        component: InformationDetailsSubComponent,
    },
    {
        path: '/informationDetailsEquipment',
        name: 'informationDetailsEquipment',
        component: InformationDetailsEquipmentComponent,
    },
    {
        path: '/searchSite',
        name: 'searchSite',
        component: searchSiteComponent,
    },
    {
        path: '/buttonBar',
        name: 'buttonBar',
        component: ButtonBarComponent,
    },
    {
        path: '/header',
        name: 'header',
        component: HeaderComponent,
    },
];
