import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {CAP_DIRECTION, KeyValuePair, PIN_DIRECTION} from '@/globals';
import {
    CreateEditEquipmentCapViewModel,
    CreateEditEquipmentPinViewModel,
    EquipmentCapModel,
    EquipmentPinModel,
    UpdateCapSortOrderEquipmentCapModel,
    UpdatePinSortOrderEquipmentCapModel,
} from '@/sdk';
import {EquipmentCapsApi, EquipmentPinsApi} from '@/sdk/api-services';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class EquipmentCircuitDiagramComponent extends VueWrapper {
    public $refs!: {
        tableContainer: Vue;
    };
    @Prop({
        type: String,
        required: true,
    })
    public readonly locationId!: string;

    @Prop({
        type: Boolean,
        required: true,
        default: false,
    })
    public readonly readonly!: boolean;

    public pinsInToAddCount: number = 0;
    public pinsOutToAddCount: number = 0;

    public capsInToAddCount: number = 0;
    public capsOutToAddCount: number = 0;

    readonly name = 'add-edit-pin-dialog';

    public equipmentPinsApi: EquipmentPinsApi = new EquipmentPinsApi();
    public equipmentCapsApi: EquipmentCapsApi = new EquipmentCapsApi();

    public inPinsList: Array<EquipmentPinModel> = [];
    public outPinsList: Array<EquipmentPinModel> = [];

    public inCapsList: Array<EquipmentCapModel> = [];
    public outCapsList: Array<EquipmentCapModel> = [];

    public pinDirections: Array<KeyValuePair> = [
        {
            key: PIN_DIRECTION.IN,
            value: 'In',
        },
        {
            key: PIN_DIRECTION.OUT,
            value: 'Out',
        },
    ];

    // pinInLines: {[key: string]: LeaderLine} = {};
    // pinOutLines: {[key: string]: LeaderLine} = {};

    // pinConnectionLines: {[key: string]: LeaderLine} = {};

    public get hash() {
        const ids = [
            ...this.inPinsList.map(x => x.id),
            ...this.outPinsList.map(x => x.id),
            ...this.inCapsList.map(x => x.id),
            ...this.outCapsList.map(x => x.id),
        ];

        ids.sort(() => Math.random() - 0.5);

        return ids.join('');
    }

    public onScroll(event: Event) {
        // Object.values(this.pinInLines).forEach(line => {
        //     line.position();
        // });
        // Object.values(this.pinOutLines).forEach(line => {
        //     line.position();
        // });
    }

    public mounted(): void {
        const promises = [this.getEquipmentPins(), this.getEquipmentCaps()];

        console.log(this.$refs.tableContainer.$el);

        new MutationObserver(() => {
            console.log('scroll');
        }).observe(this.$refs.tableContainer.$el, {
            attributes: true,
            childList: true,
            subtree: true,
        });

        Promise.all(promises).then(() => {
            this.rerenderLinks();
        });
    }

    public beforeDestroy(): void {
        // this.removeAllLinks();
    }

    private rerenderLinks() {
        setTimeout(() => {
            // this.removeAllLinks();

            this.inPinsList.forEach(pin => {
                if (pin.equipmentCapId) {
                    const fromElem = document.getElementById(`pin-${pin.id}`);
                    const toElem = document.getElementById(`cap-${pin.equipmentCapId}`);

                    if (fromElem && toElem) {
                        this.createAndSaveLink(pin, fromElem, toElem);
                    }
                }

                if (pin.connectedPinId) {
                    const fromElem = document.getElementById(`pin-${pin.id}`);
                    const toElem = document.getElementById(`pin-${pin.connectedPinId}`);

                    if (fromElem && toElem) {
                        this.createAndSaveLink(pin, fromElem, toElem, true);
                    }
                }
            });

            this.outPinsList.forEach(pin => {
                if (pin.equipmentCapId) {
                    const fromElem = document.getElementById(`pin-${pin.id}`);
                    const toElem = document.getElementById(`cap-${pin.equipmentCapId}`);

                    if (fromElem && toElem) {
                        this.createAndSaveLink(pin, fromElem, toElem);
                    }
                }
            });
        }, 1000);
    }

    fromElement: HTMLElement | null = null;
    fromType: string = '';
    fromPin: EquipmentPinModel | null = null;

    public isStartExists(start: HTMLElement) {
        // return Object.values(this.pinInLines).some(line => line.start === start);
        return false;
    }

    public isPinConnectionStartExists(start: HTMLElement) {
        // return Object.values(this.pinConnectionLines).some(line => line.start === start);
        return false;
    }

    private updatePinConnectedId(pin: EquipmentPinModel, connectedPinId: string) {
        const p = this.inPinsList.find(x => x.id === pin.id);
        if (p) {
            p.connectedPinId = connectedPinId;
        }

        const p1 = this.outPinsList.find(x => x.id === pin.id);
        if (p1) {
            p1.connectedPinId = connectedPinId;
        }
    }

    public onPinInMouseDownn(event: MouseEvent, type: string, pin: EquipmentPinModel) {
        if (this.readonly) return;
        this.fromElement = event.currentTarget as HTMLElement;
        this.fromType = type;
        this.fromPin = pin;
    }

    public onPinOutMouseDown(event: MouseEvent, type: string, pin: EquipmentPinModel) {
        if (this.readonly) return;
        this.fromElement = event.currentTarget as HTMLElement;
        this.fromType = type;
        this.fromPin = pin;
    }

    public async onPinMouseUp(event: MouseEvent, pin: EquipmentPinModel, type: 'pin-out' | 'pin-in') {
        if (this.readonly) return;
        try {
            const toElement = event.currentTarget as HTMLElement;
            if (
                this.fromElement &&
                this.fromType !== type &&
                !this.isPinConnectionStartExists(this.fromElement) &&
                this.fromPin &&
                pin &&
                !pin.connectedPinId &&
                !this.fromPin.connectedPinId
            ) {
                const promises = [
                    this.equipmentPinsApi.updatePin(this.locationId, this.fromPin.id, {
                        ...this.fromPin,
                        connectedPinId: pin.id,
                    }),
                    this.equipmentPinsApi.updatePin(this.locationId, pin.id, {
                        ...pin,
                        connectedPinId: this.fromPin.id,
                    }),
                ];

                await Promise.all(promises);

                this.createAndSaveLink(this.fromPin, this.fromElement, toElement, true);
                this.updatePinConnectedId(this.fromPin, pin.id);
                this.updatePinConnectedId(pin, this.fromPin.id);

                this.fromElement = null;
                this.fromPin = null;
            }
        } catch (error) {
            console.log(error);
        }
    }

    // public onCoreInMouseDown(event: MouseEvent, type: string, pin: EquipmentPinModel) {
    //     if (this.readonly) return;
    //     this.fromElement = event.currentTarget as HTMLElement;
    //     this.fromType = type;
    //     this.fromPin = pin;
    // }

    public async onCapInMouseUp(event: MouseEvent, cap: EquipmentCapModel) {
        if (this.readonly) return;
        try {
            const toElement = event.currentTarget as HTMLElement;
            if (this.fromElement && this.fromType === 'pin-in' && !this.isStartExists(this.fromElement) && this.fromPin && cap) {
                await this.equipmentPinsApi.updatePin(this.locationId, this.fromPin.id, {
                    ...this.fromPin,
                    equipmentCapId: cap.id,
                });

                if (this.fromPin) {
                    this.fromPin.equipmentCapId = cap.id;
                }

                this.createAndSaveLink(this.fromPin, this.fromElement, toElement);
                this.fromElement = null;
                this.fromPin = null;
            }
        } catch (error) {
            console.log(error);
        }
    }

    // public onCoreOutMouseDown(event: MouseEvent, type: string, pin: EquipmentPinModel) {
    //     if (this.readonly) return;
    //     this.fromElement = event.currentTarget as HTMLElement;
    //     this.fromType = type;
    //     this.fromPin = pin;
    // }

    public async onCapOutMouseUp(event: MouseEvent, cap: EquipmentCapModel) {
        if (this.readonly) return;
        try {
            const toElement = event.currentTarget as HTMLElement;
            if (this.fromElement && this.fromType === 'pin-out' && !this.isStartExists(this.fromElement) && this.fromPin && cap) {
                await this.equipmentPinsApi.updatePin(this.locationId, this.fromPin.id, {
                    ...this.fromPin,
                    equipmentCapId: cap.id,
                });

                this.createAndSaveLink(this.fromPin, this.fromElement, toElement);
                this.fromElement = null;
                this.fromPin = null;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                        Drag Drop Functionality Start                       */
    /* -------------------------------------------------------------------------- */
    public dragingCap: EquipmentCapModel | null = null;
    public onCapDragStart(event: DragEvent, cap: EquipmentCapModel) {
        if (this.readonly) return;
        this.dragingCap = cap;
    }
    public onDropCap(where: 'before' | 'after', cap: EquipmentCapModel) {
        if (this.readonly || this.dragingCap?.id === cap.id) return;
        if (this.dragingCap && cap) {
            const sortData: UpdateCapSortOrderEquipmentCapModel = {
                dragedCapId: this.dragingCap.id,
                targetCapId: cap.id,
                where,
            };

            this.LoaderSrv.showFullScreenLoader('Updating cap order...');
            this.equipmentCapsApi
                .updateSortOrder(this.locationId, sortData)
                .then(() => {
                    this.getEquipmentCaps().finally(() => {
                        this.rerenderLinks();
                    });
                    // this.AlertSrv.show('success', 'Cap order updated successfully!');
                })
                .catch(e => {
                    this.AlertSrv.show('error', 'Unable to update cap order please try again later!');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public dragingPin: EquipmentPinModel | null = null;
    public onPinDragStart(event: DragEvent, pin: EquipmentPinModel) {
        if (this.readonly) return;
        this.dragingPin = pin;
    }
    public onDropPin(where: 'before' | 'after', pin: EquipmentPinModel) {
        if (this.readonly || this.dragingPin?.id === pin.id) return;
        if (this.dragingPin && pin) {
            const sortData: UpdatePinSortOrderEquipmentCapModel = {
                dragedPinId: this.dragingPin.id,
                targetPinId: pin.id,
                where,
            };

            this.LoaderSrv.showFullScreenLoader('Updating pin order...');
            this.equipmentPinsApi
                .updateSortOrder(this.locationId, sortData)
                .then(() => {
                    this.getEquipmentPins().finally(() => {
                        this.rerenderLinks();
                    });
                })
                .catch(e => {
                    this.AlertSrv.show('error', 'Unable to update pin order please try again later!');
                })
                .finally(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }
    /* -------------------------------------------------------------------------- */
    /*                        Drag Drop Functionality End                         */
    /* -------------------------------------------------------------------------- */

    private getRandomDarkViewableColor() {
        const getRandomValue = () => Math.floor(Math.random() * 129) + 64; // 64-192 for not too dark colors
        const red = getRandomValue().toString(16).padStart(2, '0');
        const green = getRandomValue().toString(16).padStart(2, '0');
        const blue = getRandomValue().toString(16).padStart(2, '0');
        return `#${red}${green}${blue}`;
    }

    private createAndSaveLink(pin: EquipmentPinModel, fromElement: HTMLElement, toElement: HTMLElement, isPinConnection: boolean = false) {
        // if (!pin || !fromElement || !toElement) return;
        // const line = new LeaderLine(fromElement, toElement, {
        //     referenceElement: this.$refs.tableContainer.$el,
        //     color: this.getRandomDarkViewableColor(),
        //     startPlug: 'disc',
        //     endPlug: 'disc',
        //     dropShadow: true,
        //     // startPlugColor: this.getRandomViewableColor(),
        // });
        // if (isPinConnection) {
        //     this.pinConnectionLines[pin.id] = line;
        // } else if (pin.pinDirection === PIN_DIRECTION.IN) {
        //     this.pinInLines[pin.id] = line;
        // } else if (pin.pinDirection === PIN_DIRECTION.OUT) {
        //     this.pinOutLines[pin.id] = line;
        // }
    }

    private removeAllLinks() {
        // Object.values(this.pinInLines).forEach(line => line?.remove());
        // Object.values(this.pinOutLines).forEach(line => line?.remove());
        // Object.values(this.pinConnectionLines).forEach(line => line?.remove());
    }

    private removeLink(pinId: string) {
        // this.pinInLines[pinId]?.remove();
        // this.pinOutLines[pinId]?.remove();
        // this.pinConnectionLines[pinId]?.remove();
    }

    public getEquipmentPins() {
        this.LoaderSrv.showFullScreenLoader('Loading pins details');
        return this.equipmentPinsApi
            .getLocationPins(this.locationId)
            .then(res => {
                this.inPinsList = res?.filter(x => x.pinDirection === PIN_DIRECTION.IN) ?? [];
                this.outPinsList = res?.filter(x => x.pinDirection === PIN_DIRECTION.OUT) ?? [];

                return res;
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public getEquipmentCaps() {
        this.LoaderSrv.showFullScreenLoader('Loading caps details');
        return this.equipmentCapsApi
            .getLocationCaps(this.locationId)
            .then(res => {
                this.inCapsList = res?.filter(x => x.capDirection === CAP_DIRECTION.IN) ?? [];
                this.outCapsList = res?.filter(x => x.capDirection === CAP_DIRECTION.OUT) ?? [];

                return res;
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public addPins(direction: PIN_DIRECTION) {
        if ([PIN_DIRECTION.IN, PIN_DIRECTION.OUT].includes(direction)) {
            const count = direction === PIN_DIRECTION.IN ? this.pinsInToAddCount : this.pinsOutToAddCount;
            const pins = Array.from({length: count}, () => {
                const random = Math.floor(Math.random() * 16777215).toString(16);
                return new CreateEditEquipmentPinViewModel({
                    title: `Pin ${random}`,
                    pinDirection: direction,
                    sortOrder: 0,
                });
            });

            const maxOrder =
                direction === PIN_DIRECTION.IN
                    ? Math.min(...this.inPinsList.map(x => x.sortOrder ?? 0))
                    : Math.max(...this.outPinsList.map(x => x.sortOrder ?? 0));

            this.LoaderSrv.showFullScreenLoader();
            this.equipmentPinsApi
                .createPinsMultiple(this.locationId, maxOrder, pins)
                .then(() => {
                    this.getEquipmentPins().finally(() => {
                        this.rerenderLinks();
                    });
                    this.AlertSrv.show('success', 'Pins added successfully!');
                })
                .catch(e => {
                    this.AlertSrv.show('error', 'Unable to add pins please try again later!');
                })
                .finally(() => {
                    this.pinsInToAddCount = this.pinsOutToAddCount = 0;
                    this.LoaderSrv.hideFullScreenLoader();
                });
        } else {
            this.AlertSrv.show('error', 'Invalid pin direction!');
        }
    }

    public addCaps(direction: CAP_DIRECTION) {
        if ([CAP_DIRECTION.IN, CAP_DIRECTION.OUT].includes(direction)) {
            const count = direction === CAP_DIRECTION.IN ? this.capsInToAddCount : this.capsOutToAddCount;
            const caps = Array.from({length: count}, () => {
                const random = Math.floor(Math.random() * 16777215).toString(16);
                return new CreateEditEquipmentCapViewModel({
                    title: `Cap ${random}`,
                    capDirection: direction,
                    sortOrder: 0,
                });
            });

            const maxOrder =
                direction === CAP_DIRECTION.IN
                    ? Math.min(...this.inCapsList.map(x => x.sortOrder ?? 0))
                    : Math.max(...this.outCapsList.map(x => x.sortOrder ?? 0));

            this.LoaderSrv.showFullScreenLoader();
            this.equipmentCapsApi
                .createCapsMultiple(this.locationId, maxOrder, caps)
                .then(() => {
                    this.getEquipmentCaps().finally(() => {
                        this.rerenderLinks();
                    });
                    this.AlertSrv.show('success', 'Caps added successfully!');
                })
                .catch(e => {
                    this.AlertSrv.show('error', 'Unable to add caps please try again later!');
                })
                .finally(() => {
                    this.capsInToAddCount = this.capsOutToAddCount = 0;
                    this.LoaderSrv.hideFullScreenLoader();
                });
        } else {
            this.AlertSrv.show('error', 'Invalid cap direction!');
        }
    }

    addSingleCap(cap: EquipmentCapModel, where: 'before' | 'after') {
        const newCap = new CreateEditEquipmentCapViewModel({
            title: cap.title,
            capDirection: cap.capDirection,
            sortOrder: 0,
        });

        const afterOrder = where === 'before' ? -1 : cap.sortOrder;

        this.LoaderSrv.showFullScreenLoader('Adding cap...');
        this.equipmentCapsApi
            .createCapsMultiple(this.locationId, afterOrder, [newCap])
            .then(() => {
                this.getEquipmentCaps().finally(() => {
                    this.rerenderLinks();
                });
                this.AlertSrv.show('success', 'Cap added successfully!');
            })
            .catch(e => {
                this.AlertSrv.show('error', 'Unable to add cap please try again later!');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    addSinglePin(pin: EquipmentPinModel, where: 'before' | 'after') {
        const newPin = new CreateEditEquipmentPinViewModel({
            title: pin.title,
            pinDirection: pin.pinDirection,
            sortOrder: 0,
        });

        const afterOrder = where === 'before' ? -1 : pin.sortOrder;

        this.LoaderSrv.showFullScreenLoader('Adding pin...');
        this.equipmentPinsApi
            .createPinsMultiple(this.locationId, afterOrder, [newPin])
            .then(() => {
                this.getEquipmentPins().finally(() => {
                    this.rerenderLinks();
                });
                this.AlertSrv.show('success', 'Pin added successfully!');
            })
            .catch(e => {
                this.AlertSrv.show('error', 'Unable to add pin please try again later!');
            })
            .finally(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    onPinRadioClick(pin: EquipmentPinModel) {
        if (pin.isActive) {
            this.ConfirmSrv.open('Deactivate Pin?', 'Are you sure you want to deactivate this pin?').then(y => {
                if (y) {
                    this.LoaderSrv.showFullScreenLoader('Deactivating pin...');
                    this.equipmentPinsApi
                        .markPinInactive(pin.id)
                        .then(() => {
                            this.getEquipmentPins().finally(() => {
                                this.rerenderLinks();
                            });
                            this.AlertSrv.show('success', 'Pin has been deactivated.');
                        })
                        .catch(e => {
                            this.AlertSrv.show('error', 'Unable to deactivate pin.');
                        })
                        .finally(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                }
            });
        } else {
            this.ConfirmSrv.open('Activate Pin?', 'Are you sure you want to activate this pin?').then(y => {
                if (y) {
                    this.LoaderSrv.showFullScreenLoader('Activating pin...');
                    this.equipmentPinsApi
                        .markPinActive(pin.id)
                        .then(() => {
                            this.getEquipmentPins().finally(() => {
                                this.rerenderLinks();
                            });
                            this.AlertSrv.show('success', 'Pin has been activated.');
                        })
                        .catch(e => {
                            this.AlertSrv.show('error', 'Unable to activate pin.');
                        })
                        .finally(() => {
                            this.LoaderSrv.hideFullScreenLoader();
                        });
                }
            });
        }
    }

    onAddPinClick() {
        this.CoreSrv.OpenModal(this.name);
    }

    // onEditPinClick(pin: EquipmentPinModel) {
    //     this.pin = new CreateEditEquipmentPinViewModel({
    //         title: pin.title,
    //         pinDirection: pin.pinDirection,
    //         id: pin.id,
    //     });

    //     this.CoreSrv.OpenModal(this.name);
    // }

    onDeletePinClick(pin: EquipmentPinModel) {
        if (this.readonly) return;

        this.ConfirmSrv.open('Delete Pin?', 'Are you sure you want to delete this pin?').then(y => {
            if (y) {
                // this.removeLink(pin.id);
                // pin.connectedPinId && this.removeLink(pin.connectedPinId);
                this.LoaderSrv.showFullScreenLoader('Deleting pin...');
                this.equipmentPinsApi
                    .deletePin(pin.id)
                    .then(() => {
                        this.getEquipmentPins().finally(() => {
                            this.rerenderLinks();
                        });
                        this.AlertSrv.show('success', 'Pin has been delete.');
                    })
                    .catch(e => {
                        this.AlertSrv.show('error', 'Unable to delete pin.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    onDeleteCapClick(cap: EquipmentCapModel) {
        if (this.readonly) return;

        this.ConfirmSrv.open('Delete Cap?', 'Are you sure you want to delete this cap?').then(y => {
            if (y) {
                // this.removeLink(cap.id);
                this.LoaderSrv.showFullScreenLoader('Deleting cap...');
                this.equipmentCapsApi
                    .deleteCap(cap.id)
                    .then(() => {
                        this.getEquipmentCaps().finally(() => {
                            this.rerenderLinks();
                        });
                        this.AlertSrv.show('success', 'Cap has been delete.');
                    })
                    .catch(e => {
                        this.AlertSrv.show('error', 'Unable to delete cap.');
                    })
                    .finally(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        });
    }

    close() {
        this.CoreSrv.CloseModal(this.name);
    }

    // reset() {
    //     this.pin = new CreateEditEquipmentPinViewModel({
    //         title: '',
    //         pinDirection: PIN_DIRECTION.IN,
    //     });
    // }

    // save() {
    //     this.LoaderSrv.showFullScreenLoader('Saving Pin...');
    //     if (this.pin.id) {
    //         this.equipmentPinsApi
    //             .updatePin(this.locationId, this.pin.id, this.pin)
    //             .then(res => {
    //                 this.AlertSrv.show('success', 'Pin updated successfully!');
    //                 this.getEquipmentPins();
    //                 this.close();
    //                 // this.reset();
    //             })
    //             .catch(e => {
    //                 this.AlertSrv.show('error', e?.message ?? 'Unable to save pin please try again later!');
    //             })
    //             .finally(() => {
    //                 this.LoaderSrv.hideFullScreenLoader();
    //             });
    //     } else {
    //         this.equipmentPinsApi
    //             .createPin(this.locationId, this.pin)
    //             .then(res => {
    //                 this.AlertSrv.show('success', 'Pin added successfully!');
    //                 this.getEquipmentPins();
    //                 this.close();
    //                 // this.reset();
    //             })
    //             .catch(e => {
    //                 this.AlertSrv.show('error', e?.message ?? 'Unable to save pin please try again later!');
    //             })
    //             .finally(() => {
    //                 this.LoaderSrv.hideFullScreenLoader();
    //             });
    //     }
    // }
}
