import {SensorType} from '@/types/types';

const paths = {
    abbSkinTemperature: `/abb-skin-temperature`,
    monetTemperature: `/monet-temperature`,
    motorSurfaceTemperature: `/motor-surface-temperature`,
    vibratorSampling: `/vibrator-sampling`,
};

export const targetEndpoint = (type: SensorType): string => {
    switch (type) {
        case SensorType.ABB_SKIN_TEMPERATURE:
            return paths.abbSkinTemperature;
        case SensorType.MONET_TEMPERATURE:
            return paths.monetTemperature;
        case SensorType.MOTOR_SURFACE_TEMPERATURE:
            return paths.motorSurfaceTemperature;
        case SensorType.VIBRATOR_SAMPLING:
            return paths.vibratorSampling;
        case SensorType.GENERATED:
            return '/generated-sensor-data';
        default:
            throw new Error(`Unknown SensorType: ${type}`);
    }
};
