var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "380px",
                  },
                  attrs: { elevation: 0 },
                },
                [
                  _c("iframe", {
                    attrs: {
                      src: `http://localhost:1234/d-solo/ae6mxykaq20aoc/foex-dashboard?orgId=1&from=1734181807365&to=1734203407365&timezone=browser&var-siteId=${_vm.siteId}&panelId=2&__feature.dashboardSceneSolo`,
                      frameborder: "0",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "380px",
                  },
                  attrs: { elevation: 0 },
                },
                [
                  _c("iframe", {
                    attrs: {
                      src: `http://localhost:1234/d-solo/ae6mxykaq20aoc/foex-dashboard?orgId=1&from=1704031200000&to=1732975200000&timezone=browser&var-siteId=${_vm.siteId}&theme=light&panelId=4&__feature.dashboardSceneSolo`,
                      frameborder: "0",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "380px",
                  },
                  attrs: { elevation: 0 },
                },
                [
                  _c("iframe", {
                    attrs: {
                      src: `http://localhost:1234/d-solo/ae6mxykaq20aoc/foex-dashboard?orgId=1&from=1735546099694&to=1735567699695&timezone=browser&var-siteId=${_vm.siteId}&theme=light&panelId=6&__feature.dashboardSceneSolo`,
                      frameborder: "0",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "380px",
                  },
                  attrs: { elevation: 0 },
                },
                [
                  _c("iframe", {
                    attrs: {
                      src: `http://localhost:1234/d-solo/ae6mxykaq20aoc/foex-dashboard?orgId=1&from=1735548265662&to=1735569865662&timezone=browser&var-siteId=${_vm.siteId}&theme=light&panelId=7&__feature.dashboardSceneSolo`,
                      frameborder: "0",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }