var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "sensor-graph" }, [
    _c("div", { staticClass: "chart-controls mb-4" }, [
      _c("label", { staticClass: "mr-2" }, [_vm._v("Chart Type:")]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedChartType,
              expression: "selectedChartType",
            },
          ],
          staticClass: "chart-select",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedChartType = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { attrs: { value: "line" } }, [_vm._v("Line Chart")]),
          _c("option", { attrs: { value: "area" } }, [_vm._v("Area Chart")]),
          _c("option", { attrs: { value: "bar" } }, [_vm._v("Bar Chart")]),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "chart-container", staticStyle: { height: "400px" } },
      [
        _c("line-chart", {
          style: { height: "100%", width: "100%" },
          attrs: { data: _vm.chartData, options: _vm.computedChartOptions },
        }),
      ],
      1
    ),
    _vm.showStatistics
      ? _c("div", { staticClass: "statistics-panel mt-4" }, [
          _c(
            "div",
            {
              staticClass: "stats-header",
              on: { click: _vm.toggleStatistics },
            },
            [
              _c("h3", [_vm._v("Data Statistics")]),
              _c("span", { staticClass: "toggle-icon" }, [
                _vm._v(_vm._s(_vm.isStatsExpanded ? "▼" : "▶")),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isStatsExpanded,
                  expression: "isStatsExpanded",
                },
              ],
              staticClass: "stats-content",
            },
            [
              _c("div", { staticClass: "stat-item" }, [
                _c("span", [_vm._v("Total Points:")]),
                _c("span", [_vm._v(_vm._s(_vm.statistics.totalCount))]),
              ]),
              _c("div", { staticClass: "stat-item" }, [
                _c("span", [_vm._v("Average:")]),
                _c("span", [_vm._v(_vm._s(_vm.statistics.average.toFixed(2)))]),
              ]),
              _c("div", { staticClass: "stat-item" }, [
                _c("span", [_vm._v("Max:")]),
                _c("span", [_vm._v(_vm._s(_vm.statistics.max.toFixed(2)))]),
              ]),
              _c("div", { staticClass: "stat-item" }, [
                _c("span", [_vm._v("Min:")]),
                _c("span", [_vm._v(_vm._s(_vm.statistics.min.toFixed(2)))]),
              ]),
              _c("div", { staticClass: "stat-item" }, [
                _c("span", [_vm._v("Standard Deviation:")]),
                _c("span", [_vm._v(_vm._s(_vm.statistics.stdDev.toFixed(2)))]),
              ]),
              _c("div", { staticClass: "stat-item" }, [
                _c("span", [_vm._v("RMS:")]),
                _c("span", [_vm._v(_vm._s(_vm.statistics.rms.toFixed(2)))]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "control-panel mt-4" }, [
      _c("div", { staticClass: "control-inputs" }, [
        _c("div", { staticClass: "input-group" }, [
          _c("label", [_vm._v("Scale:")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.scale,
                expression: "scale",
                modifiers: { number: true },
              },
            ],
            attrs: { type: "number", step: "0.1" },
            domProps: { value: _vm.scale },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.scale = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _c("div", { staticClass: "input-group" }, [
          _c("label", [_vm._v("Offset:")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.offset,
                expression: "offset",
                modifiers: { number: true },
              },
            ],
            attrs: { type: "number" },
            domProps: { value: _vm.offset },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.offset = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _c("div", { staticClass: "input-group" }, [
          _c("label", [_vm._v("Sampling Frequency (Hz):")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.samplingFrequency,
                expression: "samplingFrequency",
                modifiers: { number: true },
              },
            ],
            attrs: { type: "number", min: "1" },
            domProps: { value: _vm.samplingFrequency },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.samplingFrequency = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "control-buttons" }, [
        _c(
          "button",
          {
            class: ["simulation-button", _vm.isRunning ? "stop" : "start"],
            on: { click: _vm.toggleSimulation },
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.isRunning ? "Stop Simulation" : "Start Simulation") +
                " "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }