import Papa from 'papaparse';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {CSVData, MonetTemperatureCSVData, SensorType, TemperatureCSVData, VibratorSamplingCSVData} from '../../../types/types';
import {detectDelimiter, detectSensorType, parseMonetTemperatureData, parseTemperatureData, parseVibratorSamplingData} from '../../../utils/utils';

@Component
export default class CSVParser extends Vue {
    @Prop({required: true}) setParsedData!: (data: CSVData | null) => void;
    @Prop({required: true}) setFileName!: (name: string) => void;
    @Prop({required: true}) setSampleInterval!: (interval: number | null) => void;

    isLoading = false;
    error: string | null = null;
    detectedSensor: string | null = null;

    mounted() {
        console.log('CSVParser component mounted');
    }

    triggerFileUpload() {
        console.log('triggerFileUpload called');
        (this.$refs.fileInput as HTMLInputElement).click();
    }

    extractSampleIntervalFromText(csvText: string): number | null {
        console.log('extractSampleIntervalFromText called');
        const lines = csvText.split('\n');
        for (const line of lines) {
            if (line.startsWith('Sample Interval')) {
                const parts = line.split(',');
                if (parts.length > 1) {
                    const sampleInterval = parseFloat(parts[1]);
                    return !isNaN(sampleInterval) ? sampleInterval : null;
                }
            }
        }
        return null;
    }

    handleFileUpload(event: Event) {
        console.log('handleFileUpload called');
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            this.setFileName(file.name);
            this.isLoading = true;
            this.error = null;
            const reader = new FileReader();
            reader.onload = e => {
                const text = (e.target as FileReader).result as string;
                const delimiter = detectDelimiter(text);

                const sampleInterval = this.extractSampleIntervalFromText(text);
                this.setSampleInterval(sampleInterval);

                Papa.parse(text, {
                    delimiter: delimiter,
                    complete: result => {
                        console.log('Papa.parse complete');
                        const parsedCSV = result.data as string[][];
                        let csvData: VibratorSamplingCSVData | TemperatureCSVData | MonetTemperatureCSVData | null = null;

                        try {
                            const sensorType = detectSensorType(parsedCSV);
                            this.detectedSensor = sensorType;
                            switch (sensorType) {
                                case SensorType.VIBRATOR_SAMPLING:
                                    csvData = parseVibratorSamplingData(parsedCSV);
                                    break;
                                case SensorType.ABB_SKIN_TEMPERATURE:
                                case SensorType.MOTOR_SURFACE_TEMPERATURE:
                                    csvData = parseTemperatureData(parsedCSV, sensorType);
                                    break;
                                case SensorType.MONET_TEMPERATURE:
                                    csvData = parseMonetTemperatureData(parsedCSV);
                                    break;
                                default:
                                    throw new Error('Unsupported sensor type');
                            }
                            this.setParsedData(csvData);
                        } catch (error: any) {
                            console.error('Error parsing CSV:', error);
                            this.error = 'Error parsing CSV. Please check the file format.';
                        } finally {
                            this.isLoading = false;
                        }
                    },
                    error: (error: any) => {
                        console.error('Error parsing CSV:', error);
                        this.error = 'Error parsing CSV. Please check the file format.';
                        this.isLoading = false;
                    },
                });
            };
            reader.readAsText(file);
        }
    }
}
