import VueWrapper from '@/components/core/Vue/vue.wrapper';

import {Color4, Engine, HemisphericLight, PickingInfo, Scene, SceneLoader, Vector3} from '@babylonjs/core';
import * as GUI from '@babylonjs/gui';
import {Inspector} from '@babylonjs/inspector';
import '@babylonjs/loaders';
import {Component} from 'vue-property-decorator';
@Component({
    name: 'DigitalTwinCanvasComponent',
    components: {},
})
export default class DigitalTwinCanvasComponent extends VueWrapper {
    private engine!: Engine;
    private scene!: Scene;
    public $refs!: {
        bjsCanvas: HTMLCanvasElement;
    };

    public mounted() {
        this.initializeBabylonJS();
    }

    beforeDestroy() {
        if (this.engine) {
            this.engine.dispose();
        }
    }

    private async initializeBabylonJS() {
        const canvas = this.$refs.bjsCanvas as HTMLCanvasElement;
        if (!canvas) {
            console.error('Canvas element not found');
            return;
        }
        this.engine = new Engine(canvas, true);
        this.scene = new Scene(this.engine);
        this.scene.clearColor = new Color4(0.0, 0.0, 0.0, 0.76);

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), this.scene);
        light.intensity = 0.7;

        await SceneLoader.ImportMesh(
            '',
            '/images/',
            'C03126G0-9 combined.glb',
            this.scene,
            newMeshes => {
                this.scene.onPointerDown = (evt, pickResult) => {
                    if (pickResult.hit) {
                        this.createGUIButton(pickResult.pickedMesh);
                    }
                };
            },
            undefined,
            undefined,
            '.glb'
        );

        this.scene.createDefaultCameraOrLight(true, true, true);
        Inspector.Show(this.scene, {});

        this.engine.runRenderLoop(() => {
            this.scene.render();
        });

        window.addEventListener('resize', () => {
            this.engine.resize();
        });
    }

    private createGUIButton(picked: PickingInfo['pickedMesh']) {
        const guiCanvas = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI', true, this.scene);
        picked!.overlayColor.set(0.1, 0.1, 0.9);
        picked!.renderOverlay = true;

        const guiButton = GUI.Button.CreateSimpleButton('guiButton', picked!.name);
        guiButton.width = '150px';
        guiButton.height = '40px';
        guiButton.color = 'white';
        guiButton.cornerRadius = 5;
        guiButton.background = 'green';
        guiButton.onPointerUpObservable.add(() => {
            guiCanvas.dispose();
            picked!.renderOverlay = false;
        });
        guiButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
        guiCanvas.addControl(guiButton);
    }
}
