var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "white height-100x d-flex" },
    [
      _c(
        "div",
        {
          ref: "searchSectionElem",
          staticClass:
            "left-tree-section overflow-hidden flex-shrink-0 relative",
        },
        [
          _c(
            "div",
            {
              ref: "areaBrowserElem",
              staticClass: "overflow-hidden relative mb-1",
              staticStyle: { height: "100%", "min-height": "150px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "overflow-hidden height-100x left-tree-section-child",
                },
                [
                  _c("SiteSetupAreaBrowserComponent", {
                    attrs: {
                      site: _vm.siteToEdit,
                      siteLocations: _vm.siteLocationsSrv.siteLocations,
                    },
                    on: {
                      locationItemClick: _vm.locationItemClick,
                      siteClick: _vm.onSiteClick,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("div", {
            staticClass:
              "resize-control-vertical height-100x site-browser-resizer",
            on: { mousedown: _vm.onListMouseDown },
          }),
        ]
      ),
      _c(
        "div",
        {
          ref: "searchSectionParentElem",
          staticStyle: { "background-color": "#e9e9e9" },
        },
        [[_c("DigitalTwinCanvasComponent")]],
        2
      ),
      _c("SaveLocationDialogComponent"),
      _c("SaveDocumentationFolderDialogComponent"),
      _c("SaveDocumentationFileDialogComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }