var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mb-12" },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.showSensorManagement
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("v-card-title", { staticClass: "pt-0 pb-2" }, [
                    _c("h4", { staticClass: "text-h6 mt-2" }, [
                      _vm._v("Sensor Management"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "ml-auto" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: { color: "admin-primary " },
                            on: { click: _vm.openCreateSensorDialog },
                          },
                          [
                            _vm._v(" Add New Sensor "),
                            _c("v-icon", { staticClass: "ml-1" }, [
                              _vm._v("mdi-plus-circle-outline"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("v-card-text", [
                    _c("div", [
                      _c("table", { staticClass: "foex-table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("No")]),
                            _c("th", [_vm._v("Sensor ID")]),
                            _c("th", [_vm._v("Sensor Type")]),
                            _c("th", [_vm._v("Sensor IP")]),
                            _c("th", [_vm._v("Status")]),
                            _c("th", [_vm._v("Last Updated")]),
                            _c("th", [_vm._v("Actions")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.sensors, function (sensor, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c("td", [_vm._v(_vm._s(sensor.sensorId))]),
                              _c("td", [_vm._v(_vm._s(sensor.sensorType))]),
                              _c("td", [_vm._v(_vm._s(sensor.ip))]),
                              _c("td", [_vm._v(_vm._s(sensor.active))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      sensor.updated ?? sensor.created,
                                      "yyyy-MM-dd hh:mm:ss"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openViewSensorChart(sensor)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-chart-line")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditSensorDialog(
                                            sensor
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteSensor(sensor)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _c(
                "v-card",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "500px",
                  },
                  attrs: { elevation: 0 },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-0 pb-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.goBackToSensorManagement },
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                        1
                      ),
                      _c("h4", { staticClass: "text-h6 mt-2" }, [
                        _vm._v("Sensor Chart"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticStyle: { height: "100%" } }, [
                    _c("iframe", {
                      attrs: {
                        src: "http://localhost:3001/d-solo/fea7gx9qq4jy8f/monettemperaturedashboard?orgId=1&timezone=browser&panelId=1&__feature.dashboardSceneSolo",
                        width: "100%",
                        height: "100%",
                        frameborder: "0",
                      },
                    }),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _vm.showCreateSensorDialog
        ? _c("create-sensor-dialog-component", {
            attrs: {
              siteLocationId: _vm.siteLocationId,
              fetchSensors: _vm.fetchSensors,
              show: _vm.showCreateSensorDialog,
            },
            on: {
              "update:show": function ($event) {
                _vm.showCreateSensorDialog = $event
              },
            },
          })
        : _vm._e(),
      _vm.showEditSensorDialog
        ? _c("edit-sensor-dialog-component", {
            attrs: {
              show: _vm.showEditSensorDialog,
              siteLocationId: _vm.siteLocationId,
              selectedSensor: _vm.selectedSensor,
              fetchSensors: _vm.fetchSensors,
            },
            on: {
              "update:show": function ($event) {
                _vm.showEditSensorDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }