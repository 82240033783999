import {SensorType} from '@/types/types';
import axiosInstance from '@/utils/api';
import {targetEndpoint} from '@/utils/paths';
import {SiteLocationService} from '@/views/shared/services';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {MonetTemperatureCSVData, TemperatureCSVData, VibratorSamplingCSVData} from '../../../types/types';
import CSVParser from './CSVParser.component.vue';
import DataTable from './DataTable.component.vue';
import IoTDataClient from './IoTDataClient.component';
import SensorGraph from './SensorGraph.component.vue';
import SinusoidalFunction from './SinusoidalFunction.component.vue';
import SensorManagementComponent from './sensor-management.component';

interface SensorData {
    sensorName: string;
    value: number;
    time: number;
    sensorType: SensorType;
}

interface Parameters {
    A: number;
    F: number;
    P: number;
    B: number;
    samplingRate: number;
    duration: number;
    noiseAmplitude: number;
    xOffset: number;
    decayRate: number;
}

@Component({
    components: {
        CSVParser,
        DataTable,
        SensorGraph,
        SinusoidalFunction,
        IoTDataClient,
        SensorManagementComponent,
    },
})
export default class IoTDataSimulator extends Vue {
    mqttEndpoint = 'wss://';
    mqttTopics = ['sensor/data'];
    sensorData: SensorData[] = [];
    dataSource: string | null = null;
    dataSources = ['CSV', 'Sinusoidal'];
    parameters: Parameters = {
        A: 1,
        F: 1,
        P: 0,
        B: 0,
        samplingRate: 0.01,
        duration: 10,
        noiseAmplitude: 0,
        xOffset: 0,
        decayRate: 0,
    };

    successMessage: string | null = null;
    errorMessage: string | null = null;

    @Prop({
        type: Boolean,
        default: false,
    })
    public readOnlyFlag!: boolean;

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    async sendDataToEndpoint() {
        try {
            if (!this.sensorData.length) {
                this.$set(this, 'errorMessage', 'No data to send.');
                return;
            }

            const sensorType = this.sensorData[0]?.sensorType;
            if (!sensorType) {
                this.$set(this, 'errorMessage', 'SensorType is missing.');
                return;
            }

            const endpointPath = targetEndpoint(sensorType);

            //const fullEndpoint = `${endpointPath}`;

            // const payload = {
            //     data: this.sensorData.map(item => ({
            //         value: item.value,
            //         time: item.time,
            //         sensorName: item.sensorName,
            //     })),
            // };

            // console.log('Sending data to:', fullEndpoint);
            // console.log('Payload:', payload);

            // const response = await axiosInstance.post(fullEndpoint, payload);
            // console.log('Data sent successfully:', response.data);
            const payload = {
                data: this.sensorData.map(item => ({
                    sensorId: '042b3be0362858cb01acf723', // Hard coded for now, seems that the CSV Parsing is not working
                    sensorName: 'VibratorSampling', // Hard coded for now, seems that the CSV Parsing is not working
                    time: '2024-11-03 18:52:37', // Hard coded for now, seems that the CSV Parsing is not working
                    voltage: '10', // Hard coded for now, seems that the CSV Parsing is not working
                })),
            };
            const oneData = payload.data[0];
            console.log('Sending data to:', endpointPath);
            console.log('Payload:', payload);
            console.log('OneData: ', oneData);
            await axiosInstance.post(`${endpointPath}`, oneData);
            // const response = await axiosInstance.post(fullEndpoint, oneData);
            // const response = await axiosInstance.post(fullEndpoint, payload);
            //console.log('Data sent successfully:', response.data);

            this.$set(this, 'successMessage', 'Data sent successfully!');
        } catch (error) {
            console.error('Error sending data:', error);
            this.$set(this, 'errorMessage', error instanceof Error ? error.message : 'An unknown error occurred.');
        }
    }

    handleData(newData: SensorData) {
        console.log('handleData called', newData);
        this.sensorData.push(newData);
    }

    handleParsedData(parsedData: any[]) {
        console.log('handleParsedData called', parsedData);
        this.sensorData = parsedData.map(item => ({
            sensorName: item.sensorName,
            value: parseFloat(item.value),
            time: new Date(item.time).getTime(),
            sensorType: item.sensorType,
        }));
    }

    setParsedData(data: TemperatureCSVData | VibratorSamplingCSVData | MonetTemperatureCSVData) {
        console.log('setParsedData called', data);

        if (data.type === SensorType.ABB_SKIN_TEMPERATURE || data.type === SensorType.MOTOR_SURFACE_TEMPERATURE) {
            this.sensorData = data.data.map((item: TemperatureCSVData['data'][number]) => ({
                sensorName: data.sensorName,
                value: item.MEASUREMENT_VALUE,
                time: new Date(item.MEASUREMENT_TAKEN_ON).getTime(),
                sensorType: data.type,
            }));
        } else if (data.type === SensorType.VIBRATOR_SAMPLING) {
            this.sensorData = data.data.map((item: VibratorSamplingCSVData['data'][number]) => ({
                sensorName: data.sensorName,
                value: item.CH1 || 0,
                time: new Date(item.TIME).getTime(),
                sensorType: data.type,
            }));
        } else if (data.type === SensorType.MONET_TEMPERATURE) {
            this.sensorData = data.data.map((item: MonetTemperatureCSVData['data'][number]) => ({
                sensorName: data.sensorName,
                value: item.Value,
                time: new Date(item.Date).getTime(),
                sensorType: data.type,
            }));
        }
    }

    setFileName(name: string) {
        console.log('setFileName called', name);
    }

    setSampleInterval(interval: number) {
        console.log('setSampleInterval called', interval);
    }

    generateSinusoidalData() {
        const data: SensorData[] = [];
        const now = Date.now();
        const {A, F, P, B, samplingRate, duration, noiseAmplitude, xOffset, decayRate} = this.parameters;
        const points = Math.ceil(duration / samplingRate);

        for (let i = 0; i < points; i++) {
            const x = i * samplingRate + xOffset;
            const decay = Math.exp(-decayRate * x); // exponential decay
            const noise = (Math.random() - 0.5) * noiseAmplitude; // random noise
            const y = A * decay * Math.sin(2 * Math.PI * F * x + P) + B + noise;
            data.push({
                sensorName: 'Sinusoidal Sensor',
                value: y,
                time: now + i * 1000,
                sensorType: SensorType.GENERATED,
            });
        }
        console.log('generateSinusoidalData called', data);
        this.sensorData = data;
    }

    updateParameters(newParameters: Parameters) {
        console.log('updateParameters called', newParameters);
        this.parameters = newParameters;
    }
}
