import {CAP_DIRECTION} from '@/globals';

export interface EquipmentCapModel {
    id: string;
    title: string;
    locationId: string;
    capDirection: CAP_DIRECTION;
    sortOrder: number;
    created: string;
}

export class CreateEditEquipmentCapViewModel implements Omit<EquipmentCapModel, 'id' | 'locationId' | 'created'> {
    id?: string;
    title: string;
    capDirection: CAP_DIRECTION;
    sortOrder: number;

    constructor(data: CreateEditEquipmentCapViewModel) {
        Object.assign(this, data);
    }
}

export interface UpdateCapSortOrderEquipmentCapModel {
    dragedCapId: string;
    targetCapId: string;
    where: 'before' | 'after';
}
