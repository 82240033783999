import VueWrapper from '@/components/core/Vue/vue.wrapper';
import {AlertService, FieldOptions, LoaderService, LocationModel, SiteModel} from '@/sdk';
import {CreatePointApi, SitesApi} from '@/sdk/api-services';
import {LocationApi} from '@/sdk/api-services/location.api';
import {AdminDataFieldService} from '@/views/shared/services';
import {SiteLocationService} from '@/views/shared/services/site-location.service';
import {Component, Prop, Watch} from 'vue-property-decorator';

// import DiagramView from './diagram-view-canvas.component.vue';

import {KeyValuePair, makeid} from '@/globals';
import {CoresGroup} from '@/sdk/models/location/cores-group.model';
import * as go from 'gojs';

@Component({
    name: 'DiagramViewCanvasComponent',
})
export default class DiagramViewCanvasComponent extends VueWrapper {
    $refs!: {
        drawingDivCanvas: HTMLDivElement;
        treeDrawingCanvasDiv: HTMLDivElement;
        drawingDivCanvasCoresDiv: HTMLDivElement;
    };

    search: string | null = null;

    public searchTab: number | null = 0;

    @Prop({
        type: String,
        required: true,
    })
    public siteId!: string;

    @Prop({
        type: SiteModel,
        required: true,
    })
    public siteToEdit!: SiteModel;

    @Prop({
        default: false,
        required: false,
    })
    public diagramViewStyle!: boolean;

    @Prop({
        default: false,
        required: false,
    })
    public diagramFullPageConfig!: boolean;

    @Prop({
        type: String,
        required: false,
    })
    public selectedLocationId!: string;

    @Prop({
        type: String,
        required: false,
    })
    public deletedLocationId!: string;

    @Watch('selectedLocationId')
    onSelectedLocationChange(newVal: string, oldVal: string) {
        // Call onSelectedLocationChange whenever 'selectedLocationId' changes
        console.log('newVal', newVal);
        console.log('oldVal', oldVal);
        this.onSelectedLocationFocus(newVal);
    }

    @Watch('deletedLocationId')
    onDeletedLocationIdChange(newVal: string, oldVal: string) {
        // Call onSelectedLocationChange whenever 'selectedLocationId' changes
        console.log('newVal deletedLocationId', newVal);
        console.log('oldVal deletedLocationId', oldVal);
        this.deleteLocationNodeFromId(newVal);
    }

    public onSelectedLocationFocus: any = {};
    public deleteLocationNodeFromId: any = {};

    public powerBoardType: Array<KeyValuePair> = [
        {
            key: '3P3W',
            value: '3P3W',
        },
        {
            key: '3P4W',
            value: '3P4W',
        },
        {
            key: '1P2W',
            value: '1P2W',
        },
    ];

    public siteLocationsSrv: SiteLocationService = new SiteLocationService();

    public adminDataFieldsSrv: AdminDataFieldService = new AdminDataFieldService();

    public updateSite: SiteModel = new SiteModel();

    public sitesApi: SitesApi = new SitesApi();

    public createPointApi: CreatePointApi = new CreatePointApi();

    public siteLocations: Array<any> = [];

    public selectedNodeData: any = false;
    public selectedCoreData: any = null;

    public hideCoreView: any = {};
    public hideTreeView: any = {};
    public showTreeView: any = {};
    public showCanvasView: any = {};
    public changeSelectedDP: () => void = () => {};

    public tabEDP: any = null;
    public tabDPE: any = null;

    public coreDetailsView: boolean | false = false;
    public coreDetailsViewEDP: boolean | false = false;
    public coreDetailsViewDPE: boolean | false = false;
    public coreGroupView: boolean | false = false;
    public coreView: boolean | false = false;

    // @Prop({
    //     default: false,
    //     required: false,
    // })
    public treeViewEnabled: boolean | false = false;
    public diagramFullPageCoreView: boolean | false = false;
    public clickedFocusOnCanvas: boolean | false = false;

    public coreGroupClicked: any = null;

    public coreDetailsArrayList: any = null;
    public switchBoardsFromArrayList: any = null;
    public switchBoardsToArrayList: any = null;
    public coreDetailsGroupArrayList: any = null;
    public coreGroupViewObj: any = null;

    public coreFromLocationData: any = null;
    public coreToLocationData: any = null;

    public coreLocationDataArray: any = [];

    public selectedDP: any = null;
    public selectedDPObj: any = null;
    public nodesDPArray: Array<any> = [];
    public locationPathFrom: Array<any> = [];
    public locationPathTo: Array<any> = [];

    public myCoreDiagramInstance: any = null;
    public showSelectedCoreInstance: any = null;

    @Prop({
        type: Boolean,
        required: true,
    })
    public showSearchResults!: boolean;

    public tab = 0;

    public prev() {
        if (this.tab === 0) return;
        this.tab = this.tab - 1;
    }
    public next() {
        this.tab = this.tab + 1;
    }
    public setActive(id: any) {
        if (id) {
            this.tab = this.coreDetailsArrayList.findIndex((d: any) => d.id === id);
        } else {
            this.tab = 1;
        }
    }
    public setActiveGroup(id: any) {
        if (id) {
            this.tab = this.coreDetailsGroupArrayList.findIndex((d: any) => d.id === id);
        } else {
            this.tab = 1;
        }
    }
    // public formsSiteResults!: boolean;
    public allEquipmentDistributionLocations: Array<any> = [];
    public get getEquipmentsList(): Array<any> {
        const siteLocations = this.siteLocationsSrv.siteLocations;
        if (siteLocations) {
            //  // console.log('this.siteLocationsSrv.selectedLcoation', siteLocations);
            this.allEquipmentDistributionLocations = Object.assign(
                [],
                siteLocations.filter(site => site.type === 'equipment' || site.type === 'distribution')
            );
            //  // console.log('allEquipmentDistributionLocations', this.allEquipmentDistributionLocations);

            return this.allEquipmentDistributionLocations;
        }
        return [];
    }

    public selectConnectDirection: Array<KeyValuePair> = [
        {
            key: 'IN',
            value: 'IN',
        },
        {
            key: 'OUT',
            value: 'OUT',
        },
        {
            key: 'N/A',
            value: 'N/A',
        },
    ];

    public isDoubleClick: boolean = false;

    async mounted() {
        this.$refs.drawingDivCanvas.style.display = 'block';
        this.$refs.treeDrawingCanvasDiv.style.display = 'none';
        this.$refs.drawingDivCanvasCoresDiv.style.display = 'none';

        const me = this;
        // console.log('STARTED');
        await this.adminDataFieldsSrv.loadAreaFieldOptions();
        await this.adminDataFieldsSrv.loadEquipmentFieldOptions();

        // console.log('STARTED 2', this.adminDataFieldsSrv);
        const $ = go.GraphObject.make;

        const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);
        // Cylinder Shape Generator
        go.Shape.defineFigureGenerator('Cylinder4', function (shape, w, h) {
            let param1 = shape ? shape.parameter1 : NaN; // half the width of the ellipse
            if (isNaN(param1)) param1 = 5; // default value
            param1 = Math.min(param1, w / 3);

            const geo = new go.Geometry();
            const cpyOffset = KAPPA * 0.5;
            const fig = new go.PathFigure(w - param1, 0, true);
            geo.add(fig);
            // The body, starting and ending top right
            fig.add(new go.PathSegment(go.PathSegment.Bezier, w, 0.5 * h, w - KAPPA * param1, 0, w, (0.5 - cpyOffset) * h));
            fig.add(new go.PathSegment(go.PathSegment.Bezier, w - param1, h, w, (0.5 + cpyOffset) * h, w - KAPPA * param1, h));
            fig.add(new go.PathSegment(go.PathSegment.Line, param1, h));
            fig.add(new go.PathSegment(go.PathSegment.Bezier, 0, 0.5 * h, KAPPA * param1, h, 0, (0.5 + cpyOffset) * h));
            fig.add(new go.PathSegment(go.PathSegment.Bezier, param1, 0, 0, (0.5 - cpyOffset) * h, KAPPA * param1, 0));
            fig.add(new go.PathSegment(go.PathSegment.Line, w - param1, 0));

            const fig2 = new go.PathFigure(w - param1, 0, false);
            geo.add(fig2);
            // Cylinder line (right)
            fig2.add(new go.PathSegment(go.PathSegment.Bezier, w - 2 * param1, 0.5 * h, w - param1 - KAPPA * param1, 0, w - 2 * param1, (0.5 - cpyOffset) * h));
            fig2.add(new go.PathSegment(go.PathSegment.Bezier, w - param1, h, w - 2 * param1, (0.5 + cpyOffset) * h, w - param1 - KAPPA * param1, h));

            geo.spot1 = new go.Spot(0, 0);
            geo.spot2 = new go.Spot(1, 1, -2 * param1, 0);
            return geo;
        });
        const nodeMenu = $(
            'ContextMenu',
            makeButton('Remove', (e: any) => e.diagram.commandHandler.deleteSelection(), null, 'remove'),
            makeButton(
                'Relocate',
                (e: any, obj: any) => {
                    e.diagram.model.commit((m: any) => {
                        // alternate between lightblue and lightgreen colors
                        m.set(m.modelData, 'movable', true);
                    }, 'changed shared color');
                    // const node = obj.part.adornedPart;
                    // // we are modifying the model, so conduct a transaction
                    //  // console.log(node);
                    // const diagram = node.diagram;
                    // diagram.startTransaction('add node and link');
                    // diagram.model.movable = true;
                    // diagram.commitTransaction('add node and link');
                    // diagram.model.commit((m: any) => {
                    //     const data = m.get; //m.nodeDataArray[node.diagram.key]; // get the first node data
                    //     m.set(data, 'move', true);
                    // }, 'move');
                    //  // console.log(obj);
                    //  // console.log(e);
                    // const node = obj.part;
                    // const data = node.data;
                    // e.diagram.model.commit((m: any) => {
                    //     // m.modelData
                    //      // console.log(m.modelData);
                    //     m.set(m.modelData, 'move', true);
                    // });
                    // // return (data.move = true);
                },
                null,
                'relocate'
            ),
            makeButton(
                'Wiring Entry',
                (e: any, obj: any) => {
                    onNodeDoubleClick(obj?.part);
                },
                null,
                'WiringEntry'
            ),
            makeButton('Copy', (e: any) => e.diagram.commandHandler.copySelection(), null, ''),
            // makeButton('Copy Code', (e: any) =>  // console.log('myDiagram.model.toJson => ', myCoreDiagram.model.toJson()), null, ''),
            $(go.Shape, 'LineH', {strokeWidth: 2, height: 1, stretch: go.GraphObject.Horizontal})
        );

        function makeIndicatorRight(propName: string | undefined) {
            return $(
                go.Shape,
                'Circle',
                {position: new go.Point(10, 0), width: 8, height: 8, fill: 'white', strokeWidth: 0, margin: 5},
                new go.Binding('fill', propName)
            );
        }

        const makeIndicatorLeft = (propName: string | undefined) => {
            return $(
                go.Shape,
                'Circle',
                {position: new go.Point(0, 0), width: 8, height: 8, fill: 'white', strokeWidth: 0, margin: 5},
                new go.Binding('fill', propName)
            );
        };

        const saveRenameNodeLocation = (textblock: any, oldstr: string, newstr: string) => {
            const data = textblock.part.data;

            // console.log('datatata', data);
            if (data.key && data.text) {
                const updateLocationObj = new LocationModel({
                    ...this.siteLocationsSrv.location,
                    title: newstr,
                });
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .UpdateLocation(data.key, updateLocationObj)
                    .subscribe(
                        res => {
                            if (res.Status) {
                                this.AlertSrv.show('success', 'Location has been updated successfully');
                                this.$emit('success');
                                this.siteLocationsSrv.getSiteLocations();
                            }
                        },
                        err => {
                            this.AlertSrv.show('error', 'Unable to update location. Please try agian later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
            return true;
        };

        const saveRenameGroupData = (textblock: any, oldstr: string, newstr: string) => {
            const data = textblock.part.data;
            const fromNode = myCoreDiagram.findNodeForKey(data.from)?.data;
            const toNode = myCoreDiagram.findNodeForKey(data.to)?.data;
            console.log('datatata', data);
            if (data.locationId && data.group_Id) {
                const updateCoresGroupObj = new CoresGroup({
                    locationId: data.locationId,
                    groupColor: data.groupColor,
                    coresIds: data.group_Cores,
                    id: data.group_Id,
                    name: newstr,
                });
                new LocationApi().UpdateCoresGroups(updateCoresGroupObj);
                this.siteLocationsSrv.getSiteLocations();
                if (fromNode && toNode) {
                    showSelectedCore(fromNode, toNode);
                }
            }

            return true;
        };

        const minimizePageCoreView = () => {
            this.minimizePageCoreView(false);
        };

        const removeOtherNodes = () => {
            savedCoresModelContent.linkDataArray = [];
            savedCoresModelContent.linkDataArray.length = 0;
            savedCoresModelContent.nodeDataArray.length = 0;
            savedCoresModelContent.nodeDataArray = [
                {key: -1, category: 'Conditional', loc: '280 100', text: 'Add Core', icon: 'round.png'},
                {key: -2, category: 'Conditional', loc: '420 100', text: 'Swap', icon: 'swap.png'},
            ];
            this.$emit('fullPageFormView', 'core');
        };

        const showSelectedCore = (fromNode: any, toNode: any) => {
            removeOtherNodes();
            myCoreDiagram.clear();
            const fromNodeNew = Object.assign({}, fromNode, {move: false, loc: '100 100', positionCore: 'Left'});
            const toNodeNew = Object.assign({}, toNode, {move: false, loc: '600 100', positionCore: 'Right'});
            let lineHeight = 15;
            let startingPoint = 30;
            // console.log('this.selectedCoreData fromNode', fromNode);
            // console.log('this.selectedCoreData toNode', toNode);
            console.log('this.selectedCoreData fromNodeNew', fromNodeNew);
            console.log('this.selectedCoreData toNodeNew', toNodeNew);

            this.LoaderSrv.showFullScreenLoader();

            new LocationApi()
                .getLocationById(fromNodeNew.key!, {
                    include: [
                        {
                            relation: 'locationDetail',
                        },
                        {
                            relation: 'equipmentDetail',
                        },
                        {
                            relation: 'distributionPanel',
                        },
                        {
                            relation: 'switchBoards',
                            order: ['sequence'],
                        },
                        {
                            relation: 'coresGroups',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['sequence'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.coreFromLocationData = new LocationModel(res.Data);

                            // console.log('fromNodeNew Data => ?res.Data -> ', res.Data);
                            const coresGroups = res.Data.coresGroups;
                            if (fromNodeNew.icon === 'dp.png') {
                                if (res.Data.switchBoards && res.Data.switchBoards.length) {
                                    // const switchBoards = res.Data.switchBoards.sort((a, b) => a);
                                    res.Data.switchBoards.forEach((switchBoard, index) => {
                                        if (
                                            switchBoard.connectWith === toNodeNew.key
                                            // && switchBoard.connectDirection === 'OUT'
                                        ) {
                                            // const fromNodeData = myCoreDiagram.findNodeForKey(fromNodeNew.key);
                                            // const toNodeData = myCoreDiagram.findNodeForKey(toNodeNew.key);
                                            const coresGroup = coresGroups?.find((x: any) => x.coresIds?.includes(switchBoard.id));
                                            const linkData = {
                                                from: fromNodeNew.key,
                                                to: toNodeNew.key,
                                                name: switchBoard.name ? switchBoard.name : 'Core ' + (index + 1),
                                                groupColor: coresGroup && coresGroup.groupColor ? coresGroup.groupColor : '',
                                                group_name:
                                                    coresGroup && coresGroup.name
                                                        ? coresGroup.name
                                                        : `G ${coresGroup && coresGroup.groupColor ? coresGroup.groupColor : 'NewGroup'}`,
                                                group_Id: coresGroup && coresGroup.id ? coresGroup.id : '',
                                                group_Cores: coresGroup && coresGroup.coresIds ? coresGroup.coresIds : [],
                                                locationId: this.coreFromLocationData.id,
                                                sequence: index + 1,
                                                fromPort: '',
                                                toPort: '',
                                                points: [100, 200 + startingPoint],
                                                coreId: switchBoard.id,
                                            };

                                            savedCoresModelContent.linkDataArray.push(linkData as never);

                                            myCoreDiagram.model = go.Model.fromJson(savedCoresModelContent);
                                            startingPoint = startingPoint + 30;
                                            lineHeight = lineHeight + 50;
                                            // const toolManager = myCoreDiagram.toolManager.linkingTool;
                                            // toolManager.insertLink(fromNodeData, null, toNodeData, null);
                                            // toolManager.insertLink(fromNodeData, null, toNodeData, null);
                                        }
                                    });

                                    myCoreDiagram.commit(d => {
                                        d.links.each((link: any) => {
                                            // console.log('randomColor------', link.data);
                                            if (link.data && link.data.groupColor !== '') {
                                                link.findObject('GROUP_SHAPE').fill = '#80' + (link.data && link.data.groupColor ? link.data.groupColor : '');
                                                link.findObject('GROUP_SHAPE').width = 400;
                                                link.findObject('GROUP_SHAPE').height = 35;
                                                link.findObject('GROUP_SHAPE_NAME').group_name = link.data.group_name ? link.data.group_name : 'Group Name';
                                                link.findObject('GROUP_SHAPE_NAME').width = 80;
                                                // console.log('randomColor------', link.findObject('GROUP_SHAPE_NAME'));
                                            }
                                        });
                                    });
                                }
                            } else if (fromNodeNew.icon === 'equip.png') {
                                if (res.Data.wiringCables && res.Data.wiringCables.length) {
                                    const wiringCables = res.Data.wiringCables.sort((a: any, b: any) => a.sequence - b.sequence);
                                    wiringCables.forEach((wiringCable, index) => {
                                        if (
                                            wiringCable.connectWith === toNodeNew.key
                                            // && wiringCable.connectDirection === 'IN'
                                        ) {
                                            // const fromNodeData = myCoreDiagram.findNodeForKey(fromNodeNew.key);
                                            // const toNodeData = myCoreDiagram.findNodeForKey(toNodeNew.key);
                                            const coresGroup = coresGroups?.find((x: any) => x.coresIds?.includes(wiringCable.id));

                                            const linkData = {
                                                from: fromNodeNew.key,
                                                to: toNodeNew.key,
                                                name: wiringCable.name ? wiringCable.name : 'Core ' + wiringCable.sequence,
                                                sequence: wiringCable.sequence,
                                                groupColor: coresGroup && coresGroup.groupColor ? coresGroup.groupColor : '',
                                                group_name:
                                                    coresGroup && coresGroup.name
                                                        ? coresGroup.name
                                                        : `G ${coresGroup && coresGroup.groupColor ? coresGroup.groupColor : 'NewGroup'}`,
                                                group_Id: coresGroup && coresGroup.id ? coresGroup.id : '',
                                                group_Cores: coresGroup && coresGroup.coresIds ? coresGroup.coresIds : [],
                                                locationId: this.coreFromLocationData.id,
                                                fromPort: '',
                                                toPort: '',
                                                points: [100, 200 + startingPoint],
                                                coreId: wiringCable.id,
                                            };
                                            // this.coreLocationDataArray.push(linkData);

                                            savedCoresModelContent.linkDataArray.push(linkData as never);

                                            myCoreDiagram.model = go.Model.fromJson(savedCoresModelContent);
                                            startingPoint = startingPoint + 30;
                                            lineHeight = lineHeight + 50;
                                            // const toolManager = myCoreDiagram.toolManager.linkingTool;
                                            // toolManager.insertLink(fromNodeData, null, toNodeData, null);
                                            // toolManager.insertLink(fromNodeData, null, toNodeData, null);
                                        }
                                    });

                                    myCoreDiagram.commit(d => {
                                        d.links.each((link: any) => {
                                            if (link.data && link.data.groupColor !== '') {
                                                // console.log('randomColor------', link.data);
                                                link.findObject('GROUP_SHAPE').fill = '#80' + (link.data && link.data.groupColor ? link.data.groupColor : '');
                                                link.findObject('GROUP_SHAPE').width = 400;
                                                link.findObject('GROUP_SHAPE').height = 35;
                                                link.findObject('GROUP_SHAPE_NAME').group_name = link.data.group_name ? link.data.group_name : 'Group Name';
                                                link.findObject('GROUP_SHAPE_NAME').width = 80;
                                                // console.log('randomColor------', link.findObject('GROUP_SHAPE_NAME'));
                                            }
                                        });
                                        this.siteLocationsSrv.getSiteLocations();
                                    });
                                }
                            }

                            // myCoreDiagram.model = go.Model.fromJson(savedCoresModelContent);
                        } else {
                            // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                            // console.log('From Location Not found', fromNodeNew.key);
                            this.coreFromLocationData = null;
                        }
                    },
                    err => {
                        // this.AlertSrv.show('warning', 'Unable to load data.');
                    }
                )
                .add(() => {
                    const heightOfDiagram = myCoreDiagram.documentBounds.height;
                    // console.log('heightOfDiagram', heightOfDiagram);
                    if (heightOfDiagram > 200) {
                        fromNodeNew.loc = '100 ' + heightOfDiagram;
                        toNodeNew.loc = '600 ' + heightOfDiagram;
                    }
                    myCoreDiagram.model.addNodeData({...fromNodeNew, lineHeight});
                    myCoreDiagram.model.addNodeData({...toNodeNew, lineHeight});
                    this.getLocationPathFromArray();
                });

            new LocationApi()
                .getLocationById(toNodeNew.key!, {
                    include: [
                        {
                            relation: 'locationDetail',
                        },
                        {
                            relation: 'equipmentDetail',
                        },
                        {
                            relation: 'distributionPanel',
                        },
                        {
                            relation: 'switchBoards',
                            order: ['createdAt'],
                        },
                        {
                            relation: 'coresGroups',
                        },
                        {
                            relation: 'wiringCables',
                            order: ['createdAt'],
                        },
                    ],
                })
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.coreToLocationData = new LocationModel(res.Data);

                            // console.log('toNode Data = ?res.Data -> ', res.Data);

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.approvalType ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.approvalType === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.approvalType = [];
                            // }

                            // if (
                            //     this.siteLocationsSrv.selectedLcoation &&
                            //     (!this.siteLocationsSrv.selectedLcoation?.environmentalCondition ||
                            //         typeof this.siteLocationsSrv.selectedLcoation?.environmentalCondition === 'string')
                            // ) {
                            //     this.siteLocationsSrv.selectedLcoation.environmentalCondition = [];
                            // }
                        } else {
                            // this.siteLocationsSrv.selectedLcoation = new LocationModel({locationId: location.id});
                            // console.log('From Location Not found', toNodeNew.key);
                            this.siteLocationsSrv.selectedLcoation = null;
                        }
                    },
                    err => {
                        this.AlertSrv.show('warning', 'Unable to load data.');
                    }
                )
                .add(() => {
                    this.getLocationPathToArray();
                    this.LoaderSrv.hideFullScreenLoader();
                });

            // console.log('this.selectedCoreData =>', this.selectedCoreData);
            this.coreView = true;
            // document.getElementById('drawingDivCanvas')!.style.display = 'none';
            // document.getElementById('treeDrawingCanvasDiv')!.style.display = 'none';
            // document.getElementById('drawingDivCanvasCoresDiv')!.style.display = 'block';
            this.$refs.drawingDivCanvas.style.display = 'none';
            this.$refs.treeDrawingCanvasDiv.style.display = 'none';
            this.$refs.drawingDivCanvasCoresDiv.style.display = 'block';
        };

        const myTreeDiagram = new go.Diagram(
            'treeDrawingCanvasDiv', // must be the ID or reference to div
            {
                allowCopy: false,
                'grid.visible': false,
                scrollMargin: 200,
                allowHorizontalScroll: false,
                allowVerticalScroll: true,
                layout: $(go.TreeLayout, {angle: 90, nodeSpacing: 100, layerSpacing: 100, layerStyle: go.TreeLayout.LayerUniform}),
                nodeTemplate: $(
                    go.Node,
                    'Table',
                    nodeStyle(),
                    {contextMenu: nodeMenu, movable: true},
                    new go.Binding('movable', 'move'),
                    {
                        click: function (e, node: any) {
                            // . . . now node is the Node that was double-clicked
                            const data = node.data;
                            // console.log(data);
                            showSelectedNode(data);
                            // now data has all of the custom app-specific properties that you have
                            // supplied for that node, in the Model.nodeDataArray
                            // "9541faa8-255f-49c0-9242-72df4981fc07"
                        },
                        doubleClick: function (e, obj: any) {
                            onNodeDoubleClick(obj);
                        },
                    },
                    // {portId: ''}, // this whole panel acts as the only port for the node
                    $(
                        go.Panel,
                        'Vertical',
                        $(
                            go.Panel,
                            'Table',
                            $(go.Shape, 'RoundedRectangle', {width: 100, height: 100, strokeWidth: 1, parameter1: 10, fill: '#DBE1F0'}),
                            $(
                                go.Panel,
                                'Vertical', // everything within the border
                                $(
                                    go.TextBlock,
                                    {
                                        editable: true,
                                        margin: 4,
                                        maxLines: 1,
                                        overflow: go.TextBlock.OverflowEllipsis,
                                        isMultiline: false,
                                        textAlign: 'center',
                                        width: 80,
                                        textValidation: saveRenameNodeLocation,
                                    },
                                    textStyle(),
                                    new go.Binding('text').makeTwoWay()
                                ),
                                $(go.Picture, {width: 50, height: 50, margin: new go.Margin(0, 5, 5, 5)}, new go.Binding('source', 'icon', makeImagePath)),
                                $(
                                    go.Panel,
                                    'Position', // the row of status indicators
                                    makeIndicatorLeft('ind0'),
                                    makeIndicatorRight('ind1')
                                )
                            )
                        )
                    ),
                    makePort('T', go.Spot.Top, go.Spot.TopSide, true, true),
                    makePort('L', go.Spot.Left, go.Spot.LeftSide, true, true),
                    makePort('R', go.Spot.Right, go.Spot.RightSide, true, true),
                    makePort('B', go.Spot.Bottom, go.Spot.BottomSide, true, true)
                ),
            }
        );

        const myDiagram = new go.Diagram('drawingDivCanvas', {
            // enable Ctrl-Z to undo and Ctrl-Y to redo
            'undoManager.isEnabled': true,
            'grid.visible': true,
            allowHorizontalScroll: true,
            allowVerticalScroll: true,
            linkTemplate: $(
                go.Link, // the whole link panel
                {
                    routing: go.Link.AvoidsNodes,
                    curve: go.Link.JumpOver,
                    corner: 0,
                    toShortLength: 0,
                    relinkableFrom: true,
                    relinkableTo: true,
                    reshapable: true,
                    resegmentable: true,
                    // mouse-overs subtly highlight links:
                    mouseEnter: (e: go.InputEvent, link: any) => (link.findObject('HIGHLIGHT').stroke = 'rgba(30,144,255,0.2)'),
                    mouseLeave: (e: go.InputEvent, link: any) => (link.findObject('HIGHLIGHT').stroke = 'transparent'),
                    selectionAdorned: false,
                },
                new go.Binding('points').makeTwoWay(),
                $(
                    go.Shape, // the highlight shape, normally transparent
                    {isPanelMain: true, strokeWidth: 8, stroke: 'transparent', name: 'HIGHLIGHT'}
                ),
                $(
                    go.Shape, // the link path shape
                    {isPanelMain: true, stroke: 'gray', strokeWidth: 1.5},
                    new go.Binding('stroke', 'isSelected', sel => (sel ? 'dodgerblue' : 'black')).ofObject(),
                    new go.Binding('strokeWidth').makeTwoWay()
                ),
                {
                    doubleClick: function (e, obj: any) {
                        // . . . now node is the Node that was double-clicked
                        const data = obj.data;
                        // console.log(data);
                        const fromNode = obj.fromNode;
                        // console.log('from', fromNode.data);
                        const toNode = obj.toNode;

                        // console.log('toNode', toNode.data);
                        if (fromNode.data.key !== -99 && toNode.data.icon !== 'earthWire.png') {
                            showSelectedCore(fromNode.data, toNode.data);
                        }
                        // now data has all of the custom app-specific properties that you have
                        // supplied for that node, in the Model.nodeDataArray
                        // "9541faa8-255f-49c0-9242-72df4981fc07"
                    },
                },
                $(go.TextBlock, 'Out', {segmentIndex: 0, segmentOffset: new go.Point(25, 15)}),
                $(go.TextBlock, 'In', {segmentIndex: -1, segmentOffset: new go.Point(-10, -15)})
            ),
            nodeTemplate: $(
                go.Node,
                'Table',
                nodeStyle(),
                {contextMenu: nodeMenu, movable: true},
                new go.Binding('movable', 'move'),
                {
                    click: function (e, node: any) {
                        // . . . now node is the Node that was double-clicked
                        const data = node.data;
                        // console.log(data);
                        showSelectedNode(data);
                        // now data has all of the custom app-specific properties that you have
                        // supplied for that node, in the Model.nodeDataArray
                        // "9541faa8-255f-49c0-9242-72df4981fc07"
                    },
                    doubleClick: function (e, obj: any) {
                        onNodeDoubleClick(obj);
                    },
                },
                // {portId: ''}, // this whole panel acts as the only port for the node
                $(
                    go.Panel,
                    'Vertical',
                    $(
                        go.Panel,
                        'Table',
                        $(go.Shape, 'RoundedRectangle', {width: 100, height: 100, strokeWidth: 1, parameter1: 10, fill: '#DBE1F0'}),
                        $(
                            go.Panel,
                            'Vertical', // everything within the border
                            $(
                                go.TextBlock,
                                {
                                    editable: true,
                                    margin: 4,
                                    maxLines: 1,
                                    overflow: go.TextBlock.OverflowEllipsis,
                                    isMultiline: false,
                                    textAlign: 'center',
                                    width: 80,
                                    textValidation: saveRenameNodeLocation,
                                },
                                textStyle(),
                                new go.Binding('text').makeTwoWay()
                            ),
                            $(go.Picture, {width: 50, height: 50, margin: new go.Margin(0, 5, 5, 5)}, new go.Binding('source', 'icon', makeImagePath)),
                            $(
                                go.Panel,
                                'Position', // the row of status indicators
                                makeIndicatorLeft('ind0'),
                                makeIndicatorRight('ind1')
                            )
                        )
                    )
                ),
                makePort('T', go.Spot.Top, go.Spot.TopSide, true, true),
                makePort('L', go.Spot.Left, go.Spot.LeftSide, true, true),
                makePort('R', go.Spot.Right, go.Spot.RightSide, true, true),
                makePort('B', go.Spot.Bottom, go.Spot.BottomSide, true, true)
            ),
        });

        myDiagram.commandHandler.doKeyDown = function () {
            // must be a function, not an arrow =>
            const e = this.diagram.lastInput;
            // The meta (Command) key substitutes for "control" for Mac commands
            const control = e.control || e.meta;
            const key = e.key;
            // Quit on any undo/redo key combination:
            if (control && (key === 'Z' || key === 'Y' || key === 'C' || key === 'V' || key === 'X')) return;

            if (key === 'Del' || key === 'Backspace' || key === 'Delete') return;

            // call base method with no arguments (default functionality)
            go.CommandHandler.prototype.doKeyDown.call(this);
        };

        // myDiagram.commandHandler.deleteSelection = function () {
        //     const e = this.diagram.lastInput;

        //     console.log('log E', e);
        //     console.log('log E.key', e.key);
        //     return;

        //     // call base method with no arguments (default functionality)
        //     go.CommandHandler.prototype.doKeyDown.call(this);
        // };

        const nodeMenuCores = $(
            'ContextMenu',
            makeButton(
                'Delete',
                (e: any) => {
                    console.log('REMOVE CALLED');
                    console.log(e.diagram);
                    e.diagram.commandHandler.deleteSelection();
                },
                false,
                'remove'
            ),
            makeButton(
                'Bundle',
                (e: any) => {
                    myCoreDiagram.commit(d => {
                        let connectWith = '';
                        let locationId = '';
                        let locationNodeType = '';
                        let connectNodeType = '';
                        let fromNode = null;
                        let toNode = null;
                        d.nodes.each(node => {
                            if (node.key === -1) return;
                            if (node.key === -2) return;

                            const nodeData = myCoreDiagram.findNodeForKey(node.key);

                            if (nodeData?.data.positionCore === 'Left') {
                                locationId = nodeData.data.key;
                                locationNodeType = nodeData.data.icon;
                                fromNode = myCoreDiagram.findNodeForKey(locationId)?.data;
                            }

                            if (nodeData?.data.positionCore === 'Right') {
                                connectWith = nodeData.data.key;
                                connectNodeType = nodeData.data.icon;
                                toNode = myCoreDiagram.findNodeForKey(connectWith)?.data;
                            }
                        });
                        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                        const coresArray: any[] = [];
                        d.selection.each((link: any) => {
                            coresArray.push(link.data.coreId);
                            // console.log('link.coreId', link.coreId);
                            // console.log('randomColor', randomColor);
                            link.findObject('GROUP_SHAPE')!.fill = '#80' + randomColor;
                            link.findObject('GROUP_SHAPE')!.width = 400;
                            link.findObject('GROUP_SHAPE')!.height = 25;
                            link.findObject('GROUP_SHAPE_NAME')!.group_name = 'Group Name';
                            link.findObject('GROUP_SHAPE_NAME')!.width = 80;
                        });
                        const newCoresGroupData = {
                            coresIds: coresArray,
                            groupColor: randomColor,
                            locationId: locationId,
                            name: `G ${randomColor}`,
                        };
                        new LocationApi().CreateLocationCoresGroups(newCoresGroupData);
                        this.siteLocationsSrv.getSiteLocations();
                        if (fromNode && toNode) {
                            showSelectedCore(fromNode, toNode);
                        }
                    }, 'change color');
                },
                (e: any, obj: any) => {
                    let returnValue = false;
                    myCoreDiagram.commit(d => {
                        d.selection.each((link: any) => {
                            const linkGrouped = link.findObject('GROUP_SHAPE')!.width;
                            // console.log('linkGrouped', linkGrouped);
                            if (linkGrouped) {
                                returnValue = false;
                            } else {
                                returnValue = true;
                            }
                        });
                    }, 'change color');
                    return returnValue;
                },
                'bundle'
            ),
            makeButton(
                'Un Bundle',
                (e: any, obj: any) => {
                    const clickedElm = obj.part.adornedPart;
                    console.log('clickedElm', clickedElm);
                    const selectionArray = <any>[];
                    myCoreDiagram.links.each((linkCheck: any) => {
                        // console.log('clickedElm Data', clickedElm.data.group_Id);
                        if (linkCheck.data.group_Id === clickedElm.data.group_Id) {
                            selectionArray.push(linkCheck);
                        }
                        console.log('clickedElm MATCHED', clickedElm.data);
                    });
                    myCoreDiagram.commit(async d => {
                        d.selectCollection(selectionArray);
                    });
                    myCoreDiagram.commit(async d => {
                        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                        let groupId = '';
                        let locationId = '';
                        d.selection.each((link: any) => {
                            groupId = link.data.group_Id;
                            locationId = link.data.locationId;
                            console.log('randomColor', randomColor);
                            console.log('groupId', groupId);
                            console.log('locationId', locationId);
                            link.findObject('GROUP_SHAPE')!.fill = null;
                            link.findObject('GROUP_SHAPE')!.width = 0;
                            link.findObject('GROUP_SHAPE')!.height = 0;
                            link.findObject('GROUP_SHAPE_NAME')!.group_name = null;
                            link.findObject('GROUP_SHAPE_NAME')!.width = 0;
                            link.findObject('GROUP_SHAPE_NAME')!.height = 0;
                        });
                        if (groupId) {
                            console.log('Here', groupId);
                            await new LocationApi().deleteCoresGroups(groupId);
                            this.siteLocationsSrv.getSiteLocations();
                        }
                    }, 'change color');
                },
                (e: any, obj: any) => {
                    let returnValue = false;
                    myCoreDiagram.commit(d => {
                        d.selection.each((link: any) => {
                            const linkGrouped = link.findObject('GROUP_SHAPE')!.width;
                            // console.log('linkGrouped', linkGrouped);
                            if (linkGrouped) {
                                returnValue = true;
                            } else {
                                returnValue = false;
                            }
                        });
                    }, 'change color');
                    return returnValue;
                },
                ''
            ),
            $(go.Shape, 'LineH', {strokeWidth: 2, height: 1, stretch: go.GraphObject.Horizontal})
        );

        const clickedCoreView = (fromNode: any, toNode: any, clickedLink: any) => {
            // if (this.coreDetailsGroupArrayList && this.coreDetailsGroupArrayList.length) {
            //     return;
            // }
            me.coreDetailsView = true;
            me.coreGroupView = false;

            me.coreDetailsViewDPE = false;
            me.coreDetailsViewEDP = false;

            this.switchBoardsFromArrayList = [];
            this.switchBoardsToArrayList = [];
            // console.log('this.coreFromLocationData 1', this.coreFromLocationData);
            this.coreDetailsArrayList = this.coreFromLocationData.wiringCables.filter((c: any) => c.connectWith === toNode.key && c.connectDirection === 'OUT');
            this.setActive(this.coreDetailsArrayList.find((c: any) => c.sequence === clickedLink.sequence).id);
            // console.log('this.coreDetailsArrayList 2', this.coreDetailsArrayList);
        };

        const clickedCoreViewDP = (fromNode: any, toNode: any, clickedLink: any) => {
            me.coreDetailsView = true;
            me.coreGroupView = false;

            me.coreDetailsViewDPE = false;
            me.coreDetailsViewEDP = false;

            this.coreDetailsArrayList = [];
            // console.log('this.coreFromLocationData 1', this.coreFromLocationData);
            this.switchBoardsFromArrayList = this.coreFromLocationData.switchBoards.filter(
                (sb: any) => sb.connectWith === toNode.key && sb.connectDirection.toLowerCase() === 'out'
            );
            this.switchBoardsToArrayList = this.coreToLocationData.switchBoards.filter(
                (sb: any) => sb.connectWith === fromNode.key && sb.connectDirection.toLowerCase() === 'in'
            );
            // this.setActive(this.switchBoardsFromArrayList.find((c: any) => c.sequence === clickedLink.sequence).id);
            // console.log('this.switchBoardsFromArrayList 2', this.switchBoardsFromArrayList);
        };

        const clickedCoreViewEDP = (fromNode: any, toNode: any, clickedLink: any) => {
            me.coreDetailsView = true;
            me.coreGroupView = false;

            me.coreDetailsViewEDP = true;
            me.coreDetailsArrayList = [];
            // console.log('this.coreFromLocationData 1 ---------', this.coreFromLocationData);
            this.coreDetailsArrayList = this.coreFromLocationData.wiringCables.filter((c: any) => c.connectWith === toNode.key && c.connectDirection === 'OUT');
            this.setActive(this.coreDetailsArrayList.find((c: any) => c.sequence === clickedLink.sequence).id);

            // this.switchBoardsFromArrayList = this.coreFromLocationData.switchBoards.filter(
            //     (sb: any) => sb.connectWith === toNode.key && sb.connectDirection.toLowerCase() === 'out'
            // );
            this.switchBoardsToArrayList = this.coreToLocationData.switchBoards.filter(
                (sb: any) => sb.connectWith === fromNode.key && sb.connectDirection.toLowerCase() === 'in'
            );
            // this.setActive(this.switchBoardsFromArrayList.find((c: any) => c.sequence === clickedLink.sequence).id);
            console.log('this.switchBoardsToArrayList 2', this.switchBoardsToArrayList);
        };

        const clickedCoreViewDPE = (fromNode: any, toNode: any, clickedLink: any) => {
            me.coreDetailsView = true;
            me.coreGroupView = false;

            me.coreDetailsViewDPE = true;

            this.coreDetailsArrayList = [];
            // console.log('this.coreFromLocationData 1', this.coreFromLocationData);
            this.coreDetailsArrayList = this.coreToLocationData.wiringCables.filter((c: any) => c.connectWith === fromNode.key && c.connectDirection === 'IN');
            // this.setActive(this.coreDetailsArrayList.find((c: any) => c.sequence === clickedLink.sequence).id);

            this.switchBoardsFromArrayList = this.coreFromLocationData.switchBoards.filter(
                (sb: any) => sb.connectWith === toNode.key && sb.connectDirection.toLowerCase() === 'out'
            );
            // this.switchBoardsToArrayList = this.coreToLocationData.switchBoards.filter(
            //     (sb: any) => sb.connectWith === fromNode.key && sb.connectDirection.toLowerCase() === 'out'
            // );
            // this.setActive(this.switchBoardsFromArrayList.find((c: any) => c.sequence === clickedLink.sequence).id);
            console.log('this.switchBoardsFromArrayList 2', this.switchBoardsFromArrayList);
        };

        const clickedGroupView = (clickedGroup: any) => {
            if (clickedGroup && clickedGroup.group_Id) {
                this.coreGroupClicked = clickedGroup;

                me.coreDetailsView = true;

                me.coreGroupView = true;

                this.coreGroupViewObj = this.coreFromLocationData!.coresGroups.find((group: CoresGroup) => group.id === clickedGroup.group_Id);

                this.coreDetailsGroupArrayList = [];
                console.log('this.coreFromLocationData 1', this.coreFromLocationData);
                if (this.coreFromLocationData.type === 'equipment') {
                    this.coreDetailsGroupArrayList = this.coreFromLocationData.wiringCables.filter((c: any) => {
                        console.log('core', c);
                        return clickedGroup.group_Cores.includes(c.id);
                    });
                } else if (this.coreFromLocationData.type === 'distribution') {
                    this.coreDetailsGroupArrayList = this.coreFromLocationData.switchBoards.filter((c: any) => {
                        console.log('core', c);
                        return clickedGroup.group_Cores.includes(c.id);
                    });
                }
                console.log('this.coreDetailsGroupArrayList Grouped', this.coreDetailsGroupArrayList);
            }
        };

        // let alreadyClickedOnGroup = false;

        const myCoreDiagram = new go.Diagram('drawingDivCanvasCores', {
            'undoManager.isEnabled': false,
            'grid.visible': false,
            allowZoom: false,
            allowCopy: false,
            allowDelete: true,
            contentAlignment: go.Spot.Center,
            allowHorizontalScroll: false,
            allowVerticalScroll: true,
            linkTemplate: $(
                go.Link,
                {
                    routing: go.Link.Normal,
                    mouseEnter: (e: go.InputEvent, link: any) => (link.findObject('HIGHLIGHT').stroke = '#61c33399'),
                    mouseLeave: (e: go.InputEvent, link: any) => (link.findObject('HIGHLIGHT').stroke = 'transparent'),
                    // selectionAdorned: false,
                    contextMenu: nodeMenuCores,
                    // toShortLength: 2,
                    deletable: true,
                },
                $(
                    go.Shape,
                    {isPanelMain: true, stroke: '#000', strokeWidth: 2},
                    new go.Binding('stroke', 'isSelected', sel => (sel ? '#61C333' : 'black')).ofObject()
                ),
                new go.Binding('points').makeTwoWay(),
                $(
                    go.Shape,
                    {fill: '#000', stroke: null, toArrow: 'Triangle', scale: 2, name: 'ARROWHEAD'},
                    new go.Binding('fill', 'isSelected', sel => (sel ? '#61C333' : 'black')).ofObject()
                ),
                $(
                    go.Shape,
                    {
                        figure: 'Cylinder4',
                        width: 0,
                        height: 0,
                        name: 'GROUP_SHAPE',
                        fill: '#ffffff',
                        click: function (e: any, obj: any) {
                            console.log('clicked Group', obj.part.data);
                            const data = obj.part.data;
                            console.log(data);
                            clickedGroupView(data);
                        },
                    },
                    new go.Binding('fill', 'groupColor'),
                    new go.Binding('width', 'width'),
                    new go.Binding('height', 'height')
                ),
                $(
                    go.TextBlock,
                    {
                        editable: true,
                        name: 'GROUP_SHAPE_NAME',
                        maxLines: 1,
                        overflow: go.TextBlock.OverflowEllipsis,
                        isMultiline: false,
                        textAlign: 'center',
                        background: 'white',
                        width: 0,
                        textValidation: saveRenameGroupData,
                    },
                    new go.Binding('text', 'group_name'),
                    {segmentOffset: new go.Point(150, 0)}
                ),
                $(go.TextBlock, 'Out', {
                    segmentIndex: 0,
                    segmentOffset: new go.Point(-25, 0),
                }),
                $(go.TextBlock, new go.Binding('text', 'name'), {
                    segmentOffset: new go.Point(0, -10),
                }),
                $(go.TextBlock, 'In', {
                    segmentIndex: -1,
                    segmentOffset: new go.Point(15, 0),
                }),
                $(go.Shape, {isPanelMain: true, strokeWidth: 2, stroke: 'transparent', name: 'HIGHLIGHT'}),
                {
                    click: function (e, obj: any) {
                        const data = obj.data;
                        console.log('Clicked Core obj.data', data);
                        if (me.coreGroupClicked) {
                            me.coreGroupClicked = null;
                            return;
                        }
                        // if (data.group_Id || data.group_Id !== '') {
                        //     return false;
                        // }
                        const fromNode = obj.fromNode;
                        console.log('from', fromNode.data);
                        const toNode = obj.toNode;
                        console.log('toNode', toNode.data);
                        if (fromNode.data.icon === 'equip.png' && toNode.data.icon === 'equip.png') {
                            clickedCoreView(fromNode.data, toNode.data, data);
                        } else if (fromNode.data.icon === 'dp.png' && toNode.data.icon === 'dp.png') {
                            clickedCoreViewDP(fromNode.data, toNode.data, data);
                        } else if (fromNode.data.icon === 'equip.png' && toNode.data.icon === 'dp.png') {
                            clickedCoreViewEDP(fromNode.data, toNode.data, data);
                        } else if (fromNode.data.icon === 'dp.png' && toNode.data.icon === 'equip.png') {
                            clickedCoreViewDPE(fromNode.data, toNode.data, data);
                        }
                    },
                    // doubleClick: function (e, obj: any) {
                    //     const data = obj.data;
                    //     // console.log('obj.data', data);
                    //     if (data.group_Id || data.group_Id !== '') {
                    //         const fromNode = obj.fromNode;
                    //         // console.log('from', fromNode.data);
                    //         const toNode = obj.toNode;
                    //         // console.log('toNode', toNode.data);
                    //         if (fromNode.data.icon === 'equip.png' && toNode.data.icon === 'equip.png') {
                    //             clickedCoreView(fromNode.data, toNode.data, data);
                    //         } else if (fromNode.data.icon === 'dp.png' && toNode.data.icon === 'dp.png') {
                    //             clickedCoreViewDP(fromNode.data, toNode.data, data);
                    //         } else if (fromNode.data.icon === 'equip.png' && toNode.data.icon === 'dp.png') {
                    //             clickedCoreViewEDP(fromNode.data, toNode.data, data);
                    //         } else if (fromNode.data.icon === 'dp.png' && toNode.data.icon === 'equip.png') {
                    //             clickedCoreViewDPE(fromNode.data, toNode.data, data);
                    //         }
                    //     }
                    // },
                }
            ),
            nodeTemplate: $(
                go.Node,
                'Table',
                nodeStyle(),
                {selectable: false},
                new go.Binding('movable', 'move'),
                new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
                // {portId: ''}, // this whole panel acts as the only port for the node
                $(
                    go.Panel,
                    'Vertical',
                    $(
                        go.Panel,
                        'Table',
                        $(go.Shape, 'RoundedRectangle', {width: 100, height: 100, strokeWidth: 1, parameter1: 10, fill: '#DBE1F0'}),
                        $(
                            go.Panel,
                            'Vertical', // everything within the border
                            $(
                                go.TextBlock,
                                {
                                    editable: false,
                                    margin: 4,
                                    maxLines: 1,
                                    overflow: go.TextBlock.OverflowEllipsis,
                                    isMultiline: false,
                                    textAlign: 'center',
                                    width: 80,
                                },
                                textStyle(),
                                new go.Binding('text').makeTwoWay()
                            ),
                            $(go.Picture, {width: 50, height: 50, margin: new go.Margin(0, 5, 5, 5)}, new go.Binding('source', 'icon', makeImagePath)),
                            $(
                                go.Panel,
                                'Position', // the row of status indicators
                                makeIndicatorLeft('ind0'),
                                makeIndicatorRight('ind1')
                            )
                            // new go.Binding('text').makeTwoWay()
                        )
                    ),
                    // makeCoresPort('L', go.Spot.Left, go.Spot.LeftSide, true, true)
                    $(
                        go.Shape,
                        'Rectangle',
                        {
                            fromLinkable: true,
                            toLinkable: true,
                            fromLinkableDuplicates: true,
                            toLinkableDuplicates: true,
                            width: 0.5,
                            height: 20,
                            strokeWidth: 3,
                            strokeDashArray: [8, 3],
                            fill: '#fff',
                            stretch: go.GraphObject.Vertical,
                            portId: '',
                            toSpot: go.Spot.AllSides,
                            fromSpot: go.Spot.AllSides,
                            cursor: 'pointer',
                            mouseEnter: (e: {diagram: any}, port: any) => {
                                if (!e.diagram.isReadOnly) port.fill = '#61C333';
                            },
                            mouseLeave: (e: any, port: any) => (port.fill = 'transparent'),
                        },
                        new go.Binding('height', 'lineHeight')
                    )
                )
            ),
        });

        const showSelectedNode = (data: any) => {
            setTimeout(() => {
                if (me.isDoubleClick) {
                    me.isDoubleClick = false;
                } else {
                    me.selectedNodeData = data;
                    me.clickedFocusOnCanvas = true;
                    // me.locationItemClickFn(data);
                    me.$emit('goToLocation', data.key);
                }
            }, 1000);
        };

        const onNodeDoubleClick = (node: any) => {
            me.isDoubleClick = true;

            if (node.data?.key) {
                me.$emit('onNodeDoubleClick', node.data.key);
            }
        };

        // Add Core Button Template
        myCoreDiagram.nodeTemplateMap.add(
            'Conditional',
            $(
                go.Node,
                'Auto',
                nodeStyle(),
                {selectable: false},
                $(go.Shape, 'RoundedRectangle', {fill: '#A8B0BE', stroke: '#A8B0BE', width: 100, height: 45}),
                $(
                    'Button',
                    {
                        'ButtonBorder.fill': '#A8B0BE',
                        'ButtonBorder.figure': 'RoundedRectangle',
                        'ButtonBorder.stroke': '#A8B0BE',
                        'ButtonBorder.strokeWidth': 0.5,
                        _buttonFillOver: '#A8B0BE',
                        width: 100,
                        height: 45,
                        click: (e: any, obj: any) => {
                            if (obj.part.data.key === -1) {
                                // Add Core Function
                                myCoreDiagram.commit(d => {
                                    let connectWith = '';
                                    let locationId = '';
                                    let locationNodeType = '';
                                    let connectNodeType = '';
                                    const randomName = Math.floor(Math.random() * 16777215).toString(16);
                                    const connectionKey = makeid(10);

                                    d.nodes.each(node => {
                                        if (node.key === -1) return;
                                        if (node.key === -2) return;

                                        const nodeData = myCoreDiagram.findNodeForKey(node.key);
                                        // console.log('nodeData?.data', nodeData?.data);
                                        // console.log('nodeData', nodeData);

                                        if (nodeData?.data.positionCore === 'Left') {
                                            locationId = nodeData.data.key;
                                            locationNodeType = nodeData.data.icon;
                                        }

                                        if (nodeData?.data.positionCore === 'Right') {
                                            connectWith = nodeData.data.key;
                                            connectNodeType = nodeData.data.icon;
                                        }

                                        // myCoreDiagram.startTransaction();
                                        // myCoreDiagram.remove(node);
                                        // myCoreDiagram.commitTransaction('deleted node');
                                    });

                                    if (locationNodeType === 'dp.png') {
                                        const newBoard = {
                                            locationId,
                                            connectWith,
                                            connectDirection: 'OUT',
                                            name: `Core ${randomName}`,
                                            connectionKey,
                                            sequence:
                                                this.coreFromLocationData.switchBoards && this.coreFromLocationData.switchBoards.length
                                                    ? this.coreFromLocationData.switchBoards.length + 1
                                                    : 1,
                                        };
                                        new LocationApi()
                                            .CreateLocationSwitchBoard(newBoard)
                                            .subscribe(
                                                async res => {
                                                    // console.log('res.Data', res.Data);
                                                    // this.AlertSrv.show('success', 'Switch Board has been saved successfully!');
                                                    // this.showNewBoard = false;
                                                    // await this.updateSyncLocation(this.newBoard);
                                                    // this.newBoard = new SwitchBoardModel();
                                                    // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                },
                                                err => {
                                                    // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save switch board. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                this.LoaderSrv.hideFullScreenLoader();
                                            });
                                    } else if (locationNodeType === 'equip.png') {
                                        const newWiringCable = {
                                            locationId,
                                            connectWith,
                                            connectDirection: 'OUT',
                                            name: `Core ${randomName}`,
                                            connectionKey,
                                            sequence:
                                                this.coreFromLocationData.wiringCables && this.coreFromLocationData.wiringCables.length
                                                    ? this.coreFromLocationData.wiringCables.length + 1
                                                    : 1,
                                        };
                                        new LocationApi()
                                            .CreateLocationWiringCable(newWiringCable)
                                            .subscribe(
                                                res => {
                                                    // console.log('CreateLocationWiringCable resData', res.Data);
                                                },
                                                err => {
                                                    // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                // this.newWiringCableObj = new WiringCableModel();
                                                // this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }

                                    if (connectNodeType === 'dp.png') {
                                        const newBoard = {
                                            locationId: connectWith,
                                            connectWith: locationId,
                                            connectDirection: 'IN',
                                            name: `Core ${randomName}`,
                                            connectionKey,
                                            sequence:
                                                this.coreToLocationData.wiringCables && this.coreToLocationData.wiringCables.length
                                                    ? this.coreToLocationData.wiringCables.length + 1
                                                    : 1,
                                        };
                                        new LocationApi()
                                            .CreateLocationSwitchBoard(newBoard)
                                            .subscribe(
                                                async res => {
                                                    // console.log('res.Data', res.Data);
                                                    // this.AlertSrv.show('success', 'Switch Board has been saved successfully!');
                                                    // this.showNewBoard = false;
                                                    // await this.updateSyncLocation(this.newBoard);
                                                    // this.newBoard = new SwitchBoardModel();
                                                    // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                },
                                                err => {
                                                    // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save switch board. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                const fromNode = myCoreDiagram.findNodeForKey(locationId);
                                                const toNode = myCoreDiagram.findNodeForKey(connectWith);
                                                showSelectedCore(fromNode?.data, toNode?.data);
                                                // this.LoaderSrv.hideFullScreenLoader();
                                            });
                                    } else if (connectNodeType === 'equip.png') {
                                        const newWiringCable = {
                                            locationId: connectWith,
                                            connectWith: locationId,
                                            name: `Core ${randomName}`,
                                            connectionKey,
                                            connectDirection: 'IN',
                                            sequence:
                                                this.coreToLocationData.wiringCables && this.coreToLocationData.wiringCables.length
                                                    ? this.coreToLocationData.wiringCables.length + 1
                                                    : 1,
                                        };
                                        new LocationApi()
                                            .CreateLocationWiringCable(newWiringCable)
                                            .subscribe(
                                                res => {
                                                    // console.log('CreateLocationWiringCable resData', res.Data);
                                                },
                                                err => {
                                                    // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                    this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                                                }
                                            )
                                            .add(() => {
                                                const fromNode = myCoreDiagram.findNodeForKey(locationId);
                                                const toNode = myCoreDiagram.findNodeForKey(connectWith);
                                                showSelectedCore(fromNode?.data, toNode?.data);
                                                // this.newWiringCableObj = new WiringCableModel();
                                                // this.LoaderSrv.hideFullScreenLoader();
                                                // this.$forceUpdate();
                                            });
                                    }
                                    // console.log('locationId', locationId);
                                    // console.log('connectWith', connectWith);
                                    this.siteLocationsSrv.getSiteLocations();
                                }, 'Adding Nodes');
                            } else if (obj.part.data.key === -2) {
                                // console.log('Swap Data');

                                let locationId = '';
                                let locationNodeType = '';

                                let connectWith = '';
                                let connectNodeType = '';

                                myCoreDiagram.nodes.each(node => {
                                    if (node.key === -1) return;
                                    if (node.key === -2) return;

                                    const nodeData = myCoreDiagram.findNodeForKey(node.key);

                                    // console.log('nodeData?.data', nodeData?.data);
                                    // console.log('nodeData', nodeData);

                                    if (nodeData?.data.positionCore === 'Left') {
                                        locationId = nodeData.data.key;
                                        locationNodeType = nodeData.data.icon;
                                    }

                                    if (nodeData?.data.positionCore === 'Right') {
                                        connectWith = nodeData.data.key;
                                        connectNodeType = nodeData.data.icon;
                                    }
                                });

                                myCoreDiagram.commit(d => {
                                    // console.log('this.coreFromLocationData', this.coreFromLocationData);

                                    console.log('coreFromLocationData', this.coreFromLocationData, locationNodeType, connectNodeType);
                                    console.log('coreToLocationData', this.coreToLocationData);
                                    if (locationNodeType === 'equip.png' && this.coreFromLocationData.type === 'equipment') {
                                        this.coreFromLocationData.wiringCables.forEach((wiringCable: any) => {
                                            if (connectWith === wiringCable.connectWith && wiringCable.connectDirection === 'OUT') {
                                                const wireToUpdate = {
                                                    ...wiringCable,
                                                    connectDirection: 'IN',
                                                };
                                                // console.log('wireToUpdate', wireToUpdate);
                                                new LocationApi()
                                                    .UpdateWiringCable(wireToUpdate)
                                                    .subscribe(
                                                        res => {
                                                            // console.log('res.Data 1', res.Data);
                                                            // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                            // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                        },
                                                        err => {
                                                            // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                            this.AlertSrv.show('error', 'Unable to update Wiring Entry. Please try again later!');
                                                        }
                                                    )
                                                    .add(() => {
                                                        // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                        // this.LoaderSrv.hideFullScreenLoader();
                                                    });
                                            }
                                        });
                                    } else if (locationNodeType === 'dp.png' && this.coreFromLocationData.type === 'distribution') {
                                        this.coreFromLocationData.switchBoards.forEach((switchBoard: any) => {
                                            if (connectWith === switchBoard.connectWith && switchBoard.connectDirection === 'OUT') {
                                                const boardToUpdate = {
                                                    ...switchBoard,
                                                    connectDirection: 'IN',
                                                };
                                                // console.log('boardToUpdate', boardToUpdate);
                                                new LocationApi()
                                                    .UpdateSwitchBoard(boardToUpdate)
                                                    .subscribe(
                                                        res => {
                                                            //  // console.log('res.Data', res.Data);
                                                            // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                            // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                        },
                                                        err => {
                                                            // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                            // this.AlertSrv.show('error', 'Unable to update Wiring Entry. Please try again later!');
                                                        }
                                                    )
                                                    .add(() => {
                                                        // this.AlertSrv.show('success', 'Switch Board has been updated successfully!');
                                                        // this.LoaderSrv.hideFullScreenLoader();
                                                    });
                                            }
                                        });
                                    }

                                    if (connectNodeType === 'equip.png' && this.coreToLocationData.type === 'equipment') {
                                        // console.log('HERE 2');
                                        this.coreToLocationData.wiringCables.forEach((wiringCable: any) => {
                                            // console.log('wiringCable loop ', wiringCable);

                                            if (locationId === wiringCable.connectWith && wiringCable.connectDirection === 'IN') {
                                                const wireToUpdate = {
                                                    ...wiringCable,
                                                    connectDirection: 'OUT',
                                                };
                                                // console.log('wireToUpdate', wireToUpdate);
                                                new LocationApi().UpdateWiringCable(wireToUpdate).subscribe(
                                                    res => {
                                                        // console.log('res.Data 2', res.Data);
                                                        // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                        // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                    },
                                                    err => {
                                                        // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                        this.AlertSrv.show('error', 'Unable to update Wiring Entry. Please try again later!');
                                                    }
                                                );
                                                // .add(() => {
                                                //     // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                //     // this.LoaderSrv.hideFullScreenLoader();
                                                //     // console.log('CONVERTED');
                                                //     const fromNode = myCoreDiagram.findNodeForKey(connectWith);
                                                //     const toNode = myCoreDiagram.findNodeForKey(locationId);
                                                //     showSelectedCore(fromNode?.data, toNode?.data);
                                                // });
                                            }
                                        });
                                    } else if (locationNodeType === 'dp.png' && this.coreToLocationData.type === 'distribution') {
                                        this.coreToLocationData.switchBoards.forEach((switchBoard: any) => {
                                            if (locationId === switchBoard.connectWith && switchBoard.connectDirection === 'IN') {
                                                const boardToUpdate = {
                                                    ...switchBoard,
                                                    connectDirection: 'OUT',
                                                };
                                                // console.log('boardToUpdate', boardToUpdate);
                                                new LocationApi().UpdateSwitchBoard(boardToUpdate).subscribe(
                                                    res => {
                                                        //  // console.log('res.Data', res.Data);
                                                        // this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                                                        // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                                    },
                                                    err => {
                                                        // console.log(this.siteLocationsSrv.selectedLcoation?.id);
                                                        this.AlertSrv.show('error', 'Unable to update Wiring Entry. Please try again later!');
                                                    }
                                                );
                                                // .add(() => {
                                                //     const fromNode = myCoreDiagram.findNodeForKey(connectWith);
                                                //     const toNode = myCoreDiagram.findNodeForKey(locationId);
                                                //     showSelectedCore(fromNode?.data, toNode?.data);
                                                //     // this.AlertSrv.show('success', 'Switch Board has been updated successfully!');
                                                //     // this.LoaderSrv.hideFullScreenLoader();
                                                // });
                                            }
                                        });
                                    }

                                    for (const g of (this.coreFromLocationData?.coresGroups ?? []) as Array<CoresGroup>) {
                                        let toCables: any = this.coreFromLocationData.wiringCables?.filter((x: any) => (g.coresIds ?? []).includes(x.id)) ?? [];

                                        if (toCables.length === 0) {
                                            toCables = this.coreFromLocationData.switchBoards?.filter((x: any) => (g.coresIds ?? []).includes(x.id)) ?? [];
                                        }

                                        const toConnectionKeys = toCables.map((x: any) => x.connectionKey);
                                        let fromCables =
                                            this.coreToLocationData.wiringCables?.filter((x: any) => toConnectionKeys.includes(x.connectionKey)) ?? [];

                                        if (fromCables.length === 0) {
                                            fromCables =
                                                this.coreToLocationData.switchBoards?.filter((x: any) => toConnectionKeys.includes(x.connectionKey)) ?? [];
                                        }
                                        // const final = fromCables.filter(x => toCables.map(y => y.connectWith).includes(x.connectWith));
                                        console.log('toConnectionKeys', toCables, toConnectionKeys);
                                        new LocationApi()
                                            .UpdateCoresGroups({
                                                ...g,
                                                coresIds: fromCables.map((x: any) => x.id) ?? [],
                                                locationId: this.coreToLocationData.id,
                                            })
                                            .then(r => {
                                                //
                                            })
                                            .catch(err => {
                                                console.log(err);
                                            });
                                    }

                                    // console.log('locationId', locationId);
                                    // console.log('connectWith', connectWith);
                                }, 'Swap Nodes');

                                myDiagram.commit(d => {
                                    d.links.each(link => {
                                        // console.log('object node for Link => ', link.data);
                                        if (link.data.from === -99) return;
                                        if (link.data.to <= -89) return;

                                        if (link.fromNode?.key === locationId && link.toNode?.key === connectWith) {
                                            console.log('Link Data', link);
                                            myDiagram.model.commit(m => {
                                                m.set(link.data, 'from', connectWith);
                                                m.set(link.data, 'to', locationId);
                                            });
                                        }

                                        // c.connectDirection === this.adminDataFieldsSrv.equipmentFieldOptions['equipmentWiringDirection'].find(x => x.value === 'Out')!.id
                                    });
                                });

                                const fromNode = myCoreDiagram.findNodeForKey(connectWith);
                                const toNode = myCoreDiagram.findNodeForKey(locationId);
                                showSelectedCore(fromNode?.data, toNode?.data);

                                // const dataFromModel = JSON.parse(myDiagram.model.toJson());
                                // const dataIndex = dataFromModel.linkDataArray.findIndex((l: any) => l.from === locationId && l.to === connectWith);
                                // console.log('data before', dataFromModel.linkDataArray[dataIndex]);
                                // dataFromModel.linkDataArray[dataIndex].from = connectWith;
                                // dataFromModel.linkDataArray[dataIndex].to = locationId;

                                // myDiagram.model = go.GraphLinksModel.fromJson(dataFromModel);
                                // console.log('data after', dataFromModel.linkDataArray[dataIndex]);

                                // myDiagram.commit(d => {
                                //     // dataJson.from = connectWith;
                                //     // dataJson.to = locationId;
                                //     // d.links.each(l => {
                                //     //     if (l.fromNode?.key === locationId && l.toNode?.key === connectWith) {

                                //     //         console.log('Link Data', l);

                                //     //     }
                                //     // });
                                // }, 'Swap Parent Node Link');
                            }
                            this.siteLocationsSrv.getSiteLocations();
                            updateLinksData();
                        },
                    },
                    $(
                        go.Panel,
                        'Horizontal',
                        $(go.Picture, {width: 30, height: 30}, new go.Binding('source', 'icon', makeImagePath)),
                        $(go.TextBlock, new go.Binding('text'), {margin: 2, textAlign: 'center'})
                    )
                )
            )
        );

        const updateDiagramJson = (diagramJSON: any) => {
            this.sitesApi.UpdateSite(this.siteId, new SiteModel({...this.siteToEdit, drawingCanvas1: diagramJSON})).subscribe(
                res => {
                    // console.log('Site has been UPDATED successfully!', res);
                    // console.log('Site has been UPDATED successfully!', res.Data);
                    this.siteLocationsSrv.site!.drawingCanvas1 = diagramJSON;
                    // this.AlertSrv.show('success', 'Site has been saved successfully!');
                },
                err => {
                    this.AlertSrv.show('error', 'Unable to save site. Please try again later.');
                }
            );
        };

        this.onSelectedLocationFocus = (nodeId: string) => {
            const node = myDiagram.findNodeForKey(nodeId);
            console.log('this.treeViewEnabled', this.treeViewEnabled);
            if (!this.clickedFocusOnCanvas) {
                if (node) {
                    focusOnNodeCanvas(node);
                    focusOnNodeTree(node);
                }
            }
            this.clickedFocusOnCanvas = false;
        };

        this.deleteLocationNodeFromId = (nodeId: string) => {
            const node = myDiagram.findNodeForKey(nodeId);
            if (node) {
                myDiagram.commit(m => {
                    m.remove(node);
                });
            }
        };

        function focusOnNodeTree(node: any) {
            myTreeDiagram.select(node);
            // Set up an Animation that shows the node significantly larger than normal
            // and then scales it back down to normal.
            // This intentionally does not operate on the selected node itself,
            // but on a temporary copy of it, so that the node and the model are unaffected.
            const focus1 = node.copy();
            focus1.layerName = 'Tool';
            focus1.isInDocumentBounds = false;
            focus1.locationSpot = go.Spot.Center;
            focus1.location = node.actualBounds.center;
            // Figure out how large to scale it initially; assume maximum is one third of the viewport size
            const w = Math.max(node.actualBounds.width, 1);
            const h = Math.max(node.actualBounds.height, 1);
            const viewscale = Math.max(myTreeDiagram.viewportBounds.width / w, myTreeDiagram.viewportBounds.height / h) / 3;
            // Now create the Animation showing the temporary node scaled initially at VIEWSCALE
            const anim = new go.Animation();
            anim.addTemporaryPart(focus1, myTreeDiagram);
            anim.add(focus1, 'scale', viewscale, 1.0); // and animating down to scale 1.0
            // This animation occurs concurrently with the scrolling animation.
            anim.duration = myTreeDiagram.animationManager.duration + 100;
            anim.start();
            // Meanwhile, make sure that the node is in the viewport, so the user can see it
            myTreeDiagram.commandHandler.scrollToPart(node);
        }

        function focusOnNodeCanvas(node: any) {
            myDiagram.select(node);
            // Set up an Animation that shows the node significantly larger than normal
            // and then scales it back down to normal.
            // This intentionally does not operate on the selected node itself,
            // but on a temporary copy of it, so that the node and the model are unaffected.
            const focus1 = node.copy();
            focus1.layerName = 'Tool';
            focus1.isInDocumentBounds = false;
            focus1.locationSpot = go.Spot.Center;
            focus1.location = node.actualBounds.center;
            // Figure out how large to scale it initially; assume maximum is one third of the viewport size
            const w = Math.max(node.actualBounds.width, 1);
            const h = Math.max(node.actualBounds.height, 1);
            const viewscale = Math.max(myDiagram.viewportBounds.width / w, myDiagram.viewportBounds.height / h) / 3;
            // Now create the Animation showing the temporary node scaled initially at VIEWSCALE
            const anim = new go.Animation();
            anim.addTemporaryPart(focus1, myDiagram);
            anim.add(focus1, 'scale', viewscale, 1.0); // and animating down to scale 1.0
            // This animation occurs concurrently with the scrolling animation.
            anim.duration = myDiagram.animationManager.duration + 100;
            anim.start();
            // Meanwhile, make sure that the node is in the viewport, so the user can see it
            myDiagram.commandHandler.scrollToPart(node);
        }

        myDiagram.addModelChangedListener(evt => {
            //  // console.log('addModelChangedListener', evt.isTransactionFinished);
            // console.log(evt.model?.toJson());
            if (!evt.isTransactionFinished) return;

            // console.log('New Data to SAVE ======================================');
            // console.log(this.siteId);
            // console.log(this.siteToEdit);
            const saveDiagramJson = evt.model!.toJson();
            // console.log(saveDiagramJson);
            if (this.siteToEdit && saveDiagramJson) {
                updateDiagramJson(saveDiagramJson);
            }

            // saveModel(evt.model);
        });

        myCoreDiagram.addDiagramListener('SelectionDeleting', function (e) {
            const sel = e.subject; // the collection of currently selected Parts
            sel.each(function (part: any) {
                // do something with the part being deleted
                console.log('About to delete:', part.data);

                const fromNode = myCoreDiagram.findNodeForKey(part.data.from)?.data;
                const toNode = myCoreDiagram.findNodeForKey(part.data.to)?.data;

                console.log('fromNode', fromNode);
                console.log('toNode', toNode);

                if (fromNode.icon === 'dp.png') {
                    new LocationApi().deleteSwitchBoards(part.data.coreId).subscribe();
                } else if (fromNode.icon === 'equip.png') {
                    new LocationApi().deleteWiringCables(part.data.coreId).subscribe();
                }
            });
            getSiteLocationServicesFromSrv();
        });

        const getSiteLocationServicesFromSrv = () => {
            this.siteLocationsSrv.getSiteLocations();
        };

        myDiagram.addDiagramListener('SelectionDeleting', function (e) {
            const sel = e.subject; // the collection of currently selected Parts
            sel.each(function (part: any) {
                // do something with the part being deleted
                console.log('About to delete:', part.data);

                const deletedNode = myDiagram.findNodeForKey(part.data.key)?.data;

                if (deletedNode?.key) {
                    new LocationApi().deleteLocation(deletedNode.key).subscribe(
                        async res => {
                            getSiteLocationServicesFromSrv();
                        },
                        err => {
                            console.log('error', err);
                        }
                    );
                }
                // if (fromNode.icon === 'dp.png') {
                //     new LocationApi().deleteSwitchBoards(part.data.coreId).subscribe();
                // } else if (fromNode.icon === 'equip.png') {
                //     new LocationApi().deleteWiringCables(part.data.coreId).subscribe();
                // }
            });
        });

        myDiagram.addDiagramListener('LinkDrawn', e => {
            // console.log('LINK CREATED');
            // console.log(e.subject.part.data);
            const linkData = e.subject.part.data;

            const fromNode = myDiagram.findNodeForKey(linkData.from);
            const toNode = myDiagram.findNodeForKey(linkData.to);

            myDiagram.model.commit(m => {
                m.set(toNode?.data, 'parent', fromNode?.data.key);
            });
            if (linkData.from === -99) {
                return;
            }
            if (linkData.to <= -89) {
                return;
            }
            const locationId = fromNode?.data.key;
            const locationNodeType = fromNode?.data.icon;

            const connectWith = toNode?.data.key;
            const connectNodeType = toNode?.data.icon;

            const fromLocation = this.siteLocationsSrv.siteLocations.find(location => location.id === locationId);
            const toLocation = this.siteLocationsSrv.siteLocations.find(location => location.id === connectWith);

            const isNodeExist =
                locationNodeType === 'dp.png'
                    ? fromLocation?.switchBoards?.filter(loc => loc.locationId === locationId && loc.connectWith === connectWith).length
                    : fromLocation?.wiringCables?.filter(loc => loc.locationId === locationId && loc.connectWith === connectWith).length;
            // console.log('isNodeExist', isNodeExist);

            if (!isNodeExist) {
                const randomName = Math.floor(Math.random() * 16777215).toString(16);
                const connectionKey = makeid(10);
                myDiagram.commit(d => {
                    if (locationNodeType === 'dp.png') {
                        const switchBoardsArray = this.siteLocationsSrv.siteLocations.find(location => location.id === locationId)?.switchBoards;
                        const newBoard = {
                            locationId,
                            connectWith,
                            connectDirection: 'OUT',
                            sequence: switchBoardsArray && switchBoardsArray.length ? switchBoardsArray.length + 1 : 1,
                            name: `Core ${randomName}`,
                            connectionKey,
                        };
                        new LocationApi()
                            .CreateLocationSwitchBoard(newBoard)
                            .subscribe(
                                async res => {
                                    // console.log('res.Data', res.Data);
                                    // this.AlertSrv.show('success', 'Switch Board has been saved successfully!');
                                    // this.showNewBoard = false;
                                    // await this.updateSyncLocation(this.newBoard);
                                    // this.newBoard = new SwitchBoardModel();
                                    // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                                },
                                err => {
                                    this.AlertSrv.show('error', 'Unable to save switch board. Please try again later!');
                                }
                            )
                            .add(() => {
                                this.LoaderSrv.hideFullScreenLoader();
                            });
                    } else if (locationNodeType === 'equip.png') {
                        const wiringCableArray = this.siteLocationsSrv.siteLocations.find(location => location.id === locationId)?.wiringCables;
                        const newWiringCable = {
                            locationId,
                            connectWith,
                            connectDirection: 'OUT',
                            sequence: wiringCableArray && wiringCableArray.length ? wiringCableArray.length + 1 : 1,
                            name: `Core ${randomName}`,
                            connectionKey,
                        };
                        new LocationApi()
                            .CreateLocationWiringCable(newWiringCable)
                            .subscribe(
                                res => {
                                    console.log('CreateLocationWiringCable resData', res.Data);
                                },
                                err => {
                                    this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                                }
                            )
                            .add(() => {
                                // this.newWiringCableObj = new WiringCableModel();
                                // this.LoaderSrv.hideFullScreenLoader();
                                // this.$forceUpdate();
                            });
                    }

                    if (connectNodeType === 'dp.png') {
                        const switchBoardsArray = this.siteLocationsSrv.siteLocations.find(location => location.id === locationId)?.switchBoards;
                        const newBoard = {
                            locationId: connectWith,
                            connectWith: locationId,
                            connectDirection: 'IN',
                            sequence: switchBoardsArray && switchBoardsArray.length ? switchBoardsArray.length + 1 : 1,
                            name: `Core ${randomName}`,
                            connectionKey,
                        };
                        new LocationApi().CreateLocationSwitchBoard(newBoard).subscribe(
                            async res => {
                                // console.log('res.Data', res.Data);
                                this.AlertSrv.show('success', 'Linked successfully!');
                                // this.showNewBoard = false;
                                // await this.updateSyncLocation(this.newBoard);
                                // this.newBoard = new SwitchBoardModel();
                                // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                            },
                            err => {
                                this.AlertSrv.show('error', 'Unable to save switch board. Please try again later!');
                            }
                        );
                    } else if (connectNodeType === 'equip.png') {
                        const wiringCableArray = this.siteLocationsSrv.siteLocations.find(location => location.id === locationId)?.wiringCables;
                        const newWiringCable = {
                            locationId: connectWith,
                            connectWith: locationId,
                            connectDirection: 'IN',
                            sequence: wiringCableArray && wiringCableArray.length ? wiringCableArray.length + 1 : 1,
                            name: `Core ${randomName}`,
                            connectionKey,
                        };
                        new LocationApi().CreateLocationWiringCable(newWiringCable).subscribe(
                            res => {
                                // console.log('CreateLocationWiringCable resData', res.Data);
                            },
                            err => {
                                this.AlertSrv.show('error', 'Unable to save Wiring Entry. Please try again later!');
                            }
                        );
                    }
                    // console.log('locationId', locationId);
                    // console.log('connectWith', connectWith);
                }, 'Adding Link Between Nodes');
            }
        });

        const addNewEquipment = () => {
            const newEquipment = {
                text: 'New Equipment',
                icon: 'equip.png',
                ind0: '#fff',
                ind1: '#fff',
                move: true,
                loc: '150 0',
            };
            this.LoaderSrv.showFullScreenLoader();

            new SitesApi()
                .CreateSiteLocation(this.siteId, new LocationModel({siteId: this.siteId, title: newEquipment.text, type: 'equipment'}))
                .subscribe(
                    res => {
                        if (res.Data) {
                            // console.log(res.Data);
                            // this.AlertSrv.show('success', 'Location has been Created successfully');
                            this.$emit('success');
                            this.siteLocationsSrv.getSiteLocations();
                            myDiagram.commit(d => {
                                d.model.addNodeData({...newEquipment, key: res.Data!.id});
                            }, 'add new Equipment Node.');
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', 'Unable to save location. Please try again later!');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        };

        const addNewDistributionPanel = () => {
            const newDP = {
                text: 'New DP',
                icon: 'dp.png',
                ind0: '#fff',
                ind1: '#fff',
                move: true,
                loc: '150 0',
            };
            this.LoaderSrv.showFullScreenLoader();

            new SitesApi()
                .CreateSiteLocation(this.siteId, new LocationModel({siteId: this.siteId, title: newDP.text, type: 'distribution'}))
                .subscribe(
                    res => {
                        if (res.Data) {
                            // console.log(res.Data);
                            // this.AlertSrv.show('success', 'Location has been Created successfully');
                            this.$emit('success');
                            this.siteLocationsSrv.getSiteLocations();
                            myDiagram.commit(d => {
                                d.model.addNodeData({...newDP, key: res.Data!.id});
                            }, 'add new distribution Node.');
                        }
                    },
                    err => {
                        this.AlertSrv.show('error', 'Unable to save location. Please try again later!');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        };

        const addNewEarthWire = () => {
            const newEW = {
                key: '-89',
                text: 'Earth Wire',
                icon: 'earthWire.png',
                category: 'ConditionalEarth',
                move: true,
                loc: '150 0',
                color: 'moccasin',
            };
            myDiagram.commit(d => {
                d.model.addNodeData({...newEW});
            }, 'add new Earth Wire.');
        };

        myDiagram.nodeTemplateMap.add(
            'Conditional',
            $(
                go.Node,
                'Auto',
                {deletable: false},
                nodeStyle(),
                $(go.Shape, 'Rectangle', {fill: '#fff', stroke: '#1C2F53', strokeWidth: 1, width: 150, height: 223, margin: new go.Margin(3, 0, 0, 0)}),
                $(
                    go.Panel,
                    'Vertical',
                    // {margin: 3},
                    $(
                        go.Panel,
                        'Auto',
                        $(go.Shape, 'Rectangle', {fill: '#E9E9E9', stroke: '#1C2F53', strokeWidth: 0.5, width: 144, height: 20}),
                        // {
                        //     background: '#E9E9E9',
                        //     width: 150,
                        //     height: 20,
                        //     margin: new go.Margin(3, 0, 0, 0),
                        // },
                        $(go.TextBlock, 'Toolbox', textStyle(), {margin: 2, textAlign: 'center'})
                    ),
                    $(
                        go.Panel,
                        'Vertical',
                        {width: 146, height: 194, margin: new go.Margin(3, 0, 0, 0)},
                        $(
                            go.Panel,
                            'Horizontal',
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Square',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 48,
                                    click: (e, button: any) => {
                                        addNewEquipment();
                                    },
                                },
                                $(go.Picture, {width: 30, height: 30}, new go.Binding('source', 'equipment', makeImagePath))
                                // $(go.TextBlock, 'ok', {margin: 2, textAlign: 'center'})
                            ),
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Square',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 48,
                                    click: (e, button: any) => {
                                        myDiagram.allowHorizontalScroll = false;
                                        myDiagram.allowVerticalScroll = false;
                                    },
                                },
                                $(go.Picture, {width: 30, height: 30}, new go.Binding('source', 'arrow', makeImagePath))
                            ),
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Square',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 48,
                                    click: (e, button: any) => {
                                        myDiagram.allowHorizontalScroll = true;
                                        myDiagram.allowVerticalScroll = true;
                                        myDiagram.toolManager.draggingTool.doActivate = function () {
                                            go.DraggingTool.prototype.doActivate.call(this);
                                            this.diagram.currentCursor = 'wait';
                                        };
                                    },
                                },
                                $(go.Picture, {width: 30, height: 30}, new go.Binding('source', 'hand', makeImagePath))
                            )
                        ),
                        $(
                            go.Panel,
                            'Horizontal',
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Square',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 48,
                                    click: (e, button: any) => {
                                        addNewDistributionPanel();
                                    },
                                },
                                $(go.Picture, {width: 30, height: 30}, new go.Binding('source', 'dp', makeImagePath))
                            ),
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Square',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 48,
                                    click: (e: any, obj: any) => e.diagram.commandHandler.undo(),
                                },
                                $(go.Picture, {width: 38, height: 38}, new go.Binding('source', 'undo', makeImagePath))
                            ),
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Square',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 48,
                                    click: (e: any, obj: any) => e.diagram.commandHandler.redo(),
                                },
                                $(go.Picture, {width: 40, height: 40}, new go.Binding('source', 'redo', makeImagePath))
                            )
                        ),
                        $(
                            go.Panel,
                            'Horizontal',
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Rectangle',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 72,
                                    click: (e, button: any) => {
                                        addNewEarthWire();
                                    },
                                },
                                $(go.Picture, {width: 40, height: 40, source: '/images/earthWire.png'})
                            ),
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Rectangle',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 72,
                                    click: (e: any, obj: any) => {
                                        const canvasDiagram = e.diagram;

                                        canvasDiagram.selection.each((element: any) => {
                                            console.log('element to delete', element.data);
                                            const fromNode = element.data.from;
                                            const toNode = element.data.to;
                                            if (fromNode) {
                                                new LocationApi()
                                                    .getLocationById(fromNode, {
                                                        include: [
                                                            {
                                                                relation: 'locationDetail',
                                                            },
                                                            {
                                                                relation: 'equipmentDetail',
                                                            },
                                                            {
                                                                relation: 'distributionPanel',
                                                            },
                                                            {
                                                                relation: 'switchBoards',
                                                                order: ['sequence'],
                                                            },
                                                            {
                                                                relation: 'coresGroups',
                                                            },
                                                            {
                                                                relation: 'wiringCables',
                                                                order: ['sequence'],
                                                            },
                                                        ],
                                                    })
                                                    .subscribe(res => {
                                                        if (res.Data) {
                                                            if (res.Data.wiringCables && res.Data.wiringCables.length) {
                                                                res.Data.wiringCables.forEach((wiringCable, index) => {
                                                                    if (wiringCable.id && wiringCable.connectWith === toNode) {
                                                                        new LocationApi().deleteWiringCables(wiringCable.id).subscribe();
                                                                    }
                                                                });
                                                            }

                                                            if (res.Data.switchBoards && res.Data.switchBoards.length) {
                                                                res.Data.switchBoards.forEach((switchBoard, index) => {
                                                                    if (switchBoard.id && switchBoard.connectWith === toNode) {
                                                                        new LocationApi().deleteSwitchBoards(switchBoard.id).subscribe();
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    });
                                            }

                                            if (toNode) {
                                                new LocationApi()
                                                    .getLocationById(toNode, {
                                                        include: [
                                                            {
                                                                relation: 'locationDetail',
                                                            },
                                                            {
                                                                relation: 'equipmentDetail',
                                                            },
                                                            {
                                                                relation: 'distributionPanel',
                                                            },
                                                            {
                                                                relation: 'switchBoards',
                                                                order: ['sequence'],
                                                            },
                                                            {
                                                                relation: 'coresGroups',
                                                            },
                                                            {
                                                                relation: 'wiringCables',
                                                                order: ['sequence'],
                                                            },
                                                        ],
                                                    })
                                                    .subscribe(res => {
                                                        if (res.Data) {
                                                            if (res.Data.wiringCables && res.Data.wiringCables.length) {
                                                                res.Data.wiringCables.forEach((wiringCable, index) => {
                                                                    if (wiringCable.id && wiringCable.connectWith === fromNode) {
                                                                        new LocationApi().deleteWiringCables(wiringCable.id).subscribe();
                                                                    }
                                                                });
                                                            }

                                                            if (res.Data.switchBoards && res.Data.switchBoards.length) {
                                                                res.Data.switchBoards.forEach((switchBoard, index) => {
                                                                    if (switchBoard.id && switchBoard.connectWith === fromNode) {
                                                                        new LocationApi().deleteSwitchBoards(switchBoard.id).subscribe();
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    });
                                            }
                                        });
                                        canvasDiagram.commandHandler.deleteSelection();
                                    },
                                },
                                $(go.Picture, {width: 30, height: 30, source: '/images/remove.png'})
                            )
                        ),
                        $(
                            go.Panel,
                            'Horizontal',
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Rectangle',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 72,
                                    click: (e: any, obj: any) => {
                                        if (myDiagram.commandHandler.canIncreaseZoom()) myDiagram.commandHandler.increaseZoom();
                                    },
                                },
                                $(go.Picture, {width: 30, height: 30}, new go.Binding('source', 'zoomIn', makeImagePath))
                            ),
                            $(
                                'Button',
                                {
                                    'ButtonBorder.fill': '#E9E9E9',
                                    'ButtonBorder.figure': 'Rectangle',
                                    'ButtonBorder.stroke': '#1C2F53',
                                    'ButtonBorder.strokeWidth': 0.5,
                                    _buttonFillOver: '#E9E9E9',
                                    height: 48,
                                    width: 72,
                                    click: (e: any, obj: any) => {
                                        if (myDiagram.commandHandler.canDecreaseZoom()) myDiagram.commandHandler.decreaseZoom();
                                    },
                                },
                                $(go.Picture, {width: 30, height: 30}, new go.Binding('source', 'zoomOut', makeImagePath))
                            )
                        )
                    )
                )
            )
        );

        myDiagram.nodeTemplateMap.add(
            'ConditionalPower',
            $(
                go.Node,
                'Table',
                nodeStyle(),
                {contextMenu: nodeMenu, movable: true, deletable: false},
                $(
                    go.Panel,
                    'Vertical',
                    $(
                        go.Panel,
                        'Table',
                        $(go.Shape, 'RoundedRectangle', {width: 100, height: 100, strokeWidth: 1, parameter1: 10, fill: '#DBE1F0'}),
                        $(
                            go.Panel,
                            'Vertical', // everything within the border
                            $(
                                go.TextBlock,
                                {
                                    editable: true,
                                    margin: 4,
                                    maxLines: 1,
                                    overflow: go.TextBlock.OverflowEllipsis,
                                    isMultiline: false,
                                    textAlign: 'center',
                                    width: 80,
                                    textValidation: saveRenameNodeLocation,
                                },
                                textStyle(),
                                new go.Binding('text').makeTwoWay()
                            ),
                            $(go.Picture, {width: 50, height: 50, margin: new go.Margin(0, 5, 5, 5)}, new go.Binding('source', 'icon', makeImagePath))
                        )
                    )
                ),
                makePort('T', go.Spot.Top, go.Spot.TopSide, true, false),
                makePort('L', go.Spot.Left, go.Spot.LeftSide, true, false),
                makePort('R', go.Spot.Right, go.Spot.RightSide, true, false),
                makePort('B', go.Spot.Bottom, go.Spot.BottomSide, true, false)
            )
        );

        myDiagram.nodeTemplateMap.add(
            'ConditionalEarth',
            $(
                go.Node,
                'Table',
                nodeStyle(),
                {
                    contextMenu: nodeMenu,
                    movable: true,
                    fromMaxLinks: 1,
                    linkValidation: (fromnode: any, fromport: any, tonode: any, toport: any) => {
                        // total number of links connecting with all ports of a node is limited to 1:
                        return tonode.linksConnected.count < 1;
                    },
                },
                // {portId: ''}, // this whole panel acts as the only port for the node
                $(
                    go.Panel,
                    'Vertical',
                    $(
                        go.Panel,
                        'Table',
                        $(go.Shape, 'RoundedRectangle', {width: 100, height: 100, strokeWidth: 1, parameter1: 10, fill: '#DBE1F0'}),
                        $(
                            go.Panel,
                            'Vertical', // everything within the border
                            $(
                                go.TextBlock,
                                {
                                    editable: true,
                                    margin: 4,
                                    maxLines: 1,
                                    overflow: go.TextBlock.OverflowEllipsis,
                                    isMultiline: false,
                                    textAlign: 'center',
                                    width: 80,
                                    textValidation: saveRenameNodeLocation,
                                },
                                textStyle(),
                                new go.Binding('text').makeTwoWay()
                            ),
                            $(go.Picture, {width: 50, height: 50, margin: new go.Margin(0, 5, 5, 5)}, new go.Binding('source', 'icon', makeImagePath))
                        )
                    )
                ),
                makePort('T', go.Spot.Top, go.Spot.TopSide, false, true)
            )
        );

        myTreeDiagram.nodeTemplateMap.add(
            'ConditionalPower',
            $(
                go.Node,
                'Table',
                nodeStyle(),
                {contextMenu: nodeMenu, movable: true, deletable: false},
                $(
                    go.Panel,
                    'Vertical',
                    $(
                        go.Panel,
                        'Table',
                        $(go.Shape, 'RoundedRectangle', {width: 100, height: 100, strokeWidth: 1, parameter1: 10, fill: '#DBE1F0'}),
                        $(
                            go.Panel,
                            'Vertical', // everything within the border
                            $(
                                go.TextBlock,
                                {
                                    editable: true,
                                    margin: 4,
                                    maxLines: 1,
                                    overflow: go.TextBlock.OverflowEllipsis,
                                    isMultiline: false,
                                    textAlign: 'center',
                                    width: 80,
                                    textValidation: saveRenameNodeLocation,
                                },
                                textStyle(),
                                new go.Binding('text').makeTwoWay()
                            ),
                            $(go.Picture, {width: 50, height: 50, margin: new go.Margin(0, 5, 5, 5)}, new go.Binding('source', 'icon', makeImagePath))
                        )
                    )
                ),
                makePort('T', go.Spot.Top, go.Spot.TopSide, true, true),
                makePort('L', go.Spot.Left, go.Spot.LeftSide, true, true),
                makePort('R', go.Spot.Right, go.Spot.RightSide, true, true),
                makePort('B', go.Spot.Bottom, go.Spot.BottomSide, true, true)
            )
        );

        myTreeDiagram.nodeTemplateMap.add(
            'ConditionalEarth',
            $(
                go.Node,
                'Table',
                nodeStyle(),
                {
                    contextMenu: nodeMenu,
                    movable: true,
                    fromMaxLinks: 1,
                    linkValidation: (fromnode: any, fromport: any, tonode: any, toport: any) => {
                        // total number of links connecting with all ports of a node is limited to 1:
                        return tonode.linksConnected.count < 1;
                    },
                },
                // {portId: ''}, // this whole panel acts as the only port for the node
                $(
                    go.Panel,
                    'Vertical',
                    $(
                        go.Panel,
                        'Table',
                        $(go.Shape, 'RoundedRectangle', {width: 100, height: 100, strokeWidth: 1, parameter1: 10, fill: '#DBE1F0'}),
                        $(
                            go.Panel,
                            'Vertical', // everything within the border
                            $(
                                go.TextBlock,
                                {
                                    editable: true,
                                    margin: 4,
                                    maxLines: 1,
                                    overflow: go.TextBlock.OverflowEllipsis,
                                    isMultiline: false,
                                    textAlign: 'center',
                                    width: 80,
                                    textValidation: saveRenameNodeLocation,
                                },
                                textStyle(),
                                new go.Binding('text').makeTwoWay()
                            ),
                            $(go.Picture, {width: 50, height: 50, margin: new go.Margin(0, 5, 5, 5)}, new go.Binding('source', 'icon', makeImagePath))
                        )
                    )
                ),
                makePort('T', go.Spot.Top, go.Spot.TopSide, false, true)
            )
        );

        function textStyle() {
            return {
                font: 'bold 11px Lato, Helvetica, Arial, sans-serif',
                stroke: 'black',
            };
        }

        function nodeStyle() {
            return [
                new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
                {
                    locationSpot: go.Spot.Center,
                },
            ];
        }

        myDiagram.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal;
        myDiagram.toolManager.relinkingTool.temporaryLink.routing = go.Link.Orthogonal;

        function makePort(name: any, align: any, spot: any, output: any, input: any) {
            const horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom);
            // the port is basically just a transparent rectangle that stretches along the side of the node,
            // and becomes colored when the mouse passes over it
            // console.log('object', output);
            return $(go.Shape, {
                fill: 'transparent', // changed to a color in the mouseEnter event handler
                strokeWidth: 0, // no stroke
                width: horizontal ? NaN : 4, // if not stretching horizontally, just 8 wide
                height: !horizontal ? NaN : 4, // if not stretching vertically, just 8 tall
                alignment: align, // align the port on the main Shape
                stretch: horizontal ? go.GraphObject.Horizontal : go.GraphObject.Vertical,
                portId: name, // declare this object to be a "port"
                fromSpot: spot, // declare where links may connect at this port
                fromLinkable: output, // declare whether the user may draw links from here
                toSpot: spot, // declare where links may connect at this port
                toLinkable: input, // declare whether the user may draw links to here
                cursor: 'pointer', // show a different cursor to indicate potential link point
                mouseEnter: (e: {diagram: any}, port: any) => {
                    // the PORT argument will be this Shape
                    if (!e.diagram.isReadOnly) port.fill = '#1C2F53';
                },
                mouseLeave: (e: any, port: any) => (port.fill = 'transparent'),
            });
        }

        function makeImagePath(icon: string) {
            return '/images/' + icon;
        }

        function swapLink(nodeKey: any) {
            return nodeKey == 1 ? 2 : 1;
        }

        const savedCoresModelContent = {
            class: 'GraphLinksModel',
            linkFromPortIdProperty: 'fromPort',
            linkToPortIdProperty: 'toPort',
            nodeDataArray: [
                {key: -1, category: 'Conditional', loc: '280 100', text: 'Add Core', icon: 'round.png'},
                {key: -2, category: 'Conditional', loc: '420 100', text: 'Swap', icon: 'swap.png'},
                // {
                //     key: 1,
                //     text: 'Device Type A',
                //     number: 17,
                //     icon: 'equip.png',
                //     color: 'moccasin',
                //     ind0: '#9ED291',
                //     ind1: '#DBE1F0',
                //     ind2: '#E998A1',
                //     move: false,
                //     loc: '100 200',
                //     positionCore: 'Left',
                // },
                // {
                //     key: 2,
                //     text: 'Device Type B',
                //     number: 97,
                //     icon: 'equip.png',
                //     color: 'mistyrose',
                //     ind0: '#E0D177',
                //     ind1: '#DBE1F0',
                //     ind2: '#E998A1',
                //     move: false,
                //     loc: '600 200',
                //     positionCore: 'Right',
                // },
            ],
            linkDataArray: [],
            // linkDataArray: this.coreLocationDataArray,
            // linkDataArray: [
            //     // {from: 1, to: 2, name: 'Cable 1', groupByCustom: '', strokeWidth: 5, points: [101.5, 240, 111.5, 240, 588.5, 240, 598.5, 240]},
            //     // {from: 1, to: 2, name: 'Cable 2', groupByCustom: '', fromPort: '', toPort: '', points: [101.5, 281, 111.5, 281, 588.5, 281, 598.5, 281]},
            //     // {from: 2, to: 1, name: 'Cable 3', groupByCustom: '', fromPort: '', toPort: '', points: [101.5, 321, 111.5, 321, 588.5, 321, 598.5, 321]},
            // ],
        };

        const defaultSavedModelContent = {
            class: 'GraphLinksModel',
            linkFromPortIdProperty: 'fromPort',
            linkToPortIdProperty: 'toPort',
            nodeDataArray: [
                {
                    key: -1,
                    category: 'Conditional',
                    loc: '0 0',
                    text: 'Start',
                    equipment: 'equip.png',
                    dp: 'dp.png',
                    earthWire: 'earthWire.png',
                    arrow: 'arrow.png',
                    hand: 'hand.png',
                    undo: 'undo.png',
                    redo: 'redo.png',
                    zoomIn: 'zoomIn.png',
                    zoomOut: 'zoomOut.png',
                },
                {
                    key: -99,
                    category: 'ConditionalPower',
                    text: 'Power Source',
                    loc: '-150 0',
                    icon: 'power.png',
                    color: 'moccasin',
                    move: true,
                },
            ],
            linkDataArray: [],
        };

        // To simplify this code we define a function for creating a context menu button:
        function makeButton(text: any, action: any, visiblePredicate: any, icon: any) {
            return $(
                'ContextMenuButton',
                $(
                    go.Panel,
                    'Horizontal',
                    {width: 173, height: 25, click: action},
                    $(go.Panel, 'Auto', {width: 40}, $(go.Picture, {margin: 10, width: 17, height: 17, source: icon ? `/images/${icon}.png` : ''})),
                    $(go.Panel, 'Auto', {width: 133}, $(go.TextBlock, {font: '12px'}, text))
                ),
                // don't bother with binding GraphObject.visible if there's no predicate
                visiblePredicate ? new go.Binding('visible', '', (o, e) => (o.diagram ? visiblePredicate(o, e) : false)).ofObject() : {}
            );
        }
        function makeSvgButton(text: any, action: any, visiblePredicate: any, icon: any) {
            return $(
                'ContextMenuButton',
                $(
                    go.Panel,
                    'Horizontal',
                    {width: 173, height: 36},
                    $(go.Panel, 'Auto', {width: 40}, $(go.Picture, {margin: 10, width: 17, height: 17, source: icon ? `/images/${icon}.svg` : ''})),
                    $(go.Panel, 'Auto', $(go.TextBlock, {font: '12px'}, text)),
                    {click: action}
                ),
                // don't bother with binding GraphObject.visible if there's no predicate
                visiblePredicate ? new go.Binding('visible', '', (o, e) => (o.diagram ? visiblePredicate(o, e) : false)).ofObject() : {}
            );
        }

        myDiagram.contextMenu = $(
            'ContextMenu',
            makeButton(
                'Add equipment',
                (e: any, obj: any) => {
                    // this.siteLocationsSrv.updateLocationToCanvasDiagram();
                    addNewEquipment();
                    // const newEquipment = {
                    //     text: 'New Equipment',
                    //     icon: 'equip.png',
                    //     ind0: '#fff',
                    //     ind1: '#fff',
                    //     move: true,
                    //     loc: '0,0',
                    // };
                    // e.diagram.model.addNodeData(newEquipment);
                    // e.diagram.commandHandler.cutSelection(e.diagram.model.addNodeData(newEquipment));
                    // e.diagram.commandHandler.pasteSelection(e.diagram.toolManager.contextMenuTool.mouseDownPoint);
                },
                null,
                'equip'
            ),
            makeButton(
                'Add Distribution Panel',
                (e: any, obj: any) => {
                    addNewDistributionPanel();
                    // const newDP = {
                    //     text: 'Device Type B',
                    //     icon: 'dp.png',
                    //     color: 'mistyrose',
                    //     loc: e.diagram.toolManager.contextMenuTool.mouseDownPoint,
                    // };
                    //  // console.log(newDP);
                    // e.diagram.model.addNodeData(newDP);
                },
                null,
                'dp'
            ),
            makeButton(
                'Paste',
                (e: any, obj: any) => e.diagram.commandHandler.pasteSelection(e.diagram.toolManager.contextMenuTool.mouseDownPoint),
                (o: any) => o.diagram.commandHandler.canPasteSelection(o.diagram.toolManager.contextMenuTool.mouseDownPoint),
                ''
            ),
            makeButton(
                'Undo',
                (e: any, obj: any) => e.diagram.commandHandler.undo(),
                (o: any) => o.diagram.commandHandler.canUndo(),
                ''
            ),
            makeButton(
                'Redo',
                (e: any, obj: any) => e.diagram.commandHandler.redo(),
                (o: any) => o.diagram.commandHandler.canRedo(),
                ''
            )
        );

        // myDiagram.model = new go.GraphLinksModel(nodesDataArray, linksDataArray);
        // myDiagram.model = go.Model.fromJson(savedModelContent);
        //  // console.log('siteLocationsSrv.site');
        //  // console.log(this.siteLocationsSrv.site);
        //  // console.log('siteLocationsSrv.site2');
        //  // console.log(this.siteToEdit);

        // const drawingCanvasData = JSON.parse(this.siteToEdit && this.siteToEdit.drawingCanvas1 ? this.siteToEdit!.drawingCanvas1! : JSON.stringify(defaultSavedModelContent));
        // if (drawingCanvasData.linkDataArray && drawingCanvasData.linkDataArray.length){

        // }
        // myDiagram.model = go.Model.fromJson();
        const dataToRender = this.siteToEdit && this.siteToEdit.drawingCanvas1 ? this.siteToEdit!.drawingCanvas1! : defaultSavedModelContent;

        // this.siteLocationsSrv.updateLocationToCanvasDiagram();

        myDiagram.model = go.GraphLinksModel.fromJson(dataToRender);

        myTreeDiagram.linkTemplate = $(
            go.Link, // the whole link panel
            {
                routing: go.Link.AvoidsNodes,
                curve: go.Link.JumpOver,
                corner: 0,
                toShortLength: 0,
                relinkableFrom: true,
                relinkableTo: true,
                reshapable: true,
                resegmentable: true,
                // mouse-overs subtly highlight links:
                mouseEnter: (e: go.InputEvent, link: any) => (link.findObject('HIGHLIGHT').stroke = 'rgba(30,144,255,0.2)'),
                mouseLeave: (e: go.InputEvent, link: any) => (link.findObject('HIGHLIGHT').stroke = 'transparent'),
                selectionAdorned: false,
            },
            new go.Binding('points').makeTwoWay(),
            $(
                go.Shape, // the highlight shape, normally transparent
                {isPanelMain: true, strokeWidth: 8, stroke: 'transparent', name: 'HIGHLIGHT'}
            ),
            $(
                go.Shape, // the link path shape
                {isPanelMain: true, stroke: 'gray', strokeWidth: 1.5},
                new go.Binding('stroke', 'isSelected', sel => (sel ? 'dodgerblue' : 'black')).ofObject(),
                new go.Binding('strokeWidth').makeTwoWay()
            ),
            {
                doubleClick: function (e, obj: any) {
                    // . . . now node is the Node that was double-clicked
                    const data = obj.data;
                    console.log(data);
                    const fromNode = obj.fromNode;
                    // console.log('from', fromNode.data);
                    const toNode = obj.toNode;
                    // console.log('toNode', toNode.data);
                    showSelectedCore(fromNode.data, toNode.data);
                    // now data has all of the custom app-specific properties that you have
                    // supplied for that node, in the Model.nodeDataArray
                    // "9541faa8-255f-49c0-9242-72df4981fc07"
                },
            },
            $(go.TextBlock, 'Out', {segmentIndex: 0, segmentOffset: new go.Point(25, 15)}),
            $(go.TextBlock, 'In', {segmentIndex: -1, segmentOffset: new go.Point(-10, -15)})
        );
        //go.Model.fromJson(this.siteToEdit && this.siteToEdit.drawingCanvas1 ? this.siteToEdit!.drawingCanvas1! : defaultSavedModelContent);
        // console.log('this.selectedCoreData this.selectedCoreData', this.selectedCoreData);
        // myCoreDiagram.model = go.Model.fromJson(savedCoresModelContent);

        this.hideCoreView = () => {
            // document.getElementById('drawingDivCanvasCoresDiv')!.style.display = 'none';
            // document.getElementById('drawingDivCanvas')!.style.display = 'block';
            this.$refs.drawingDivCanvasCoresDiv.style.display = 'none';
            this.$refs.drawingDivCanvas.style.display = 'block';

            myCoreDiagram.commit(d => {
                d.nodes.each(node => {
                    if (node.key === -1) return;
                    if (node.key === -2) return;

                    myCoreDiagram.startTransaction();
                    myCoreDiagram.remove(node);
                    myCoreDiagram.commitTransaction('deleted node');
                });
            }, 'removing Nodes');
            updateLinksData();
            this.coreDetailsView = false;
        };

        this.showTreeView = () => {
            // console.log('showTreeView');
            this.siteToEdit.drawingCanvas1;
            console.log(this.siteToEdit.drawingCanvas1);
            this.nodesDPArray = Object.assign(
                [],
                JSON.parse(this.siteToEdit!.drawingCanvas1!).nodeDataArray.filter((n: any) => n.icon === 'dp.png')
            );
            this.selectedDP = Object.assign(
                {},
                JSON.parse(this.siteToEdit!.drawingCanvas1!).nodeDataArray.find((n: any) => n.parent === -99)
            );
            const earth = Object.assign(
                {},
                JSON.parse(this.siteToEdit!.drawingCanvas1!).nodeDataArray.find((n: any) => n.icon === 'earthWire.png')
            );
            if (earth) {
                const data = Object.assign(
                    [],
                    JSON.parse(this.siteToEdit!.drawingCanvas1!).nodeDataArray.filter((n: any) => n.key !== -1)
                );

                myTreeDiagram.model = new go.TreeModel(data);
                // document.getElementById('drawingDivCanvas')!.style.display = 'none';
                // document.getElementById('treeDrawingCanvasDiv')!.style.display = 'block';
                this.$refs.drawingDivCanvas.style.display = 'none';
                this.$refs.treeDrawingCanvasDiv.style.display = 'block';
                myTreeDiagram.commandHandler.zoomToFit();
                this.treeViewEnabled = true;
                this.coreView = false;
                // this.siteLocationsSrv.selectedLcoation = null;
                // this.diagramViewStyle = false;
                // this.diagramFullPageConfig = false;
            } else {
                this.AlertSrv.show('warning', 'No Ground Node found to complete circuit');
            }
            //  // console.log('this.selectedDPObj', this.selectedDP);
            //  // console.log('this.selectedDPObj', data);
            // if (this.selectedDP) {
            //     const indexDP = data.findIndex(n => n.parent === this.selectedDP);
            //      // console.log('indexDP', indexDP);
            //     data.slice(indexDP);
            //      // console.log('indexDP', data);
            // }

            //  // console.log('showTreeData', data);
        };

        this.hideTreeView = () => {
            myTreeDiagram.clear();
        };

        this.showCanvasView = () => {
            this.hideTreeView();
            // document.getElementById('treeDrawingCanvasDiv')!.style.display = 'none';
            // document.getElementById('drawingDivCanvasCoresDiv')!.style.display = 'none';
            // document.getElementById('drawingDivCanvas')!.style.display = 'block';
            this.$refs.treeDrawingCanvasDiv.style.display = 'none';
            this.$refs.drawingDivCanvasCoresDiv.style.display = 'none';
            this.$refs.drawingDivCanvas.style.display = 'block';
            this.treeViewEnabled = false;
            this.coreView = false;
            this.hideCoreView();
            this.coreDetailsView = false;
        };

        this.changeSelectedDP = () => {
            // // const indexDP = myDiagram.model.nodeDataArray.findIndex(n => n.parent === this.selectedDP.parent);
            // // this.selectedDPObj = myTreeDiagram.findNodeForKey(this.selectedDP);
            //  // console.log('this.selectedDP', this.selectedDP);
            // myTreeDiagram.scale = 1;
            // myTreeDiagram.scrollToRect(myTreeDiagram.findNodeForKey(this.selectedDP)!.actualBounds);
            if (this.selectedDP) {
                focusOnNodeTree(myTreeDiagram.findNodeForKey(this.selectedDP));
            }
            // this.showTreeView();
        };

        const getAllLocationsWithAreas = () => {
            //  // console.log(this.siteLocationsSrv.siteLocations);
            const filteredResults = this.siteLocationsSrv.siteLocations.filter(x => x.locationId !== null);
            //  // console.log('filteredResults', filteredResults);
            return filteredResults;
        };

        const updateLinksData = () => {
            myDiagram.commit(d => {
                d.links.each(link => {
                    // console.log('object node for Link => ', link.data);
                    if (link.data.from === -99) return;
                    if (link.data.to <= -89) return;
                    const locationFound = this.siteLocationsSrv.siteLocations.find(loc => loc.id === link.data.from);

                    // console.log('locationFound ==> RENDERING =======', locationFound);
                    if (locationFound?.type === 'equipment') {
                        if (locationFound.wiringCables && locationFound.wiringCables.length > 1) {
                            const wiringCablesCount = locationFound.wiringCables.filter(
                                wc => wc.locationId === link.data.from && wc.connectWith === link.data.to && wc.connectDirection === 'OUT'
                            );
                            if (wiringCablesCount && wiringCablesCount.length > 1) {
                                myDiagram.model.commit(m => {
                                    m.set(link.data, 'strokeWidth', 2.5);
                                });
                            }
                        }
                    }

                    if (locationFound?.type === 'distribution') {
                        if (locationFound.switchBoards && locationFound.switchBoards.length > 1) {
                            const wiringCablesCount = locationFound.switchBoards.filter(
                                wc => wc.locationId === link.data.from && wc.connectWith === link.data.to && wc.connectDirection === 'OUT'
                            );
                            if (wiringCablesCount && wiringCablesCount.length > 1) {
                                myDiagram.model.commit(m => {
                                    m.set(link.data, 'strokeWidth', 2.5);
                                });
                            }
                        }
                    }

                    // c.connectDirection === this.adminDataFieldsSrv.equipmentFieldOptions['equipmentWiringDirection'].find(x => x.value === 'Out')!.id
                });
            });
        };

        this.commitDiagramNodes(myDiagram, updateLinksData);

        this.siteLocationsSrv.eventEmitter.on('onSiteLocationsLoaded', () => {
            console.log('siteLocationsSrv.siteUpdated$');
            this.commitDiagramNodes(myDiagram, updateLinksData);
        });

        this.myCoreDiagramInstance = myCoreDiagram;
        this.showSelectedCoreInstance = showSelectedCore;
    }

    public beforeDestroy() {
        this.siteLocationsSrv.eventEmitter.removeAllListeners('onSiteLocationsLoaded');
    }

    public commitDiagramNodes(myDiagram: go.Diagram, updateLinksData: Function) {
        myDiagram.commit(d => {
            console.log('COMMIT object ======================= ', d.nodes);
            // const locationsArray = getAllLocationsWithAreas();
            const locationsArray = this.siteLocationsSrv.siteLocations.filter(x => x.locationId !== null);
            // console.log('filteredResults locationsArray .... ', locationsArray);
            updateLinksData();
            const dustAreas = this.adminDataFieldsSrv.areaFieldOptions[this.areaFieldsList.areaDustArea] as Array<FieldOptions>;
            const gasAreas = this.adminDataFieldsSrv.areaFieldOptions[this.areaFieldsList.areaGasArea] as Array<FieldOptions>;
            d.nodes.each(node => {
                // console.log('object node for COMMIT => ', node);
                if (node.data.key === -1) return;

                // if (wiringCableFlag && wiringCableFlag.wiringCables && wiringCableFlag.wiringCables.length) {
                //     // myDiagram.model.commit(m => {
                //     //     m.set(node.data, 'ind0', foundGasArea?.colorGrade);
                //     //     m.set(node.data, 'ind1', foundDustArea?.colorGrade);
                //     // });
                // }
                const currentLocation = locationsArray?.find((x: any) => x.id === node.data.key);
                // console.log('currentLocation ---- > ', currentLocation);
                if (currentLocation?.locationId) {
                    //  // console.log('locationId => => ', currentLocation?.locationId);
                    new LocationApi()
                        .getLocationById(currentLocation?.locationId, {
                            include: [
                                {
                                    relation: 'locationDetail',
                                },
                            ],
                        })
                        .subscribe(
                            res => {
                                if (res.Data) {
                                    const areaLocation = new LocationModel(res.Data);
                                    //  // console.log('areaLocation', areaLocation);

                                    const gasAreaOfLocation = areaLocation?.locationDetail?.gasZone;
                                    const dustAreaOfLocation = areaLocation?.locationDetail?.dustZone;
                                    //  // console.log('gasAreaOfLocation', gasAreaOfLocation);
                                    //  // console.log('dustAreaOfLocation', dustAreaOfLocation);

                                    const foundGasArea = gasAreas.find((x: any) => {
                                        if (x.id === gasAreaOfLocation) {
                                            switch (x.value) {
                                                case 'Safe Area':
                                                    x.colorGrade = 'green';
                                                    return true;
                                                    break;
                                                case 'Zone 2':
                                                    x.colorGrade = 'orange';
                                                    return true;
                                                    break;
                                                case 'Zone 1':
                                                    x.colorGrade = 'yellow';
                                                    return true;
                                                    break;
                                                case 'Zone 0':
                                                    x.colorGrade = 'red';
                                                    return true;
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    });
                                    const foundDustArea = dustAreas.find((x: any) => {
                                        if (x.id === dustAreaOfLocation) {
                                            switch (x.value) {
                                                case 'Safe Area':
                                                    x.colorGrade = 'green';
                                                    return true;
                                                    break;
                                                case 'Zone 22':
                                                    x.colorGrade = 'orange';
                                                    return true;
                                                    break;
                                                case 'Zone 21':
                                                    x.colorGrade = 'yellow';
                                                    return true;
                                                    break;
                                                case 'Zone 20':
                                                    x.colorGrade = 'red';
                                                    return true;
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    });

                                    // console.log('foundGasArea', foundGasArea);
                                    //  // console.log('foundDustArea', foundDustArea);

                                    myDiagram.model.commit(m => {
                                        m.set(node.data, 'ind0', foundGasArea?.colorGrade);
                                        m.set(node.data, 'ind1', foundDustArea?.colorGrade);
                                    });
                                } else {
                                    // console.log('ERROR Getting Area for Location ID', currentLocation);
                                }
                            },
                            err => {
                                this.AlertSrv.show('warning', 'Unable to load data.');
                            }
                        );
                }
            });
        }, 'make Indicators');
    }

    public updateLocationData() {
        this.LoaderSrv.showFullScreenLoader();
        new LocationApi()
            .UpdateLocation(this.siteLocationsSrv!.location!.id!, this.siteLocationsSrv.location)
            .subscribe(
                res => {
                    if (res.Status) {
                        this.AlertSrv.show('success', 'Location has been updated successfully');
                        this.$emit('success');
                        this.siteLocationsSrv.getSiteLocations();
                        this.siteLocationsSrv.closeSiteLocationDialog();
                    }
                },
                err => {
                    this.AlertSrv.show('error', 'Unable to update location. Please try agian later!');
                }
            )
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }

    public get searchedLocations() {
        return this.siteLocations.filter(x => x.locationType === 'location');
    }

    public get searchedEquipments() {
        return this.siteLocations.filter(x => x.locationType === 'equipment');
    }

    public fullPageCoreView(state: any) {
        // console.log('object', state);
        if (this.diagramFullPageCoreView) {
            this.coreDetailsView = false;
            this.diagramFullPageCoreView = false;
            // document.getElementById('drawingDivCanvasCoresDiv')!.style.display = 'block';
            this.$refs.drawingDivCanvasCoresDiv.style.display = 'block';
        } else {
            this.diagramFullPageCoreView = !this.diagramFullPageCoreView;
        }
    }

    public minimizePageCoreView(state: any) {
        // console.log('object', state);
        // if (this.diagramFullPageCoreView) {
        this.coreDetailsView = false;
        this.diagramFullPageCoreView = false;
        this.coreGroupView = false;
        //     document.getElementById('drawingDivCanvasCoresDiv')!.style.display = 'block';
        // } else {
        //     this.diagramFullPageCoreView = !this.diagramFullPageCoreView;
        // }
    }

    public getLocationPathFromArray() {
        this.locationPathFrom = [];
        // console.log('this.coreFromLocationData.id', this.coreFromLocationData.id);
        let locationId = this.coreFromLocationData.id;
        let foundLocation: LocationModel | undefined = this.coreFromLocationData;

        while (locationId != null) {
            if (foundLocation) {
                this.locationPathFrom.push({id: foundLocation.id!, title: foundLocation.title!, type: foundLocation.type || 'location'});
            }
            locationId = foundLocation?.locationId;
            if (locationId) {
                foundLocation = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
            }
        }
        if (this.siteLocationsSrv.site) {
            this.locationPathFrom.push({id: this.siteLocationsSrv.site.id!, title: this.siteLocationsSrv.site.siteName!, type: 'site'});
        }
        this.locationPathFrom = this.locationPathFrom.reverse();
        return this.locationPathFrom;
    }

    public getLocationPathToArray() {
        this.locationPathTo = [];
        let locationId = this.coreToLocationData.id;
        let foundLocation: LocationModel | undefined = this.coreToLocationData;

        while (locationId != null) {
            if (foundLocation) {
                this.locationPathTo.push({id: foundLocation.id!, title: foundLocation.title!, type: foundLocation.type || 'location'});
            }
            locationId = foundLocation?.locationId;
            if (locationId) {
                foundLocation = this.siteLocationsSrv.siteLocations.find(x => x.id === locationId);
            }
        }
        if (this.siteLocationsSrv.site) {
            this.locationPathTo.push({id: this.siteLocationsSrv.site.id!, title: this.siteLocationsSrv.site.siteName!, type: 'site'});
        }
        this.locationPathTo = this.locationPathTo.reverse();
        return this.locationPathTo;
    }

    public searchClick() {
        this.getSiteLocations(this.siteId, this.search);
    }

    public hideResult() {
        // this.showResultTable = false;
        this.$emit('showSearchResultsEvent', false);

        //  // console.log('this.showResultTable', this.showResultTable);
    }

    public showResult() {
        if (this.search || this.siteLocations.length) {
            //  // console.log('Searched Data', this.search);
            this.$emit('showSearchResultsEvent', true);
        }
    }

    public locationItemClickFn(value: any) {
        console.log('locationItemClickFn', value);
        this.$emit('locationItemClick', value);
        // this.showResultTable = false;
    }

    public async updateCable(cable: any, groupCable: boolean = false) {
        console.log('updateCable Board', cable);

        if (cable.id) {
            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .UpdateWiringCable(cable)
                .subscribe(
                    res => {
                        // console.log('res.Data', res.Data);
                        this.AlertSrv.show('success', 'Wiring Entry has been updated successfully!');
                        if (cable.connectionKey) {
                            const connectedWithCore = this.coreToLocationData.wiringCables.find((core: any) => core.connectionKey === cable.connectionKey);
                            if (connectedWithCore) {
                                const updateConnectedWithCore = {...connectedWithCore, name: cable.name};
                                new LocationApi().UpdateWiringCable(updateConnectedWithCore).subscribe(res => {
                                    console.log('res updateConnectedWithCore', res.Data);
                                });
                            }
                        }
                        if (groupCable) {
                            this.setActiveGroup(cable.id);
                        } else {
                            this.setActive(cable.id);
                        }
                        // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                    },
                    err => {
                        console.log(this.siteLocationsSrv.selectedLcoation?.id);
                        this.AlertSrv.show('error', 'Unable to update Wiring Entry. Please try again later!');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });

            this.siteLocationsSrv.getSiteLocations();

            const fromNode = this.myCoreDiagramInstance.findNodeForKey(cable.locationId);
            const toNode = this.myCoreDiagramInstance.findNodeForKey(cable.connectWith);
            this.showSelectedCoreInstance(fromNode?.data, toNode?.data);
            // window.location.reload();
        }
    }

    public async updateBoard(board: any) {
        console.log('updateBoard Board', board);
        delete board.finalMidArr;
        if (board.id) {
            this.LoaderSrv.showFullScreenLoader();
            new LocationApi()
                .UpdateSwitchBoard(board)
                .subscribe(
                    res => {
                        // console.log('res.Data', res.Data);
                        this.AlertSrv.show('success', 'Switch Board has been updated successfully!');
                        // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                    },
                    err => {
                        console.log(this.siteLocationsSrv.selectedLcoation?.id);
                        this.AlertSrv.show('error', 'Unable to update switch board. Please try again later!');
                    }
                )
                .add(() => {
                    this.LoaderSrv.hideFullScreenLoader();
                });
        }
    }

    public async updateDPBoard(dp: any) {
        console.log('updateCable Board', dp);

        if (dp.distributionPanel) {
            if (!dp.distributionPanel.id) {
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .CreateLocationDistributionPanel(dp.locationId, dp)
                    .subscribe(
                        res => {
                            console.log(dp.selectedLcoation?.id);
                            if (res.Data) {
                                this.AlertSrv.show('success', 'Distribution Panel has been saved successfully!');
                            }
                            this.siteLocationsSrv.getSiteLocations();
                        },
                        err => {
                            console.log(dp.selectedLcoation?.id);
                            this.AlertSrv.show('error', 'Unable to update Wiring Entry. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            } else {
                this.LoaderSrv.showFullScreenLoader();
                new LocationApi()
                    .UpdateLocationDistributionPanel(dp.locationId!, dp.distributionPanel)
                    .subscribe(
                        res => {
                            console.log('this.siteLocationsSrv.selectedLcoation?.id', res.Data);
                            // if (res.Data) {
                            this.AlertSrv.show('success', 'Distribution Panel has been saved successfully!');

                            // this.locationItemClick(this.siteLocationsSrv.selectedLcoation!);
                            // }
                        },
                        err => {
                            console.log(this.siteLocationsSrv.selectedLcoation?.id);
                            this.AlertSrv.show('error', 'Unable to save distribution panel. Please try again later!');
                        }
                    )
                    .add(() => {
                        this.LoaderSrv.hideFullScreenLoader();
                    });
            }
        }
    }

    public getSiteLocations(id: string, search?: string | null) {
        if ((id || this.siteLocationsSrv.site?.id) && search) {
            new LoaderService().showFullScreenLoader();
            new LocationApi()
                .searchLocationDetails(id ?? this.siteLocationsSrv.site!.id!, search)
                .subscribe(
                    res => {
                        if (res.Data) {
                            const locations = res.Data;
                            this.siteLocations = [...locations.locationDetails, ...locations.equipmentDetails];
                        } else {
                            this.siteLocations = [];
                        }

                        this.$emit('showSearchResultsEvent', true);
                        // this.formsSiteResults = false;
                        // for (const location of locations) {
                        //     const found = this.siteLocations.find(x => x.id === location.id);
                        //     location.expanded = found?.expanded || false;
                        // }
                    },
                    err => {
                        new AlertService().show('error', err.message ?? err.Message ?? 'Unable to load site.');
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        }
    }
}
